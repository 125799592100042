import React from 'react'
import { CFIcon, CFView } from 'components'

export default (props) => (
  <CFView
    h="30px"
    w="30px"
    bg="black"
    br="25px"
    center
    hover
    boxShadow="1px 1px 3px rgba(0,0,0,.8)"
    {...props}
  >
    <CFIcon icon="plus" color="white" fontSize="20px" />
  </CFView>
)
