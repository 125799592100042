import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AddButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import { AddIcon, Button, EditIcon, ShopIcon, EnvelopeIcon } from 'evergreen-ui'
import RestaurantModal from './RestaurantModal'
import PromotionModal from './PromotionModal'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const [showModal, setShowModal] = useState('')
  const [restaurant, setRestaurant] = useState('')

  useEffect(() => {
    const unsubscribe = dispatch.dashboard.subscribeRestaurants()
    return () => unsubscribe()
  }, [])

  const setRestaurantModal = (type, restaurant) => {
    setShowModal(type)
    setRestaurant(restaurant)
  }

  const closeModal = () => {
    setShowModal('')
    setRestaurant('')
  }

  const setAndNavigateLocations = (restaurantId) => {
    history.push(`/restaurants/${restaurantId}/locations`)
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <Card h="calc(100vh - 110px)" w="1080px" column overflowY="auto">
            <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
              <CFView h1 bold>
                Restaurants
              </CFView>
              <CFView row>
                {dispatch.dashboard.getRole() === 'super_admin' && (
                  <CFView mr="10px">
                    <Button
                      intent="primary"
                      iconBefore={EnvelopeIcon}
                      height={40}
                      onClick={() =>
                        setRestaurantModal('promotion', restaurant)
                      }
                    >
                      Email Promotion
                    </Button>
                  </CFView>
                )}
                {dispatch.dashboard.getRole() === 'super_admin' && (
                  <Button
                    intent="danger"
                    iconBefore={AddIcon}
                    height={40}
                    onClick={() => setShowModal('restaurant')}
                  >
                    Create Restaurant
                  </Button>
                )}
              </CFView>
            </CFView>
            <CFSelect selector={dispatch.dashboard.getRestaurants}>
              {(restaurants) =>
                Object.values(restaurants).length > 0 ? (
                  <CFView p="20px" column>
                    {Object.values(restaurants)
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1
                        }
                        if (a.name > b.name) {
                          return 1
                        }
                        return 0
                      })
                      .map((restaurant) => {
                        const role = dispatch.dashboard.getRole()
                        const restaurantIds =
                          dispatch.dashboard.getRestaurantIds()
                        if (
                          (role !== 'super_admin' &&
                            role !== 'int_admin' &&
                            role !== 'menu_admin') ||
                          (role === 'int_admin' &&
                            !restaurantIds.includes(restaurant.id))
                        ) {
                          return null
                        }
                        const { inactive = false } = restaurant
                        if (inactive) {
                          return null
                        }
                        return (
                          <Card
                            p="10px"
                            mb="10px"
                            row
                            justifyBetween
                            key={restaurant.id}
                          >
                            <CFView column>
                              <CFView mb="5px">{restaurant.name}</CFView>
                              <CFView column>
                                <CFView
                                  fontSize={10}
                                >{`Order Number: ${restaurant.orderNumber}`}</CFView>
                                <CFView
                                  fontSize={10}
                                >{`Commission Fee: ${restaurant.commissionFee}`}</CFView>
                                <CFView
                                  fontSize={10}
                                >{`Stripe Fee: ${restaurant.stripeFee}`}</CFView>
                                <CFView
                                  fontSize={10}
                                >{`Subscription Fee: ${restaurant.subscriptionFee}`}</CFView>
                                <CFView
                                  fontSize={10}
                                >{`Minimum Commission: ${restaurant.minimumCommission}`}</CFView>
                                <CFView
                                  fontSize={10}
                                >{`Points Interval: ${restaurant.pointsInterval}`}</CFView>
                                <CFView
                                  fontSize={10}
                                >{`Points Per Interval: ${restaurant.pointsPerInterval}`}</CFView>
                              </CFView>
                            </CFView>
                            <CFView column>
                              <CFView mb="10px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={ShopIcon}
                                  height={40}
                                  onClick={() =>
                                    setAndNavigateLocations(restaurant.id)
                                  }
                                >
                                  View Locations
                                </Button>
                              </CFView>
                              <CFView mb="10px">
                                {dispatch.dashboard.getRole() ===
                                  'super_admin' && (
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={EnvelopeIcon}
                                    height={40}
                                    onClick={() =>
                                      setRestaurantModal(
                                        'promotion',
                                        restaurant
                                      )
                                    }
                                  >
                                    Email Promotion
                                  </Button>
                                )}
                              </CFView>
                              {dispatch.dashboard.getRole() ===
                                'super_admin' && (
                                <Button
                                  width={170}
                                  appearance="primary"
                                  intent="warning"
                                  iconBefore={EditIcon}
                                  height={40}
                                  onClick={() =>
                                    setRestaurantModal('restaurant', restaurant)
                                  }
                                >
                                  Edit Restaurant
                                </Button>
                              )}
                            </CFView>
                          </Card>
                        )
                      })}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Restaurants
                  </CFView>
                )
              }
            </CFSelect>
          </Card>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="Restaurants"
          RightButton={
            dispatch.dashboard.getRole() === 'super_admin' ? (
              <AddButton onClick={() => setShowModal('restaurant')} />
            ) : null
          }
        />
        <CFView h="calc(100vh - 110px)" column overflowY="auto">
          <CFSelect selector={dispatch.dashboard.getRestaurants}>
            {(restaurants) =>
              Object.values(restaurants).length > 0 ? (
                <CFView p="10px" column>
                  {Object.values(restaurants)
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1
                      }
                      if (a.name > b.name) {
                        return 1
                      }
                      return 0
                    })
                    .map((restaurant) => {
                      const role = dispatch.dashboard.getRole()
                      const restaurantIds =
                        dispatch.dashboard.getRestaurantIds()
                      if (
                        (role !== 'super_admin' &&
                          role !== 'int_admin' &&
                          role !== 'menu_admin') ||
                        (role === 'int_admin' &&
                          !restaurantIds.includes(restaurant.id))
                      ) {
                        return null
                      }
                      const { inactive = false } = restaurant
                      if (inactive) {
                        return null
                      }
                      return (
                        <Card mb="10px" column center key={restaurant.id}>
                          <CFView
                            w="100%"
                            column
                            center
                            bg="#43464B"
                            p="10px"
                            mb="5px"
                            color="white"
                            bold
                            fontSize={24}
                          >
                            {restaurant.name}
                          </CFView>
                          <CFView p="10px" w="90%">
                            <CFView column pb="10px">
                              <CFView row justifyBetween>
                                <CFView
                                  pb="5px"
                                  fontSize={18}
                                >{`Order Number:`}</CFView>
                                <CFView
                                  pb="5px"
                                  fontSize={18}
                                >{`${restaurant.orderNumber}`}</CFView>
                              </CFView>
                              <CFView row justifyBetween>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`Commission Fee:`}</CFView>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`${restaurant.commissionFee}`}</CFView>
                              </CFView>
                              <CFView row justifyBetween>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`Stripe Fee:`}</CFView>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`${restaurant.stripeFee}`}</CFView>
                              </CFView>
                              <CFView row justifyBetween>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`Subscription Fee:`}</CFView>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`${restaurant.subscriptionFee}`}</CFView>
                              </CFView>
                              <CFView row justifyBetween>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`Min Commission:`}</CFView>
                                <CFView
                                  fontSize={18}
                                  pb="5px"
                                >{`${restaurant.minimumCommission}`}</CFView>
                              </CFView>
                            </CFView>
                            <CFView
                              row
                              alignCenter
                              justifyCenter
                              w="100%"
                              mb="5px"
                            >
                              <CFView w="100%" mr="5px">
                                <Button
                                  appearance="primary"
                                  width={150}
                                  iconBefore={ShopIcon}
                                  height={48}
                                  onClick={() =>
                                    setAndNavigateLocations(restaurant.id)
                                  }
                                >
                                  Locations
                                </Button>
                              </CFView>
                              {dispatch.dashboard.getRole() ===
                                'super_admin' && (
                                <CFView w="100%">
                                  <Button
                                    appearance="primary"
                                    width={150}
                                    intent="warning"
                                    iconBefore={EditIcon}
                                    height={48}
                                    onClick={() =>
                                      setRestaurantModal(
                                        'restaurant',
                                        restaurant
                                      )
                                    }
                                  >
                                    Edit Store
                                  </Button>
                                </CFView>
                              )}
                            </CFView>
                          </CFView>
                        </Card>
                      )
                    })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Restaurants
                </CFView>
              )
            }
          </CFSelect>
        </CFView>
      </MobileScreen>
      <RestaurantModal
        isOpen={showModal === 'restaurant'}
        restaurant={restaurant}
        onRequestClose={closeModal}
      />
      <PromotionModal
        isOpen={showModal === 'promotion'}
        restaurant={restaurant}
        onRequestClose={closeModal}
      />
    </>
  )
}
