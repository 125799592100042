import { init } from '@rematch/core'
import { plugins } from 'cf-core'
import { plugin as restaurantPlugin } from 'cf-models-restaurant'
import { plugin as dashboardPlugin } from 'cf-models-dashboard'
import * as models from 'models'
import ENV from 'ENV'

export const store = init({
  models,
  plugins: [
    plugins.actions,
    dashboardPlugin(ENV.SERVER_URL),
    restaurantPlugin(null, null, null, false),
  ],
})

export const { dispatch, getState } = store
