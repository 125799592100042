import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFIcon,
  CFSelect,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
  PageHeader,
  HorizontalLine,
} from 'components'
import {
  AddIcon,
  Button,
  DollarIcon,
  DisableIcon,
  EditIcon,
  TickIcon,
  TickCircleIcon,
  TrashIcon,
} from 'evergreen-ui'
import AddChargeModal from './AddChargeModal'
import RefundModal from './RefundModal'
import CancelModal from './CancelModal'
import WaitTimeModal from './WaitTimeModal'
import OnlineStatusModal from './OnlineStatusModal'
import { dispatch } from 'store'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { COLOR } from 'styles'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [order, setOrder] = useState({})
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubscribeOrders = dispatch.dashboard.subscribeOrders()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubscribeOrders()
      unsubRestaurantLocations()
    }
  }, [locationId, restaurantId])

  const updateOrderStatus = (status, order) => {
    const { id: orderId, userId } = order
    const restaurantId = dispatch.restaurant.getRestaurantId()
    return dispatch.dashboard.updateOrderStatus({
      restaurantId,
      orderId,
      userId,
      order: { status },
    })
  }

  const showModalType = (type, order) => {
    setOrder(order)
    setShowModal(type)
  }

  const deleteOrder = async (order) => {
    if (window.confirm('Are you sure you want to delete?')) {
      try {
        const { id: orderId, orderNumber } = order
        await dispatch.dashboard.deleteOrder({ orderId })
        dispatch.notification.setMessage({
          message: `Order# ${orderNumber} successfully deleted!`,
          level: 'success',
        })
      } catch {
        alert('Error deleting order.')
      }
    }
  }

  const closeModal = () => {
    setShowModal('')
    setOrder('')
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            {dispatch.dashboard.getRole() !== 'admin' && (
              <BackButton mb="10px" onClick={history.goBack} />
            )}
            <Card
              h={
                dispatch.dashboard.getRole() !== 'admin'
                  ? 'calc(100vh - 148px)'
                  : 'calc(100vh - 110px)'
              }
              w="1080px"
              column
              overflowY="auto"
            >
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Orders
                </CFView>
                <CFView row>
                  <CFSelect selector={dispatch.restaurant.getWaitTime}>
                    {(waitTime) => (
                      <Button
                        iconBefore={EditIcon}
                        height={40}
                        onClick={() => setShowModal('wait time')}
                      >
                        {`Wait Time: ${waitTime} minutes`}
                      </Button>
                    )}
                  </CFSelect>
                  <CFView w="10px" />
                  <CFSelect selector={dispatch.restaurant.getOrderOpen}>
                    {(onlineStatus) => (
                      <Button
                        iconBefore={EditIcon}
                        height={40}
                        onClick={() => setShowModal('online status')}
                      >
                        {`Status: ${onlineStatus ? 'Online' : 'Offline'}`}
                      </Button>
                    )}
                  </CFSelect>
                </CFView>
              </CFView>
              <CFSelect selector={dispatch.dashboard.getOrders}>
                {(orders) =>
                  Object.values(orders).length > 0 ? (
                    <CFView p="20px" column>
                      {Object.values(orders).map((order) => (
                        <Card mb="10px" column justifyBetween key={order.id}>
                          <CFView
                            row
                            p="10px"
                            mb="5px"
                            bg="black"
                            justifyBetween
                            alignCenter
                          >
                            <CFView row justifyStart alignCenter>
                              <CFView
                                xBold
                                white
                                fontSize={19}
                              >{` ${order.status}`}</CFView>
                              <CFView
                                row
                                center
                                xBold
                                fontSize={19}
                                color={COLOR.brand}
                                mh="4px"
                              >
                                {`${
                                  order.orderType ? order.orderType : 'Pickup'
                                }`}
                              </CFView>
                              <CFView
                                bold
                                white
                                fontSize={19}
                                xBold
                                mr="5px"
                              >{`Order #${order.orderNumber} -`}</CFView>
                              <CFView white xBold fontSize={19}>{` ${
                                order.paymentMethod
                                  ? order.paymentMethod === 'online'
                                    ? 'Paid Online'
                                    : 'Paid Offline'
                                  : 'Paid Online'
                              }`}</CFView>
                            </CFView>
                            <CFView
                              white
                              bold
                              fontSize={16}
                            >{`Received: ${moment(order.createdAt).format(
                              'LT, DD/MM/YY'
                            )}`}</CFView>
                          </CFView>
                          <CFView row justifyBetween alignStart p="15px">
                            <CFView
                              column
                              bc="black"
                              bw="1.5px"
                              br="2px"
                              p="15px"
                              w="400px"
                            >
                              <CFView
                                bg="lightgrey"
                                p="10px"
                                fontSize={16}
                                mb="15px"
                              >{`Special Requests: ${
                                order.notes ? order.notes : 'None'
                              }`}</CFView>
                              {Object.values(order.products)
                                .concat(order.choicesProducts || [])
                                .map((product, index) => {
                                  return (
                                    <CFView
                                      key={index}
                                      column
                                      justifyStart
                                      alignStart
                                    >
                                      <CFView
                                        row
                                        justifyBetween
                                        alignStart
                                        w="100%"
                                      >
                                        <CFView
                                          row
                                          justifyStart
                                          alignStart
                                          maxWidth="250px"
                                        >
                                          <CFView
                                            column
                                            center
                                            p="5px"
                                            w="25px"
                                            h="25px"
                                            bg="darkgrey"
                                            mr="7px"
                                            br="3px"
                                            bold
                                            fontSize={11}
                                          >{`${product.count}X `}</CFView>
                                          <CFView
                                            column
                                            center
                                            bold
                                            mt="3px"
                                            fontSize={16}
                                          >{`${product.name}`}</CFView>
                                        </CFView>
                                        <CFView
                                          column
                                          center
                                          mr="15px"
                                          fontSize={16}
                                        >{`$${(
                                          product.price * product.count
                                        ).toFixed(2)}`}</CFView>
                                      </CFView>
                                      <CFView column mv="8px">
                                        {product.choices &&
                                          product.choices.map(
                                            (choice, index) => {
                                              return (
                                                <CFView
                                                  row
                                                  justifyBetween
                                                  ml="40px"
                                                  minWidth="130px"
                                                  maxWidth="300px"
                                                  key={index}
                                                >
                                                  <CFText
                                                    h6
                                                  >{`• ${choice.name}`}</CFText>
                                                  {choice.price > 0 && (
                                                    <CFView
                                                      h6
                                                    >{`$${choice.price.toFixed(
                                                      2
                                                    )}`}</CFView>
                                                  )}
                                                </CFView>
                                              )
                                            }
                                          )}
                                      </CFView>
                                    </CFView>
                                  )
                                })}
                              {Object.keys(order.rewards).length > 0 && (
                                <CFView column>
                                  {Object.values(order.rewards).map(
                                    (reward, index) => {
                                      return (
                                        <CFView
                                          row
                                          justifyStart
                                          alignStart
                                          mb="8px"
                                          maxWidth="240px"
                                          key={index}
                                        >
                                          <CFView
                                            column
                                            center
                                            p="5px"
                                            w="25px"
                                            h="25px"
                                            bg="darkgrey"
                                            mr="7px"
                                            br="3px"
                                            bold
                                            fontSize={11}
                                          >{`${reward.count}X `}</CFView>
                                          <CFIcon
                                            icon="gift"
                                            color="red"
                                            mr="5px"
                                            fontSize={20}
                                            mt="3px"
                                          />
                                          <CFView
                                            column
                                            center
                                            bold
                                            fontSize={16}
                                            mt="3px"
                                          >{`${reward.name}`}</CFView>
                                        </CFView>
                                      )
                                    }
                                  )}
                                </CFView>
                              )}
                              {!!order.addedCharges &&
                                order.addedCharges.map((charge, index) => {
                                  return (
                                    <CFView
                                      row
                                      justifyBetween
                                      alignCenter
                                      key={index}
                                    >
                                      <CFView
                                        row
                                        justifyStart
                                        alignStart
                                        mb="8px"
                                        maxWidth="250px"
                                      >
                                        <CFView
                                          column
                                          center
                                          p="5px"
                                          w="25px"
                                          h="25px"
                                          bg="darkgrey"
                                          mr="7px"
                                          br="3px"
                                          bold
                                          fontSize={11}
                                        >
                                          1X
                                        </CFView>
                                        <CFView
                                          column
                                          center
                                          bold
                                          fontSize={16}
                                          mt="3px"
                                        >{`Added Charge: ${charge.name}`}</CFView>
                                      </CFView>
                                      <CFView
                                        column
                                        center
                                        fontSize={16}
                                        mb="8px"
                                        mr="15px"
                                      >{`$${charge.price.toFixed(2)}`}</CFView>
                                    </CFView>
                                  )
                                })}
                              <HorizontalLine mv="15px" color="black" />
                              <CFView column justifyCenter alignEnd mr="15px">
                                <CFView
                                  fontSize={16}
                                  mv="8px"
                                >{`Subtotal: $${order.subTotal.toFixed(
                                  2
                                )}`}</CFView>
                                {order.discount > 0 && (
                                  <CFView
                                    fontSize={16}
                                    mb="8px"
                                  >{`Discount: -$${order.discount.toFixed(
                                    2
                                  )}`}</CFView>
                                )}
                                {order.orderType === 'Delivery' && (
                                  <CFView fontSize={16} mb="8px">
                                    {`Delivery Fee: $${order.deliveryFee.toFixed(
                                      2
                                    )}`}
                                  </CFView>
                                )}
                                <CFView
                                  fontSize={16}
                                  mb="8px"
                                >{`Tax: $${order.taxAmount.toFixed(
                                  2
                                )}`}</CFView>
                                {order.bagFeeAmount > 0 && (
                                  <CFView fontSize={16} mb="8px">
                                    {`Bag Fee: $${order.bagFeeAmount.toFixed(
                                      2
                                    )}`}
                                  </CFView>
                                )}
                                {order.tipAmount > 0 && (
                                  <CFView
                                    fontSize={16}
                                    mb="8px"
                                  >{`Tip: $${order.tipAmount.toFixed(
                                    2
                                  )}`}</CFView>
                                )}
                                {!!order.refunds &&
                                  order.refunds.map((refund, index) => {
                                    return (
                                      <CFView
                                        row
                                        justifyStart
                                        alignCenter
                                        mb="8px"
                                        key={index}
                                      >
                                        <CFView
                                          column
                                          center
                                          fontSize={16}
                                        >{`Refund (${
                                          refund.description
                                        }): -$${refund.amount.toFixed(
                                          2
                                        )}`}</CFView>
                                      </CFView>
                                    )
                                  })}
                              </CFView>
                              <HorizontalLine mv="15px" color="black" />
                              <CFView
                                column
                                alignEnd
                                justifyCenter
                                fontSize={16}
                                mv="8px"
                                mr="15px"
                              >{`Total: $${(
                                order.total - (order.totalRefundAmount || 0)
                              ).toFixed(2)}`}</CFView>
                            </CFView>
                            <CFView column justifyStart alignStart w="350px">
                              {order.orderType === 'Dine-in' && (
                                <CFView row fontSize={16} mb="8px">
                                  <CFView xBold fontSize={15} mr="10px">
                                    Table #:
                                  </CFView>
                                  {order.tableNumber}
                                </CFView>
                              )}
                              {!isEmpty(order.doordashDetails) && (
                                <CFView row fontSize={16} mb="8px">
                                  <CFView xBold fontSize={15} mr="10px">
                                    DoorDash Id:
                                  </CFView>
                                  {`#${order.doordashDetails.id}`}
                                </CFView>
                              )}
                              <CFView fontSize={16} mb="8px" row>
                                <CFView xBold fontSize={15} mr="10px">
                                  Name:
                                </CFView>
                                {`${order.name}`}
                              </CFView>
                              <CFView fontSize={16} mb="8px" row>
                                <CFView xBold fontSize={15} mr="10px">
                                  Phone:
                                </CFView>
                                {` ${order.phoneNumber}`}
                              </CFView>
                              <CFView fontSize={16} mb="8px" row>
                                <CFView xBold fontSize={15} mr="10px">
                                  Email:
                                </CFView>
                                {`${order.email}`}
                              </CFView>
                              <CFView row fontSize={16} mb="8px">
                                <CFView xBold fontSize={15} mr="10px">
                                  Address:
                                </CFView>
                                {order.orderType === 'Delivery'
                                  ? `${order.aptNumber} ${order.address}`
                                  : ''}
                              </CFView>
                              <CFView fontSize={16} mb="8px" row>
                                <CFView xBold fontSize={15} mr="10px">
                                  Delivery Note:
                                </CFView>
                                {order.orderType === 'Delivery'
                                  ? ` ${order.deliveryInstructions}`
                                  : ''}
                              </CFView>
                              <CFView mb="8px" fontSize={16} row>
                                <CFView xBold fontSize={15} mr="10px">
                                  Complete By:
                                </CFView>
                                {`${moment(order.completedAt).format(
                                  'LT, DD/MM/YY'
                                )}`}
                              </CFView>
                            </CFView>
                            {order.status === 'New' ? (
                              <CFView row>
                                <Button
                                  iconBefore={TickCircleIcon}
                                  appearance="primary"
                                  height={50}
                                  intent="success"
                                  onClick={() =>
                                    updateOrderStatus('Preparing', order)
                                  }
                                >
                                  Confirm
                                </Button>
                                <CFView w="5px" />
                              </CFView>
                            ) : (
                              <CFView column justifyBetween p="10px">
                                {dispatch.dashboard.getRole() !== 'admin' && (
                                  <Button
                                    iconBefore={TrashIcon}
                                    marginBottom={15}
                                    intent="danger"
                                    height={40}
                                    onClick={() => deleteOrder(order)}
                                  >
                                    Delete
                                  </Button>
                                )}
                                <CFView w="5px" />
                                <Button
                                  iconBefore={AddIcon}
                                  marginBottom={15}
                                  height={40}
                                  onClick={() =>
                                    showModalType('add charge', order)
                                  }
                                >
                                  Add Charge
                                </Button>
                                <Button
                                  iconBefore={DollarIcon}
                                  marginBottom={15}
                                  height={40}
                                  onClick={() => showModalType('refund', order)}
                                >
                                  Partial Refund
                                </Button>
                                <Button
                                  iconBefore={DisableIcon}
                                  marginBottom={15}
                                  height={40}
                                  onClick={() => showModalType('cancel', order)}
                                >
                                  Cancel Order
                                </Button>
                                <CFView w="5px" />
                                <Button
                                  iconBefore={TickIcon}
                                  height={40}
                                  onClick={() =>
                                    updateOrderStatus('Done', order)
                                  }
                                >
                                  Done
                                </Button>
                              </CFView>
                            )}
                          </CFView>
                        </Card>
                      ))}
                    </CFView>
                  ) : (
                    <CFView h2 bold fill center>
                      No Orders
                    </CFView>
                  )
                }
              </CFSelect>
            </Card>
          </CFView>
          <CFSelect selector={dispatch.restaurant.getWaitTime}>
            {(waitTime) => (
              <WaitTimeModal
                waitTime={waitTime}
                isOpen={showModal === 'wait time'}
                onRequestClose={closeModal}
              />
            )}
          </CFSelect>
          <CFSelect selector={dispatch.restaurant.getOrderOpen}>
            {(onlineStatus) => (
              <OnlineStatusModal
                onlineStatus={onlineStatus}
                isOpen={showModal === 'online status'}
                onRequestClose={closeModal}
              />
            )}
          </CFSelect>
          <AddChargeModal
            isOpen={showModal === 'add charge'}
            order={order}
            onRequestClose={closeModal}
          />
          <RefundModal
            isOpen={showModal === 'refund'}
            order={order}
            onRequestClose={closeModal}
          />
          <CancelModal
            isOpen={showModal === 'cancel'}
            order={order}
            onRequestClose={closeModal}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="Orders"
          LeftButton={
            dispatch.dashboard.getRole() === 'admin' ? null : (
              <BackButton onClick={history.goBack} />
            )
          }
        />
        <CFView
          h="calc(100vh - 120px)"
          column
          overflowY="auto"
          minWidth="320px"
        >
          <CFView column>
            <CFView p="20px" column center bg="rgba(0,0,0,.3)">
              <CFView column center>
                <CFSelect selector={dispatch.restaurant.getWaitTime}>
                  {(waitTime) => (
                    <Button
                      width={210}
                      iconBefore={EditIcon}
                      height={40}
                      onClick={() => setShowModal('wait time')}
                    >
                      {`Wait Time: ${waitTime} minutes`}
                    </Button>
                  )}
                </CFSelect>
                <CFView h="10px" />
                <CFSelect selector={dispatch.restaurant.getOrderOpen}>
                  {(onlineStatus) => (
                    <Button
                      width={210}
                      iconBefore={EditIcon}
                      height={40}
                      onClick={() => setShowModal('online status')}
                    >
                      {`Store Status: ${onlineStatus ? 'Online' : 'Offline'}`}
                    </Button>
                  )}
                </CFSelect>
              </CFView>
            </CFView>
            <CFSelect selector={dispatch.dashboard.getOrders}>
              {(orders) =>
                Object.values(orders).length > 0 ? (
                  <CFView p="20px" column>
                    {Object.values(orders).map((order, index) => (
                      <Card mb="10px" column justifyBetween key={order.id}>
                        <CFView p="10px" mb="5px" bg="black" column center>
                          <CFView row justifyStart alignStart mb="10px">
                            <CFView
                              bold
                              white
                              mr="5px"
                            >{`ORDER #${order.orderNumber} -`}</CFView>
                            <CFView
                              white
                              bold
                              fontSize={19}
                            >{` ${order.status}`}</CFView>
                          </CFView>
                          <CFView white bold fontSize={19}>{`Received: ${moment(
                            order.createdAt
                          ).format('LT, DD/MM/YY')}`}</CFView>
                        </CFView>
                        <CFView column justifyStart alignStartF p="15px">
                          <CFView column justifyStart alignStart mb="10px">
                            {Object.values(order.products)
                              .concat(order.choicesProducts || [])
                              .map((product, index) => {
                                return (
                                  <CFView
                                    column
                                    justifyStart
                                    alignStart
                                    key={index}
                                  >
                                    <CFView row justifyStart alignStart>
                                      <CFView
                                        column
                                        center
                                        p="5px"
                                        w="25px"
                                        h="25px"
                                        bg="darkgrey"
                                        mr="7px"
                                        br="3px"
                                        bold
                                        fontSize={11}
                                      >{`${product.count}X `}</CFView>
                                      <CFView
                                        column
                                        center
                                        bold
                                        fontSize={16}
                                        mt="3px"
                                      >{`${product.name}`}</CFView>
                                    </CFView>
                                    <CFView column mb="5px">
                                      {product.choices &&
                                        product.choices.map((choice, index) => {
                                          return (
                                            <CFView ml="28px" key={index}>
                                              <CFText
                                                h6
                                              >{`• ${choice.name}`}</CFText>
                                            </CFView>
                                          )
                                        })}
                                    </CFView>
                                  </CFView>
                                )
                              })}
                            {Object.keys(order.rewards).length > 0 && (
                              <CFView column>
                                {Object.values(order.rewards).map(
                                  (reward, index) => {
                                    return (
                                      <CFView
                                        row
                                        justifyStart
                                        alignCenter
                                        mb="8px"
                                        key={index}
                                      >
                                        <CFView
                                          column
                                          center
                                          p="5px"
                                          w="25px"
                                          h="25px"
                                          bg="darkgrey"
                                          mr="7px"
                                          br="3px"
                                          bold
                                          fontSize={11}
                                        >{`${reward.count}X `}</CFView>
                                        <CFIcon
                                          icon="gift"
                                          color="red"
                                          mr="5px"
                                          fontSize={20}
                                        />
                                        <CFView
                                          column
                                          center
                                          bold
                                          fontSize={16}
                                        >{`${reward.name}`}</CFView>
                                      </CFView>
                                    )
                                  }
                                )}
                              </CFView>
                            )}
                          </CFView>
                          {!!order.addedCharges &&
                            order.addedCharges.map((charge, index) => {
                              return (
                                <CFView
                                  row
                                  justifyBetween
                                  alignStart
                                  key={index}
                                >
                                  <CFView
                                    row
                                    justifyStart
                                    alignStart
                                    mb="8px"
                                    maxWidth="230px"
                                  >
                                    <CFView
                                      column
                                      center
                                      p="5px"
                                      w="25px"
                                      h="25px"
                                      bg="darkgrey"
                                      mr="7px"
                                      br="3px"
                                      bold
                                      fontSize={11}
                                    >
                                      1X
                                    </CFView>
                                    <CFView
                                      column
                                      center
                                      bold
                                      fontSize={16}
                                      mt="3px"
                                    >{`Added Charge: ${charge.name}`}</CFView>
                                  </CFView>
                                  <CFView
                                    column
                                    center
                                    fontSize={16}
                                    mb="8px"
                                    mr="15px"
                                    mt="3px"
                                  >{`$${charge.price.toFixed(2)}`}</CFView>
                                </CFView>
                              )
                            })}
                          <HorizontalLine mv="8px" color="black" />
                          <CFView column justifyStart alignStart mv="15px">
                            <CFView
                              column
                              justifyEnd
                              alignEnd
                              w="100%"
                              pr="10px"
                            >
                              <CFView fontSize={16} mb="8px">{`Subtotal: $${(
                                order.subTotal - order.discount
                              ).toFixed(2)}`}</CFView>
                              {order.discount > 0 && (
                                <CFView
                                  fontSize={16}
                                  mb="8px"
                                >{`Discount: -$${order.discount.toFixed(
                                  2
                                )}`}</CFView>
                              )}
                              <CFView
                                fontSize={16}
                                mb="8px"
                              >{`Tax: $${order.taxAmount.toFixed(2)}`}</CFView>
                              {order.bagFeeAmount > 0 && (
                                <CFView fontSize={16} mb="8px">
                                  {`Bag Fee: $${order.bagFeeAmount.toFixed(2)}`}
                                </CFView>
                              )}
                              {order.tipAmount > 0 && (
                                <CFView
                                  fontSize={16}
                                  mb="8px"
                                >{`Tip: $${order.tipAmount.toFixed(
                                  2
                                )}`}</CFView>
                              )}
                              {!!order.refunds &&
                                order.refunds.map((refund, index) => {
                                  return (
                                    <CFView
                                      row
                                      justifyStart
                                      alignCenter
                                      mb="8px"
                                      key={index}
                                    >
                                      <CFView
                                        column
                                        center
                                        fontSize={16}
                                      >{`Refund (${
                                        refund.description
                                      }): -$${refund.amount.toFixed(
                                        2
                                      )}`}</CFView>
                                    </CFView>
                                  )
                                })}
                              <CFView fontSize={16} mb="10px">{`Total: $${(
                                order.total - (order.totalRefundAmount || 0)
                              ).toFixed(2)}`}</CFView>
                            </CFView>
                            <HorizontalLine mv="10px" color="black" />
                            <CFView column justifyStart alignStart>
                              <CFView
                                mt="10px"
                                mb="8px"
                                fontSize={16}
                              >{`Order Type: ${
                                order.orderType ? order.orderType : 'Pickup'
                              }`}</CFView>
                              <CFView mb="8px" fontSize={16}>{`Payment Type: ${
                                order.paymentMethod
                                  ? order.paymentMethod === 'online'
                                    ? 'Paid Online'
                                    : 'Paid Offline'
                                  : 'Paid Online'
                              }`}</CFView>
                              {order.orderType === 'Dine-in' && (
                                <CFView row fontSize={16} mb="8px">
                                  <CFView xBold fontSize={15} mr="10px">
                                    Table #:
                                  </CFView>
                                  {order.tableNumber}
                                </CFView>
                              )}
                              {!isEmpty(order.doordashDetails) && (
                                <CFView row fontSize={16} mb="8px">
                                  <CFView xBold fontSize={15} mr="10px">
                                    DoorDash Id:
                                  </CFView>
                                  {`#${order.doordashDetails.id}`}
                                </CFView>
                              )}
                              <CFView
                                fontSize={16}
                                mb="8px"
                                maxWidth="200px"
                              >{`Name: ${order.name}`}</CFView>
                              <CFView
                                fontSize={16}
                                mb="8px"
                                maxWidth="200px"
                              >{`Phone: ${order.phoneNumber}`}</CFView>
                              <CFView
                                fontSize={16}
                                mb="8px"
                                maxWidth="200px"
                              >{`Email: ${order.email}`}</CFView>
                              <CFView
                                fontSize={16}
                                mb="8px"
                                maxWidth="200px"
                              >{`Special Requests: ${
                                order.notes ? order.notes : 'None'
                              }`}</CFView>
                              <CFView fontSize={16}>{`Complete By: ${moment(
                                order.completedAt
                              ).format('LT, DD/MM/YY')}`}</CFView>
                            </CFView>
                          </CFView>
                          {order.status === 'New' ? (
                            <CFView column center>
                              <Button
                                iconBefore={TickCircleIcon}
                                appearance="primary"
                                height={40}
                                intent="success"
                                onClick={() =>
                                  updateOrderStatus('Preparing', order)
                                }
                              >
                                Confirm
                              </Button>
                              <CFView w="5px" />
                            </CFView>
                          ) : (
                            <CFView column justifyBetween alignCenter p="10px">
                              {dispatch.dashboard.getRole() !== 'admin' && (
                                <Button
                                  iconBefore={TrashIcon}
                                  marginBottom={10}
                                  width={160}
                                  intent="danger"
                                  height={40}
                                  onClick={() => deleteOrder(order)}
                                >
                                  Delete
                                </Button>
                              )}
                              <CFView w="5px" />
                              <Button
                                iconBefore={AddIcon}
                                width={160}
                                marginBottom={10}
                                height={40}
                                onClick={() =>
                                  showModalType('add charge', order)
                                }
                              >
                                Add Charge
                              </Button>
                              <Button
                                iconBefore={DollarIcon}
                                width={160}
                                marginBottom={10}
                                height={40}
                                onClick={() => showModalType('refund', order)}
                              >
                                Partial Refund
                              </Button>
                              <Button
                                iconBefore={DisableIcon}
                                width={160}
                                marginBottom={10}
                                height={40}
                                onClick={() => showModalType('cancel', order)}
                              >
                                Cancel Order
                              </Button>
                              <CFView w="5px" />
                              <Button
                                iconBefore={TickIcon}
                                width={160}
                                height={40}
                                onClick={() => updateOrderStatus('Done', order)}
                              >
                                Done
                              </Button>
                            </CFView>
                          )}
                        </CFView>
                      </Card>
                    ))}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center p="20px">
                    No Orders
                  </CFView>
                )
              }
            </CFSelect>
          </CFView>
          <CFSelect selector={dispatch.restaurant.getWaitTime}>
            {(waitTime) => (
              <WaitTimeModal
                waitTime={waitTime}
                isOpen={showModal === 'wait time'}
                onRequestClose={closeModal}
              />
            )}
          </CFSelect>
          <CFSelect selector={dispatch.restaurant.getOrderOpen}>
            {(onlineStatus) => (
              <OnlineStatusModal
                onlineStatus={onlineStatus}
                isOpen={showModal === 'online status'}
                onRequestClose={closeModal}
              />
            )}
          </CFSelect>
          <AddChargeModal
            isOpen={showModal === 'add charge'}
            order={order}
            onRequestClose={closeModal}
          />
          <RefundModal
            isOpen={showModal === 'refund'}
            order={order}
            onRequestClose={closeModal}
          />
          <CancelModal
            isOpen={showModal === 'cancel'}
            order={order}
            onRequestClose={closeModal}
          />
        </CFView>
      </MobileScreen>
    </>
  )
}
