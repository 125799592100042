import React, { useState, useEffect } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

const initialState = {
  passcode: '',
}

export default (props) => {
  const [passcode, setPasscode] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (!isEmpty(props.passcode)) {
      setPasscode(props.passcode)
    }
  }, [props.passcode])

  const updatePasscode = async () => {
    if (passcode && isNaN(passcode)) {
      alert('Passcode must be a number.')
      return
    }
    if (passcode && passcode.length !== 4) {
      alert('Passcode must be 4 digits.')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.updatePasscode({ passcode })
      dispatch.notification.setMessage({
        message: `Passcode updated!`,
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error updating passcode.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Edit Passcode" {...props}>
      <CFView column w="360px">
        <CFView p="20px" column>
          <LabelInput
            mt="10px"
            label="Passcode"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
          />
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updatePasscode}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
