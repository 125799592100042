import React, { useState, useEffect } from 'react'
import {
  Card,
  CFIcon,
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import {
  Button,
  Checkbox,
  TextInputField,
  TrashIcon,
  AddIcon,
  FloppyDiskIcon,
} from 'evergreen-ui'
import { dispatch } from 'store'
import * as utils from 'cf-utils'
import { trim, isEmpty } from 'lodash'

const initialFields = {
  name: '',
  required: '0',
  maxSelect: '0',
}

const initialChoices = [
  {
    selected: true,
    name: '',
    price: '0',
    posId: '',
    posName: '',
    activeAfter: utils.moment().year(2000).valueOf(),
  },
]

export default (props) => {
  const [fields, setFields] = useState(initialFields)
  const [choices, setChoices] = useState(initialChoices)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.modifier) {
      const { name, required, maxSelect, choices } = props.modifier
      setFields({ name, required, maxSelect })
      setChoices(choices)
    } else {
      setFields({
        name: '',
        required: '0',
        maxSelect: '0',
        posId: '',
        posName: '',
      })
      setChoices([
        {
          selected: true,
          name: '',
          price: '0',
          posId: '',
          posName: '',
          activeAfter: utils.moment().year(2000).valueOf(),
        },
      ])
    }
  }, [props.modifier])

  const updateModifier = async () => {
    if (!isFormValid()) {
      return
    }
    let { name, required, maxSelect } = fields
    let choiceCount = 0
    const newChoices = choices.map((choice) => {
      const { price, name, selected } = choice
      if (selected) {
        choiceCount++
      }
      return {
        ...choice,
        price: Number(price),
        name: trim(name),
        selected,
      }
    })
    const modifierDetails = {
      name: trim(name),
      required: Number(required),
      maxSelect: Number(maxSelect),
      choices: newChoices,
      choiceCount,
    }
    setSaving(true)
    try {
      if (props.modifier) {
        await dispatch.dashboard.updateModifier({
          modifierId: props.modifier.id,
          modifierDetails,
        })
      } else {
        await dispatch.dashboard.createModifier({
          modifierDetails,
        })
      }
      dispatch.notification.setMessage({
        message: `Modifier saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialFields)
      setChoices(initialChoices)
    } catch (e) {
      alert('Error saving modifier.')
    } finally {
      setSaving(false)
    }
  }

  const addChoice = () => {
    const newChoices = [...choices]
    newChoices.push({ selected: true, name: '', price: '0' })
    setChoices(newChoices)
  }

  const updateChoice = (name, value, index) => {
    const newChoices = [...choices]
    newChoices[index][name] = value
    setChoices(newChoices)
  }

  const removeChoice = (index) => {
    if (choices.length === 1) {
      setChoices([
        {
          selected: true,
          name: '',
          price: '0',
          posId: '',
          posName: '',
          activeAfter: utils.moment().year(2000).valueOf(),
        },
      ])
    } else {
      const newChoices = [...choices]
      newChoices.splice(index, 1)
      setChoices(newChoices)
    }
  }

  const isFormValid = () => {
    const { name, required, maxSelect } = fields
    if (name === '') {
      alert('Please enter a valid Modifier Name')
      return false
    }
    if (isNaN(Number(required))) {
      alert('Required must be a number')
      return false
    }
    if (isNaN(Number(maxSelect))) {
      alert('Max Selection must be a number')
      return false
    }
    if (isEmpty(choices)) {
      alert('Must have at least one valid choice')
      return false
    }
    let valid = true
    choices.some(({ name, price, posId }) => {
      if (name === '') {
        alert('Please enter a valid Choice Name')
        valid = false
        return true
      }
      if (isNaN(Number(price))) {
        alert('Price must be a number')
        valid = false
        return true
      }
      if (
        dispatch.restaurant.getIntegrationId() === 'ini' &&
        (isEmpty(posId) || isNaN(Number(posId)))
      ) {
        alert(
          'Please enter a valid POS ID for each choice. Contact INI for help.'
        )
        valid = false
        return true
      }
      return false
    })
    return valid
  }

  return (
    <PrimaryModal
      title={
        props.modifier ? `Edit - ${props.modifier.name}` : 'Create Modifier'
      }
      {...props}
    >
      <DefaultScreen>
        <CFView column>
          <CFView p="20px" bg="white" row>
            <CFView column>
              <CFView row mb="15px">
                <TextInputField
                  marginRight="15px"
                  label="Modifier Name"
                  value={fields.name}
                  onChange={(e) =>
                    setFields({ ...fields, name: e.target.value })
                  }
                />
                <TextInputField
                  marginRight="15px"
                  label="Required"
                  value={fields.required}
                  onChange={(e) =>
                    setFields({ ...fields, required: e.target.value })
                  }
                />
                <TextInputField
                  label="Max Selection"
                  value={fields.maxSelect}
                  onChange={(e) =>
                    setFields({ ...fields, maxSelect: e.target.value })
                  }
                />
              </CFView>
              <Card column center p="10px">
                <CFView bold textCenter mb="10px">
                  Modifer Choices
                </CFView>
                {choices.map(
                  (
                    { selected, name, price, posId, posName, activeAfter },
                    index
                  ) => (
                    <CFView row center>
                      <Checkbox
                        marginRight={15}
                        label="Default"
                        onChange={(e) =>
                          updateChoice('selected', e.target.checked, index)
                        }
                        checked={selected}
                      />
                      <TextInputField
                        marginRight="15px"
                        label="Choice Name"
                        value={name}
                        onChange={(e) =>
                          updateChoice('name', e.target.value, index)
                        }
                      />
                      <TextInputField
                        label="Price"
                        marginRight="15px"
                        value={price}
                        onChange={(e) =>
                          updateChoice('price', e.target.value, index)
                        }
                      />
                      {dispatch.restaurant.getIntegrationId() === 'ini' && (
                        <>
                          <TextInputField
                            marginRight="15px"
                            label="POS ID"
                            value={posId}
                            onChange={(e) =>
                              updateChoice('posId', e.target.value, index)
                            }
                          />
                          <TextInputField
                            marginRight="15px"
                            label="POS Name"
                            value={posName}
                            onChange={(e) =>
                              updateChoice('posName', e.target.value, index)
                            }
                          />
                        </>
                      )}
                      <Checkbox
                        marginRight={15}
                        label="Sold Out"
                        onChange={() =>
                          updateChoice(
                            'activeAfter',
                            activeAfter > utils.moment().valueOf()
                              ? utils.moment().year(2000).valueOf()
                              : utils.moment().year(3000).valueOf(),
                            index
                          )
                        }
                        checked={activeAfter > utils.moment().valueOf()}
                      />
                      <CFView
                        h="50px"
                        w="50px"
                        center
                        hover
                        onClick={() => removeChoice(index)}
                      >
                        <CFIcon icon="times-circle" color="red" fontSize={28} />
                      </CFView>
                    </CFView>
                  )
                )}
                <PrimaryButton
                  mt="10px"
                  w="40%"
                  label="Add Choice"
                  onClick={addChoice}
                />
              </Card>
            </CFView>
          </CFView>
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={updateModifier}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView column w="100%">
          <CFView p="20px" bg="white" column center minWidth="320px" w="100%">
            <CFView column w="100%">
              <CFView column center mb="15px" w="100%">
                <TextInputField
                  width="100%"
                  marginBottom={10}
                  label="Modifier Name"
                  value={fields.name}
                  onChange={(e) =>
                    setFields({ ...fields, name: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginBottom={10}
                  label="Required"
                  value={fields.required}
                  onChange={(e) =>
                    setFields({ ...fields, required: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginBottom={10}
                  label="Max Selection"
                  value={fields.maxSelect}
                  onChange={(e) =>
                    setFields({ ...fields, maxSelect: e.target.value })
                  }
                />
              </CFView>
              <CFView xBold textCenter mb="10px">
                Modifer Choices
              </CFView>
              <CFView column center>
                {choices.map(
                  (
                    { selected, name, price, posId, posName, activeAfter },
                    index
                  ) => (
                    <Card
                      column
                      center
                      w="100%"
                      mb="15px"
                      p="15px"
                      minWidth="300px"
                    >
                      <Checkbox
                        width="100%"
                        marginBottom={10}
                        label="Make This Choice Selected By Default"
                        onChange={(e) =>
                          updateChoice('selected', e.target.checked, index)
                        }
                        checked={selected}
                      />
                      <TextInputField
                        width="100%"
                        marginBottom={10}
                        label="Choice Name"
                        value={name}
                        onChange={(e) =>
                          updateChoice('name', e.target.value, index)
                        }
                      />
                      <TextInputField
                        width="100%"
                        label="Price"
                        value={price}
                        onChange={(e) =>
                          updateChoice('price', e.target.value, index)
                        }
                      />
                      <TextInputField
                        width="100%"
                        marginBottom={10}
                        label="POS ID"
                        value={posId}
                        onChange={(e) =>
                          updateChoice('posId', e.target.value, index)
                        }
                      />
                      <TextInputField
                        width="100%"
                        marginBottom={10}
                        label="POS Name"
                        value={posName}
                        onChange={(e) =>
                          updateChoice('posName', e.target.value, index)
                        }
                      />
                      <Checkbox
                        marginRight={15}
                        label="Sold Out"
                        onChange={() =>
                          updateChoice(
                            'activeAfter',
                            activeAfter > utils.moment().valueOf()
                              ? utils.moment().year(2000).valueOf()
                              : utils.moment().year(3000).valueOf(),
                            index
                          )
                        }
                        checked={activeAfter > utils.moment().valueOf()}
                      />
                      <Button
                        iconBefore={TrashIcon}
                        width={200}
                        intent="danger"
                        height={40}
                        onClick={() => removeChoice(index)}
                      >
                        Remove This Choice
                      </Button>
                    </Card>
                  )
                )}
                <Button
                  iconBefore={AddIcon}
                  marginBottom={0}
                  width={200}
                  appearance="primary"
                  intent="warning"
                  height={40}
                  onClick={addChoice}
                >
                  ADD MORE CHOICE
                </Button>
              </CFView>
            </CFView>
          </CFView>
          <CFView column center mb="20px">
            <Button
              iconBefore={FloppyDiskIcon}
              marginBottom={0}
              width={200}
              appearance="primary"
              height={40}
              onClick={updateModifier}
            >
              {saving ? 'SAVING...' : 'SAVE MODIFIER'}
            </Button>
          </CFView>
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
