import createDashboardModel from './dashboardModel'

export default (serverUrl) => ({
  config: {
    models: {
      dashboard: createDashboardModel(serverUrl),
    },
  },
  onStoreCreated(store) {},
})
