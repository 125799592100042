import { db, storageRef } from 'cf-core/src/config/firebase'

export function createRestaurant(restaurantDetails) {
  return db.collection('Restaurants').add(restaurantDetails)
}

export function updateRestaurant(restaurantId, restaurantDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .update(restaurantDetails)
}

export function createLocation(restaurantId, locationDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .add(locationDetails)
}

export function updateLocation(restaurantId, locationId, locationDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .update(locationDetails)
}

export function updateProduct(
  restaurantId,
  locationId,
  productId,
  productDetails
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Products')
    .doc(productId)
    .update(productDetails)
}
