export { default as cflogo } from './cflogo.png'
export { default as cflogo2 } from './cflogo2.png'
export { default as cfvideo } from './cfvideo.mov'
export { default as cftitle } from './cftitle.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582514489/general/marblebgdefault.jpg'
export const sharedBgMobile =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582514460/general/marblebg.jpg'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
