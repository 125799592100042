import React, { useState, useEffect } from 'react'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { SelectField, TextInputField } from 'evergreen-ui'
import { dispatch } from 'store'

const initialState = {
  email: '',
  password: '',
  name: '',
  role: 'server',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.user) {
      setFields({ ...initialState, ...props.user })
    } else {
      setFields({ ...initialState })
    }
  }, [props.user])

  const updateUser = async () => {
    const { email, password, name, role } = fields
    if (!name) {
      alert('Please enter a name.')
      return
    }
    if (!props.user) {
      if (!email) {
        alert('Please enter valid email.')
        return
      }
      if (!password) {
        alert('Please enter a password.')
        return
      }
    }
    setSaving(true)
    try {
      if (props.user) {
        const userId = props.user.id
        await dispatch.dashboard.updateUser({
          userId,
          user: { name, role },
        })
      } else {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const locationId = dispatch.restaurant.getSelectedLocationId()
        await dispatch.dashboard.registerUser({
          email,
          password,
          restaurantId,
          locationId,
          name,
          role,
        })
      }
      dispatch.notification.setMessage({
        message: `User saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert(e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal
      title={props.user ? `Edit ${props.user.email}` : 'Create User'}
      {...props}
    >
      <CFView column maxWidth="400px" minWidth="320px" p="20px">
        <SelectField
          label="Role"
          value={fields.role}
          onChange={(e) => setFields({ ...fields, role: e.target.value })}
        >
          <option value="server">Server</option>
        </SelectField>
        {!props.user && (
          <TextInputField
            label="Email"
            value={fields.email}
            onChange={(e) => setFields({ ...fields, email: e.target.value })}
          />
        )}
        {!props.user && (
          <TextInputField
            label="Password"
            value={fields.password}
            onChange={(e) => setFields({ ...fields, password: e.target.value })}
          />
        )}
        <TextInputField
          label="Name"
          value={fields.name}
          onChange={(e) => setFields({ ...fields, name: e.target.value })}
        />
        <PrimaryButton
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateUser}
        />
      </CFView>
    </PrimaryModal>
  )
}
