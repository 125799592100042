import React, { useState } from 'react'
import { CSVReader } from 'react-papaparse'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import { kebabCase } from 'lodash'

export default (props) => {
  const [status, setStatus] = useState('')
  const [saving, setSaving] = useState(false)
  const [CSVData, setCSVData] = useState([])

  const handleOnDrop = (data) => {
    setCSVData(data)
  }

  const handleOnError = (err, file, inputElem, reason) => {
    alert('Error with upload.')
  }

  const handleOnRemoveFile = () => {
    setCSVData([])
  }

  const capitalizeWords = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const generateMenu = async () => {
    setSaving(true)
    try {
      const menuSanitized = []
      let categoryCount = -1
      let productCount = -1
      let modifierCount = -1
      if (CSVData.length === 0) {
        throw Error('CSV is empty.')
      }
      CSVData.forEach(({ data }, index) => {
        let categoryName = ''
        let categoryDescription = ''
        if (!data[0]) {
          return
        }
        if (
          data[0] !== 'CATEGORY' &&
          data[0] !== 'PRODUCT' &&
          data[0] !== 'MODIFIER' &&
          data[0] !== 'CHOICE'
        ) {
          throw Error('Bad CSV format.')
        }
        if (data[0] === 'CATEGORY') {
          categoryName = capitalizeWords(data[1])
          categoryDescription = data[2]
          menuSanitized.push({
            categoryName,
            categoryDescription,
            orderType: 'all',
            products: [],
          })
          categoryCount += 1
          productCount = -1
        } else if (data[0] === 'PRODUCT') {
          const productName = capitalizeWords(data[1])
          const description = data[2]
          const price = Number(data[3])
          const taxRate = Number(data[4] || '0.05')
          const posId = data[5] || ''
          const posName = data[6] || '1'
          menuSanitized[categoryCount].products.push({
            productName,
            description,
            price,
            posId,
            posName,
            taxRate,
            orderType: 'all',
            modifiers: [],
          })
          productCount += 1
          modifierCount = -1
        } else if (data[0] === 'MODIFIER') {
          const modifierName = capitalizeWords(data[1])
          const choiceCount = Number(data[2])
          const maxSelect = Number(data[3])
          const required = Number(data[4])
          menuSanitized[categoryCount].products[productCount].modifiers.push({
            modifierName,
            choiceCount,
            maxSelect,
            required,
            choices: [],
          })
          modifierCount += 1
        } else if (data[0] === 'CHOICE') {
          const choiceName = capitalizeWords(data[1])
          const price = Number(data[2])
          const selected = data[3]
          const posId = data[4] || ''
          const posName = data[5] || ''
          menuSanitized[categoryCount].products[productCount].modifiers[
            modifierCount
          ].choices.push({
            choiceName,
            price,
            selected,
            posId,
            posName,
          })
        }
      })

      const categoryOrderIds = []
      for (let c = 0; c < menuSanitized.length; c++) {
        const category = menuSanitized[c]
        const {
          categoryName: name,
          categoryDescription: description,
          products,
        } = category

        const categoryId = await dispatch.dashboard.createCategory({
          menuId: props.menu.id,
          category: {
            name,
            description,
            imageUrl:
              dispatch.restaurant.getCategoryImageUrl(kebabCase(name)) ||
              'https://firebasestorage.googleapis.com/v0/b/cold-fusion-9419a.appspot.com/o/restaurants%2F39nCpPX9RUrUufu7REh1_test%2Fedamame.jpeg?alt=media&token=473bc344-7443-490a-9667-3954cc4ea192',
          },
        })
        setStatus('Category added: ' + name)
        categoryOrderIds.push(categoryId)

        const productOrder = []
        for (let p = 0; p < products.length; p++) {
          const product = products[p]
          const {
            productName,
            description,
            price,
            taxRate = 0.05,
            posId,
            posName,
          } = product
          const productId = await dispatch.dashboard.createProduct({
            categoryId,
            productDetails: {
              categoryId,
              name: productName,
              description,
              price,
              taxRate,
              posId,
              posName,
            },
          })
          productOrder.push(productId)
          setStatus('Product added: ' + productName)

          const productModifiers = product.modifiers || []
          const modifiers = []
          for (let m = 0; m < productModifiers.length; m++) {
            const modifier = productModifiers[m]
            const { modifierName, choiceCount, maxSelect, required } = modifier
            const modifierId = await dispatch.dashboard.createModifier({
              modifierDetails: {
                name: modifierName,
                choiceCount,
                maxSelect,
                required,
              },
            })
            modifiers.push(modifierId)
            setStatus('Modifier added: ' + modifierName)

            const modifiersChoices = modifier.choices || []
            const choices = []
            for (let ch = 0; ch < modifiersChoices.length; ch++) {
              const choice = modifiersChoices[ch]
              const { choiceName, price, selected, posId, posName } = choice
              const newChoice = {
                name: choiceName,
                price,
                posId,
                posName,
                selected: selected === '1' ? true : false,
              }
              choices.push(newChoice)
            }

            await dispatch.dashboard.updateModifier({
              modifierId,
              modifierDetails: { choices },
            })
          }

          await dispatch.dashboard.updateProduct({
            productId,
            productDetails: { modifiers },
          })
        }
        await dispatch.dashboard.updateCategory({
          categoryId,
          categoryDetails: { productOrder },
        })
      }
      dispatch.notification.setMessage({
        message: `Menu generated!`,
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error saving data.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title={`Generate ${props.menu.name} Menu`} {...props}>
      <CFView column w="500px" h="400px">
        {saving ? (
          <CFView fill center>
            <CFView>{status}</CFView>
          </CFView>
        ) : (
          <CFView p="20px" fill>
            <CSVReader
              noClick
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here.</span>
            </CSVReader>
          </CFView>
        )}
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={CSVData.length === 0 || saving}
            onClick={generateMenu}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
