import { db } from 'cf-core/src/config/firebase'
import * as api from '../api'
import { get } from 'lodash'

export default ({ dispatch, serverUrl }) => ({
  getIsLoggedIn() {
    return !!api.auth.getCurrentUser()
  },
  getUserToken() {
    return api.auth.getUserToken()
  },
  getUserId() {
    return get(api.auth.getCurrentUser(), 'uid')
  },
  getEmail() {
    return get(api.auth.getCurrentUser(), 'email')
  },
  registerUser(user) {
    return dispatch.dashboard.getUserToken().then((authToken) => {
      return api.server.registerUser(serverUrl, authToken, user)
    })
  },
  unregisterUser(userId) {
    return dispatch.dashboard.getUserToken().then((authToken) => {
      return api.server.unregisterUser(serverUrl, authToken, userId)
    })
  },
  subscribeUser(uid, onSuccess, onError) {
    return db
      .collection('Users')
      .doc(uid)
      .onSnapshot(
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!dispatch.dashboard.getIsLoggedIn()) {
            dispatch.dashboard.unsetUser()
            onError && onError({ message: 'user is not logged in' })
          } else {
            const userData = { id: snapshot.id, ...snapshot.data() }
            dispatch.dashboard.setUser(userData)
            if (!snapshot.metadata.fromCache) {
              onSuccess && onSuccess(userData)
            }
          }
        },
        onError
      )
  },
  signOut() {
    api.auth.signOut().then(() => {
      dispatch.dashboard.unsetUser()
    })
  },
})
