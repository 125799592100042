import { db } from 'cf-core/src/config/firebase'

export function createUser({ user }) {
  return db.collection('Users').add(user)
}

export function updateUser({ userId, user }) {
  return db.collection('Users').doc(userId).set(user, { merge: true })
}

export function deleteUser({ userId }) {
  return db.collection('Users').doc(userId).delete()
}
