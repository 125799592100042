import React, { useState, useEffect } from 'react'
import { CFSelect, CFView, PrimaryButton, PrimaryModal } from 'components'
import TableItem from './TableItem'
import { dispatch } from 'store'
import { includes, filter } from 'lodash'

export default (props) => {
  const [serverTables, setServerTables] = useState([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.user) {
      setServerTables(props.user.tables || [])
    }
  }, [props.user])

  const updateServerTables = (tableId) => {
    let newServerTables = []
    if (includes(serverTables, tableId)) {
      newServerTables = filter(serverTables, (id) => id !== tableId)
    } else {
      newServerTables = [...serverTables, tableId]
    }
    setServerTables(newServerTables)
  }

  const saveServerTables = async () => {
    setSaving(true)
    try {
      await dispatch.dashboard.updateUserTables(props.user.id, serverTables)
      dispatch.notification.setMessage({
        message: `Tables saved!`,
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error saving tables.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title={'Table Settings'} {...props}>
      <CFView column maxWidth="400px" minWidth="320px" p="20px">
        <CFSelect selector={dispatch.restaurant.getTables}>
          {(tables) => (
            <CFView mb="20px">
              {Object.keys(tables).map((tableId) => (
                <TableItem
                  tableNumber={tables[tableId].tableNumber}
                  selected={includes(serverTables, tableId)}
                  onClick={() => updateServerTables(tableId)}
                />
              ))}
            </CFView>
          )}
        </CFSelect>
        <PrimaryButton
          label={saving ? 'SAVING' : 'SAVE'}
          onClick={saveServerTables}
        />
      </CFView>
    </PrimaryModal>
  )
}
