import React from 'react'
import {
  CFSelect,
  CFView,
  CheckSelectButton,
  CircleSelectButton,
  DefaultScreen,
  MobileScreen,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import { dispatch } from 'store'
import { useState } from 'react'

export default (props) => {
  const [selectedMenu, setSelectedMenu] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])
  const [saving, setSaving] = useState(false)

  const addCategoriesToMove = (category) => {
    if (selectedCategories.includes(category.id)) {
      const newCategories = selectedCategories.filter(
        (product) => product !== category.id
      )
      setSelectedCategories(newCategories)
    } else {
      setSelectedCategories([...selectedCategories, category.id])
    }
  }

  const moveCategories = () => {
    setSaving(true)

    const selectedMenusCategories = dispatch.restaurant
      .getCategoryOrder(props.menuId)
      .filter((el) => !selectedCategories.includes(el))

    const newCategories = [
      ...dispatch.restaurant.getCategoryOrder(selectedMenu),
      ...selectedCategories,
    ]

    dispatch.dashboard.updateCategoryOrder({
      menuId: selectedMenu,
      categories: newCategories,
    })

    dispatch.dashboard.updateCategoryOrder({
      menuId: props.menuId,
      categories: selectedMenusCategories,
    })

    dispatch.notification.setMessage({
      message: `Categories copied!`,
      level: 'success',
    })
    setSaving(false)
    props.onRequestClose()
  }

  const toggleAccordion = (menuId) => {
    if (selectedMenu === menuId) {
      setSelectedMenu('')
    } else {
      setSelectedMenu(menuId)
    }
  }

  return (
    <>
      <DefaultScreen>
        <PrimaryModal w="700px" title="Move Categories" {...props}>
          <CFView column justifyCenter p="20px">
            {props.menuId !== 'default' && (
              <>
                <CircleSelectButton
                  label="Dinner"
                  selected={selectedMenu === 'default'}
                  onClick={() => {
                    toggleAccordion('default')
                  }}
                />
              </>
            )}
            {props.menuId !== 'menu1' && (
              <CircleSelectButton
                label="Lunch"
                selected={selectedMenu === 'menu1'}
                onClick={() => {
                  toggleAccordion('menu1')
                }}
              />
            )}
            {props.menuId !== 'menu2' && (
              <CircleSelectButton
                label="Happy Hours"
                selected={selectedMenu === 'menu2'}
                onClick={() => {
                  toggleAccordion('menu2')
                }}
              />
            )}
            <CFSelect
              selector={[
                () => dispatch.restaurant.getCategoryOrder(props.menuId),
                dispatch.restaurant.getCategories,
              ]}
            >
              {([categoryOrder, categories]) =>
                Object.values(categories).length > 0 ? (
                  <CFView pt="20px" pb="20px" column>
                    {categoryOrder.map((categoryId, index) => (
                      <CheckSelectButton
                        key={index + categoryId}
                        label={categories[categoryId]?.name}
                        selected={selectedCategories.includes(categoryId)}
                        onClick={() =>
                          addCategoriesToMove(categories[categoryId])
                        }
                      />
                    ))}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center color="black">
                    No Categories
                  </CFView>
                )
              }
            </CFSelect>
            <CFView p="20px">
              <PrimaryButton
                label={saving ? 'SAVING...' : 'SAVE'}
                disabled={
                  saving || !selectedCategories.length || !selectedMenu.length
                }
                onClick={moveCategories}
              />
            </CFView>
          </CFView>
        </PrimaryModal>
      </DefaultScreen>
      <MobileScreen>
        <PrimaryModal w="300px" title="Move Categories" {...props}>
          <CFView column justifyCenter p="20px">
            {props.menuId !== 'default' && (
              <>
                <CircleSelectButton
                  label="Dinner"
                  selected={selectedMenu === 'default'}
                  onClick={() => {
                    toggleAccordion('default')
                  }}
                />
                {selectedMenu === 'default' && (
                  <CFSelect
                    selector={[
                      () => dispatch.restaurant.getCategoryOrder(selectedMenu),
                      dispatch.restaurant.getCategories,
                    ]}
                  >
                    {([categoryOrder, categories]) =>
                      Object.values(categories).length > 0 ? (
                        <CFView pt="20px" pb="20px" column>
                          {categoryOrder.map((categoryId, index) => (
                            <CheckSelectButton
                              key={index + categoryId}
                              label={categories[categoryId]?.name}
                              selected={selectedCategories.includes(categoryId)}
                              onClick={() =>
                                addCategoriesToMove(categories[categoryId])
                              }
                            />
                          ))}
                        </CFView>
                      ) : (
                        <CFView h2 bold fill center>
                          No Categories
                        </CFView>
                      )
                    }
                  </CFSelect>
                )}
              </>
            )}
            {props.menuId !== 'menu1' && (
              <>
                <CircleSelectButton
                  label="Lunch"
                  selected={selectedMenu === 'menu1'}
                  onClick={() => {
                    toggleAccordion('menu1')
                  }}
                />
                {selectedMenu === 'menu1' && (
                  <CFSelect
                    selector={[
                      () => dispatch.restaurant.getCategoryOrder(selectedMenu),
                      dispatch.restaurant.getCategories,
                    ]}
                  >
                    {([categoryOrder, categories]) =>
                      Object.values(categories).length > 0 ? (
                        <CFView pt="20px" pb="20px" column>
                          {categoryOrder.map((categoryId, index) => (
                            <CheckSelectButton
                              key={index + categoryId}
                              label={categories[categoryId]?.name}
                              selected={selectedCategories.includes(categoryId)}
                              onClick={() =>
                                addCategoriesToMove(categories[categoryId])
                              }
                            />
                          ))}
                        </CFView>
                      ) : (
                        <CFView h2 bold fill center>
                          No Categories
                        </CFView>
                      )
                    }
                  </CFSelect>
                )}
              </>
            )}
            {props.menuId !== 'menu2' && (
              <>
                <CircleSelectButton
                  label="Happy Hours"
                  selected={selectedMenu === 'menu2'}
                  onClick={() => {
                    toggleAccordion('menu2')
                  }}
                />
                {selectedMenu === 'menu2' && (
                  <CFSelect
                    selector={[
                      () => dispatch.restaurant.getCategoryOrder(selectedMenu),
                      dispatch.restaurant.getCategories,
                    ]}
                  >
                    {([categoryOrder, categories]) =>
                      Object.values(categories).length > 0 ? (
                        <CFView pt="20px" pb="20px" column>
                          {categoryOrder.map((categoryId, index) => (
                            <CheckSelectButton
                              key={index + categoryId}
                              label={categories[categoryId]?.name}
                              selected={selectedCategories.includes(categoryId)}
                              onClick={() =>
                                addCategoriesToMove(categories[categoryId])
                              }
                            />
                          ))}
                        </CFView>
                      ) : (
                        <CFView h2 bold fill center>
                          No Categories
                        </CFView>
                      )
                    }
                  </CFSelect>
                )}
              </>
            )}
            <CFView p="20px">
              <PrimaryButton
                label={saving ? 'SAVING...' : 'SAVE'}
                disabled={saving || selectedCategories.length === 0}
                onClick={moveCategories}
              />
            </CFView>
          </CFView>
        </PrimaryModal>
      </MobileScreen>
    </>
  )
}
