import React from 'react'
import { CFView, CFText } from 'components'
import { sortBy, get, isEmpty } from 'lodash'

export default (props) => {
  const productsWithSubTotals = {}
  for (const order of props.orders) {
    const products = isEmpty(order.products)
      ? order.choicesProducts
      : Object.values(order.products)
    for (const product of products) {
      productsWithSubTotals[product.id] = {
        name: product.name,
        id: product.id,
        price: product.price,
        count:
          get(productsWithSubTotals[product.id], 'count', 0) + product.count,
        categoryId: product.categoryId,
        subTotal:
          get(productsWithSubTotals[product.id], 'subTotal', 0) +
          product.price * product.count,
      }
    }
  }
  const sortedProductsBySubTotal = sortBy(
    productsWithSubTotals,
    'subTotal'
  ).reverse()
  return (
    <CFView h="84rem" w="100%">
      <CFView h={40} alignCenter justifyBetween row>
        <CFView w="30rem">
          <CFText bold heading dark h3>
            {'Product Name'}
          </CFText>
        </CFView>
        <CFText bold heading dark h3>
          {'SubTotal'}
        </CFText>
        <CFText bold heading dark h3>
          {'Count'}
        </CFText>
      </CFView>
      {sortedProductsBySubTotal.map((product, index) => {
        return (
          <CFView
            w="100%"
            p="2rem"
            justifyBetween
            alignCenter
            row
            bg={index % 2 === 0 ? 'grey' : 'white'}
          >
            <CFView w="26rem">
              <CFText dark h3>
                {product.name}
              </CFText>
            </CFView>
            <CFView>
              <CFText dark h3>
                {product.subTotal.toFixed(2)}
              </CFText>
            </CFView>
            <CFView>
              <CFText dark h3>
                {product.count}
              </CFText>
            </CFView>
          </CFView>
        )
      })}
    </CFView>
  )
}
