import React, { useState, useEffect } from 'react'
import {
  Card,
  CFSelect,
  CFView,
  PrimaryButton,
  PrimaryModal,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { Button, Pane, TrashIcon, InsertIcon } from 'evergreen-ui'
import { dispatch } from 'store'
import { filter, uniq } from 'lodash'

export default (props) => {
  const [restaurantIds, setRestaurantIds] = useState([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setRestaurantIds(props.user.restaurantIds)
  }, [props.user])

  const addRestaurantId = (restaurantId) => {
    setRestaurantIds([...restaurantIds, restaurantId])
  }

  const removeRestaurantId = (restaurantId) => {
    setRestaurantIds(filter(restaurantIds, (id) => id !== restaurantId))
  }

  const saveRestaurantIds = async () => {
    setSaving(true)
    try {
      const uniqueRestaurantIds = uniq(restaurantIds)
      await dispatch.dashboard.updateUser({
        userId: props.user.id,
        user: { restaurantIds: uniqueRestaurantIds },
      })
      dispatch.notification.setMessage({
        message: `User saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setRestaurantIds([])
    } catch (e) {
      alert('Error saving user.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Manage Restaurants" {...props}>
      <DefaultScreen>
        <CFView column p="20px" w="800px">
          <CFSelect selector={dispatch.dashboard.getRestaurants}>
            {(restaurants) => (
              <CFView row>
                <CFView fill column mr="20px">
                  {restaurantIds.map((restaurantId) =>
                    restaurants[restaurantId] ? (
                      <Pane
                        display="flex"
                        padding={10}
                        marginBottom={15}
                        background="tint2"
                        borderRadius={3}
                        key={restaurantId}
                      >
                        <Pane flex={1} alignItems="center" display="flex">
                          <CFView>{restaurants[restaurantId].name}</CFView>
                        </Pane>
                        <Button
                          iconBefore={TrashIcon}
                          intent="danger"
                          label={
                            saving === restaurantId ? 'Removing' : 'Remove'
                          }
                          disabled={saving === restaurantId}
                          onClick={() => removeRestaurantId(restaurantId)}
                        >
                          Delete...
                        </Button>
                      </Pane>
                    ) : null
                  )}
                </CFView>
                <CFView fill column>
                  {Object.values(restaurants).map((restaurant) => (
                    <Pane
                      display="flex"
                      padding={10}
                      marginBottom={15}
                      background="tint2"
                      borderRadius={3}
                      key={restaurant.id}
                    >
                      <Pane flex={1} alignItems="center" display="flex">
                        <CFView>{restaurant.name}</CFView>
                      </Pane>
                      <Button
                        iconBefore={InsertIcon}
                        color="warning"
                        label={saving === restaurant.id ? 'Adding' : 'Add'}
                        disabled={saving === restaurant.id}
                        onClick={() => addRestaurantId(restaurant.id)}
                      >
                        Insert Restaurant
                      </Button>
                    </Pane>
                  ))}
                </CFView>
              </CFView>
            )}
          </CFSelect>
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={saveRestaurantIds}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView column p="10px" w="100%" minWidth="320px">
          <CFSelect selector={dispatch.dashboard.getRestaurants}>
            {(restaurants) => (
              <CFView column mb="20px">
                <Card fill column p="5px" mb="20">
                  <CFView column center xBold color="#00C0FF" mv="15px">
                    EXISTING RESTAURANTS
                  </CFView>
                  {restaurantIds.map((restaurantId) =>
                    restaurants[restaurantId] ? (
                      <Pane
                        display="flex"
                        padding={10}
                        marginBottom={15}
                        background="tint2"
                        borderRadius={3}
                        key={restaurantId}
                      >
                        <Pane flex={1} alignItems="center" display="flex">
                          <CFView>{restaurants[restaurantId].name}</CFView>
                        </Pane>
                        <Button
                          iconBefore={TrashIcon}
                          intent="danger"
                          label={
                            saving === restaurantId ? 'Removing' : 'Remove'
                          }
                          disabled={saving === restaurantId}
                          onClick={() => removeRestaurantId(restaurantId)}
                        >
                          Delete...
                        </Button>
                      </Pane>
                    ) : null
                  )}
                </Card>
                <Card fill column p="5px">
                  <CFView column center xBold color="#00C0FF" mv="15px">
                    ADD RESTAURANTS
                  </CFView>
                  {Object.values(restaurants).map((restaurant) => (
                    <Pane
                      display="flex"
                      padding={10}
                      marginBottom={15}
                      background="tint2"
                      borderRadius={3}
                      key={restaurant.id}
                    >
                      <Pane flex={1} alignItems="center" display="flex">
                        <CFView>{restaurant.name}</CFView>
                      </Pane>
                      <Button
                        iconBefore={InsertIcon}
                        color="warning"
                        label={saving === restaurant.id ? 'Adding' : 'Add'}
                        disabled={saving === restaurant.id}
                        onClick={() => addRestaurantId(restaurant.id)}
                      >
                        Insert Restaurant
                      </Button>
                    </Pane>
                  ))}
                </Card>
              </CFView>
            )}
          </CFSelect>
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={saveRestaurantIds}
          />
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
