import React, { useState } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import * as utils from 'cf-utils'

const initialState = {
  refundDescription: '',
  refundAmount: '',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  const refundOrder = async () => {
    const { refundDescription, refundAmount } = fields
    const { id: orderId, total, totalRefundAmount = 0 } = props.order
    if (isNaN(Number(refundAmount))) {
      alert('Refund amount must be a number.')
      return
    }
    if (Number(refundAmount) <= 0) {
      alert('Refund amount must be greater than 0.')
      return
    }
    if (
      utils.parseCurrency(refundAmount) >
      utils.parseCurrency(total - totalRefundAmount)
    ) {
      alert('Refund must be equal or less than order total.')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.refundOrder({
        orderId,
        refundDescription,
        refundAmount: Number(refundAmount),
      })
      dispatch.notification.setMessage({
        message: `Refund successful!`,
        level: 'success',
      })
      props.onRequestClose()
      props.refreshData()
      setFields(initialState)
    } catch (e) {
      alert('Error refunding order.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Refund Order" {...props}>
      <CFView column>
        <CFView p="20px" bg="white" row>
          <CFView column>
            <LabelInput
              label="Description"
              value={fields.refundDescription}
              onChange={(e) =>
                setFields({ ...fields, refundDescription: e.target.value })
              }
            />
            <LabelInput
              mt="10px"
              label="Amount"
              value={fields.refundAmount}
              onChange={(e) =>
                setFields({ ...fields, refundAmount: e.target.value })
              }
            />
          </CFView>
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={refundOrder}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
