import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import * as pages from 'pages'
import { firebaseAuth } from 'cf-core/src/config/firebase'
import {
  Background,
  CFView,
  Navbar,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { fetchUser } from 'api'
import { cfvideo } from 'images'
import { dispatch } from 'store'

const VALID_ADMINS = ['super_admin', 'int_admin', 'menu_admin', 'admin']

function PrivateRoute({
  component: Component,
  authed,
  path,
  validRoles = [],
  ...rest
}) {
  const role = dispatch.dashboard.getRole()
  let isValid = VALID_ADMINS.includes(role)
  if (validRoles.length > 0) {
    isValid = validRoles.includes(role)
  }
  return (
    <Route
      path={path}
      {...rest}
      render={(props) =>
        authed === true && isValid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/auth', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

function PublicRoute({ component: Component, authed, ...rest }) {
  const role = dispatch.dashboard.getRole()
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : role === 'admin' ? (
          <Redirect to="/orders" />
        ) : (
          <Redirect to="/restaurants" />
        )
      }
    />
  )
}

export default class App extends Component {
  state = {
    authed: false,
    loading: true,
  }
  componentDidMount() {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        fetchUser(user.uid).then((snapshot) => {
          const {
            role,
            restaurantIds = [],
            restaurantId,
            locationId,
          } = snapshot.data()
          if (VALID_ADMINS.includes(role)) {
            dispatch.dashboard.setRole(role)
            if (role === 'int_admin') {
              dispatch.dashboard.setRestaurantIds(restaurantIds)
            }
            if (role === 'admin') {
              dispatch.restaurant.setRestaurantId(restaurantId)
              dispatch.restaurant.setLocationId(locationId)
            }
            this.setState({
              authed: true,
              loading: false,
            })
          } else {
            alert('You do not have permission.')
            dispatch.dashboard.signOut()
            this.setState({
              authed: false,
              loading: false,
            })
          }
        })
      } else {
        this.setState({
          authed: false,
          loading: false,
        })
      }
    })
  }

  render() {
    return this.state.loading === true ? (
      <CFView row h="100vh" w="100vw" justifyCenter alignCenter>
        <video
          autoPlay
          muted
          loop
          style={{ height: '20rem', objectFit: 'contain' }}
        >
          <source src={cfvideo} />
        </video>
      </CFView>
    ) : (
      <Router>
        <Background>
          <DefaultScreen>
            <Navbar authed={this.state.authed} />
          </DefaultScreen>
          <Switch>
            <PublicRoute
              path="/"
              component={pages.Home}
              authed={this.state.authed}
              exact
            />
            <PublicRoute
              path="/auth"
              component={pages.Auth}
              authed={this.state.authed}
            />
            <PrivateRoute
              path="/users"
              component={pages.Users}
              authed={this.state.authed}
              validRoles={['super_admin']}
              exact
            />
            <PrivateRoute
              path="/staff"
              component={pages.Staff}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants"
              component={pages.Restaurants}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/orders"
              component={pages.Orders}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/analytics"
              component={pages.Analytics}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/menus"
              component={pages.Menus}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/menus/rewards"
              component={pages.Rewards}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/promoCodes"
              component={pages.PromoCodes}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/settings"
              component={pages.Settings}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/statistics"
              component={pages.Stats}
              authed={this.state.authed}
              validRoles={['super_admin']}
              exact
            />
            <PrivateRoute
              path="/menus/:menuId/categories"
              component={pages.Categories}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/menus/:menuId/categories/:categoryId/products"
              component={pages.Products}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/menus/:menuId/categories/:categoryId/products/modifiers"
              component={pages.Modifiers}
              authed={this.state.authed}
              validRoles={['admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations"
              component={pages.Locations}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/orders"
              component={pages.Orders}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/rewards"
              component={pages.Rewards}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/promoCodes"
              component={pages.PromoCodes}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/analytics"
              component={pages.Analytics}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/staff"
              component={pages.Staff}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/tables"
              component={pages.Tables}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/menus"
              component={pages.Menus}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/menus/:menuId/categories"
              component={pages.Categories}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/menus/:menuId/categories/:categoryId/products"
              component={pages.Products}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <PrivateRoute
              path="/restaurants/:restaurantId/locations/:locationId/menus/:menuId/categories/:categoryId/products/modifiers"
              component={pages.Modifiers}
              authed={this.state.authed}
              validRoles={['super_admin', 'int_admin', 'menu_admin']}
              exact
            />
            <Route component={pages.Page404} />
          </Switch>
          <MobileScreen>
            <Navbar authed={this.state.authed} />
          </MobileScreen>
        </Background>
      </Router>
    )
  }
}
