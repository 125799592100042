import React from 'react'
import { Card, CFView, CFText, DefaultScreen, MobileScreen } from 'components'
import { sortBy } from 'lodash'
import moment from 'moment'

export default (props) => {
  const customers = Object.values(props.orders).reduce((acc, order) => {
    const { userId } = order
    if (props.users[userId] && order.status !== 'Cancelled') {
      if (!acc[userId]) {
        acc[userId] = {}
        acc[userId].id = userId
        acc[userId].name = props.users[userId].name
        acc[userId].email = props.users[userId].email
        acc[userId].phoneNumber = props.users[userId].phoneNumber
        acc[userId].address = props.users[userId].address
        acc[userId].lastLogin = props.users[userId].lastLogin
          ? props.users[userId].lastLogin
          : props.users[userId].createdAt
      }
      acc[userId].revenue = (acc[userId].revenue || 0) + order.total
      acc[userId].discount = (acc[userId].discount || 0) + order.discount
      acc[userId].orders = (acc[userId].orders || 0) + 1
      acc[userId].pointsEarned =
        (acc[userId].pointsEarned || 0) + order.pointsEarned
      acc[userId].pointsUsed = (acc[userId].pointsUsed || 0) + order.pointsUsed
      acc[userId].rewards =
        (acc[userId].rewards || 0) + Object.keys(order.rewards).length
      acc[userId].mobileCount =
        (acc[userId].mobileCount || 0) +
        (order.sourceClient === 'mobile' ? 1 : 0)
      acc[userId].webCount =
        (acc[userId].webCount || 0) +
        (!order.sourceClient || order.sourceClient === 'web' ? 1 : 0)
      acc[userId].pickupCount =
        (acc[userId].pickupCount || 0) +
        (!order.orderType || order.orderType === 'Pickup' ? 1 : 0)
      acc[userId].deliveryCount =
        (acc[userId].deliveryCount || 0) +
        (order.orderType === 'Delivery' ? 1 : 0)
      acc[userId].dineInCount =
        (acc[userId].dineInCount || 0) + (order.orderType === 'Dine-in' ? 1 : 0)
    }
    return acc
  }, {})
  return (
    <>
      <DefaultScreen>
        <CFView h="84rem" w="100%">
          {sortBy(Object.values(customers), ['revenue', 'orders'])
            .reverse()
            .map((user) => {
              return (
                <Card key={user.id} w="100%" mb="2rem" p="2rem" row>
                  <CFView column fill>
                    <CFText h5>Name: {user.name}</CFText>
                    <CFText h5>Phone Number: {user.phoneNumber}</CFText>
                    {user.address && (
                      <CFText h5>Address: {user.address}</CFText>
                    )}
                    <CFText h5>Mobile: {user.mobileCount}</CFText>
                    <CFText h5>Web: {user.webCount}</CFText>
                    <CFText h5>Pickup: {user.pickupCount}</CFText>
                    <CFText h5>Delivery: {user.deliveryCount}</CFText>
                    <CFText h5>Dine-in: {user.dineInCount}</CFText>
                  </CFView>
                  <CFView column fill>
                    <CFText h5>Revenue: ${user.revenue.toFixed(2)}</CFText>
                    <CFText h5>Orders: {user.orders}</CFText>
                    <CFText h5>Discount: ${user.discount.toFixed(2)}</CFText>
                    <CFText h5>Rewards: {user.rewards}</CFText>
                    <CFText h5>Points Earned: {user.pointsEarned}</CFText>
                    <CFText h5>Points Used: {user.pointsUsed}</CFText>
                    <CFText h5>
                      Current Points: {user.pointsEarned - user.pointsUsed}
                    </CFText>
                    <CFText h5>
                      Last Login:{' '}
                      {`${moment(user.lastLogin).format('LT')}, ${moment(
                        user.lastLogin
                      ).format('DD/MM/YY')}`}
                    </CFText>
                  </CFView>
                </Card>
              )
            })}
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView h="84rem" w="100%">
          {sortBy(Object.values(customers), ['revenue', 'orders'])
            .reverse()
            .map((user) => {
              return (
                <Card key={user.id} w="100%" mb="2rem" p="2rem" column>
                  <CFView column fill>
                    <CFText h5>Name: {user.name}</CFText>
                    <CFText h5>Phone Number: {user.phoneNumber}</CFText>
                    {user.address && (
                      <CFText h5>Address: {user.address}</CFText>
                    )}
                    <CFText h5>Mobile: {user.mobileCount}</CFText>
                    <CFText h5>Web: {user.webCount}</CFText>
                    <CFText h5>Pickup: {user.pickupCount}</CFText>
                    <CFText h5>Delivery: {user.deliveryCount}</CFText>
                  </CFView>
                  <CFView column fill>
                    <CFText h5>Revenue: ${user.revenue.toFixed(2)}</CFText>
                    <CFText h5>Orders: {user.orders}</CFText>
                    <CFText h5>Discount: ${user.discount.toFixed(2)}</CFText>
                    <CFText h5>Rewards: {user.rewards}</CFText>
                    <CFText h5>Points Earned: {user.pointsEarned}</CFText>
                    <CFText h5>Points Used: {user.pointsUsed}</CFText>
                    <CFText h5>
                      Current Points: {user.pointsEarned - user.pointsUsed}
                    </CFText>
                    <CFText h5>
                      Last Login:{' '}
                      {`${moment(user.lastLogin).format('LT')}, ${moment(
                        user.lastLogin
                      ).format('DD/MM/YY')}`}
                    </CFText>
                  </CFView>
                </Card>
              )
            })}
        </CFView>
      </MobileScreen>
    </>
  )
}
