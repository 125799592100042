import React, { useState, useEffect } from 'react'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { Checkbox, Select, TextInputField } from 'evergreen-ui'
import { dispatch } from 'store'
import { omit, isEmpty } from 'lodash'

const initialState = {
  active: true,
  code: '',
  discount: 0,
  frequency: 'none',
  maxDiscountAmount: 1000,
  validDays: {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  },
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.promoCode) {
      setFields({
        ...initialState,
        ...props.promoCode,
      })
    }
  }, [props.promoCode])

  const updatePromoCode = async () => {
    if (!isFormValid()) {
      return
    }
    setSaving(true)
    try {
      const { code, discount, maxDiscountAmount } = fields
      let promoCodes = dispatch.restaurant.getPromoCodes()
      if (!isEmpty(props.promoCode)) {
        if (props.promoCode.code !== code) {
          promoCodes = omit(promoCodes, props.promoCode.code)
        }
        promoCodes[code] = {
          ...fields,
          code: code.toUpperCase(),
          discount: Number(discount),
          maxDiscountAmount: Number(maxDiscountAmount),
        }
      } else {
        promoCodes = {
          ...dispatch.restaurant.getPromoCodes(),
          [code]: {
            ...fields,
            code: code.toUpperCase(),
            discount: Number(discount),
            maxDiscountAmount: Number(maxDiscountAmount),
          },
        }
      }
      await dispatch.dashboard.updatePromoCodes({ promoCodes })
      dispatch.notification.setMessage({
        message: `Promo code saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error saving promo code.')
    } finally {
      setSaving(false)
    }
  }

  const isFormValid = () => {
    const { discount, maxDiscountAmount } = fields
    if (isNaN(Number(discount))) {
      alert('Discount must be a number.')
      return false
    }
    if (discount === '' || discount <= 0 || discount > 1) {
      alert('Discount must be between 0 and 1.')
      return false
    }
    if (isNaN(Number(maxDiscountAmount))) {
      alert('Max discount must be a number.')
      return false
    }
    if (discount === '' || discount <= 0) {
      alert('Discount must be a valid number.')
      return false
    }
    return true
  }

  return (
    <PrimaryModal
      title={
        !isEmpty(props.promoCode)
          ? `Edit ${props.promoCode.code}`
          : 'Create Promo Code'
      }
      {...props}
    >
      <CFView column p="20px">
        <TextInputField
          placeholder="Code"
          label="Code"
          width="100%"
          marginBottom={15}
          value={fields.code}
          onChange={(e) => setFields({ ...fields, code: e.target.value })}
        />
        <TextInputField
          placeholder="Discount"
          label="Discount"
          width="100%"
          marginBottom={15}
          value={fields.discount}
          onChange={(e) => setFields({ ...fields, discount: e.target.value })}
        />
        <TextInputField
          placeholder="Max Discount"
          label="Max Discount"
          width="100%"
          marginBottom={15}
          value={fields.maxDiscountAmount}
          onChange={(e) =>
            setFields({ ...fields, maxDiscountAmount: e.target.value })
          }
        />
        <CFView h6 mb="5px">
          Frequency
        </CFView>
        <CFView>
          <Select
            label="Frequency"
            marginBottom={15}
            value={fields.frequency}
            onChange={(e) =>
              setFields({ ...fields, frequency: e.target.value })
            }
          >
            {['none', 'day', 'week', 'month', 'year'].map((frequency) => (
              <option value={frequency}>{frequency}</option>
            ))}
          </Select>
        </CFView>
        <CFView h6 mb="5px">
          Valid Days
        </CFView>
        <CFView row>
          <Checkbox
            label="Sunday"
            marginRight={10}
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 0: e.target.checked },
              })
            }
            checked={fields.validDays[0]}
          />
          <Checkbox
            label="Monday"
            marginRight={10}
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 1: e.target.checked },
              })
            }
            checked={fields.validDays[1]}
          />
          <Checkbox
            label="Tuesday"
            marginRight={10}
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 2: e.target.checked },
              })
            }
            checked={fields.validDays[2]}
          />
          <Checkbox
            label="Wednesday"
            marginRight={10}
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 3: e.target.checked },
              })
            }
            checked={fields.validDays[3]}
          />
          <Checkbox
            label="Thursday"
            marginRight={10}
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 4: e.target.checked },
              })
            }
            checked={fields.validDays[4]}
          />
          <Checkbox
            label="Friday"
            marginRight={10}
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 5: e.target.checked },
              })
            }
            checked={fields.validDays[5]}
          />
          <Checkbox
            label="Saturday"
            onChange={(e) =>
              setFields({
                ...fields,
                validDays: { ...fields.validDays, 6: e.target.checked },
              })
            }
            checked={fields.validDays[6]}
          />
        </CFView>
        <Checkbox
          label="Active"
          marginTop={10}
          onChange={(e) =>
            setFields({
              ...fields,
              active: e.target.checked,
            })
          }
          checked={fields.active}
        />
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updatePromoCode}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
