import React, { useState, useEffect } from 'react'
import {
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import { Button, Pane, TrashIcon, InsertIcon } from 'evergreen-ui'
import { dispatch } from 'store'
import { filter } from 'lodash'

export default (props) => {
  const [productModifiers, setProductModifiers] = useState([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setProductModifiers(props.product.modifiers || [])
  }, [props.product])

  const addModifierToProduct = (modifierId) => {
    setProductModifiers([...productModifiers, modifierId])
  }

  const removeModifierFromProduct = (modifierId) => {
    setProductModifiers(filter(productModifiers, (id) => id !== modifierId))
  }

  const saveModifiers = async () => {
    setSaving(true)
    try {
      await dispatch.dashboard.updateProduct({
        productId: props.product.id,
        productDetails: { modifiers: productModifiers },
      })
      dispatch.notification.setMessage({
        message: `Modifiers saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setProductModifiers([])
    } catch (e) {
      alert('Error saving modifiers.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <DefaultScreen>
        <PrimaryModal title="Manage Modifiers" {...props}>
          <CFView column p="20px" w="700px">
            <CFSelect selector={dispatch.restaurant.getModifiers}>
              {(modifiers) => (
                <CFView row>
                  <CFView fill column mr="20px">
                    {productModifiers.map((modifierId) =>
                      modifiers[modifierId] ? (
                        <Pane
                          display="flex"
                          padding={10}
                          marginBottom={15}
                          background="tint2"
                          borderRadius={3}
                          key={modifierId}
                        >
                          <Pane flex={1} alignItems="center" display="flex">
                            <CFView>{modifiers[modifierId].name}</CFView>
                          </Pane>
                          <Button
                            iconBefore={TrashIcon}
                            intent="danger"
                            label={
                              saving === modifierId ? 'Removing' : 'Remove'
                            }
                            disabled={saving === modifierId}
                            onClick={() =>
                              removeModifierFromProduct(modifierId)
                            }
                          >
                            Delete
                          </Button>
                        </Pane>
                      ) : null
                    )}
                  </CFView>
                  <CFView fill column>
                    {Object.values(modifiers).map((modifier) => (
                      <Pane
                        display="flex"
                        padding={10}
                        marginBottom={15}
                        background="tint2"
                        borderRadius={3}
                        key={modifier.id}
                      >
                        <Pane flex={1} alignItems="center" display="flex">
                          <CFView>{modifier.name}</CFView>
                        </Pane>
                        <Button
                          iconBefore={InsertIcon}
                          color="warning"
                          label={saving === modifier.id ? 'Adding' : 'Add'}
                          disabled={saving === modifier.id}
                          onClick={() => addModifierToProduct(modifier.id)}
                        >
                          Insert Modifier
                        </Button>
                      </Pane>
                    ))}
                  </CFView>
                </CFView>
              )}
            </CFSelect>
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={saveModifiers}
            />
          </CFView>
        </PrimaryModal>
      </DefaultScreen>
      <MobileScreen>
        <PrimaryModal title="Manage Modifiers" {...props}>
          <CFView column p="20px" w="100vw">
            <CFSelect selector={dispatch.restaurant.getModifiers}>
              {(modifiers) => (
                <CFView column center>
                  <CFView column center mb="20px">
                    <CFView textCenter xBold h3 mb="5px">
                      EXISTING MODIFIERS
                    </CFView>
                    {productModifiers.map((modifierId) =>
                      modifiers[modifierId] ? (
                        <Pane
                          display="flex"
                          padding={10}
                          marginBottom={15}
                          background="tint2"
                          borderRadius={3}
                          key={modifierId}
                        >
                          <Pane flex={1} alignItems="center" display="flex">
                            <CFView>{modifiers[modifierId].name}</CFView>
                          </Pane>
                          <Button
                            iconBefore={TrashIcon}
                            intent="danger"
                            marginLeft={15}
                            label={
                              saving === modifierId ? 'Removing' : 'Remove'
                            }
                            disabled={saving === modifierId}
                            onClick={() =>
                              removeModifierFromProduct(modifierId)
                            }
                          >
                            Delete
                          </Button>
                        </Pane>
                      ) : null
                    )}
                  </CFView>
                  <CFView fill column>
                    <CFView textCenter xBold h3 mb="5px">
                      OTHER MODIFIERS
                    </CFView>
                    {Object.values(modifiers).map((modifier) => (
                      <Pane
                        display="flex"
                        padding={10}
                        marginBottom={15}
                        background="tint2"
                        borderRadius={3}
                        key={modifier.id}
                      >
                        <Pane flex={1} alignItems="center" display="flex">
                          <CFView>{modifier.name}</CFView>
                        </Pane>
                        <Button
                          iconBefore={InsertIcon}
                          color="warning"
                          label={saving === modifier.id ? 'Adding' : 'Add'}
                          disabled={saving === modifier.id}
                          onClick={() => addModifierToProduct(modifier.id)}
                        >
                          Insert Modifier
                        </Button>
                      </Pane>
                    ))}
                  </CFView>
                </CFView>
              )}
            </CFSelect>
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={saveModifiers}
            />
          </CFView>
        </PrimaryModal>
      </MobileScreen>
    </>
  )
}
