import React, { useState, useEffect } from 'react'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { Select } from 'evergreen-ui'
import { dispatch } from 'store'

export default (props) => {
  const [onlineStatus, setOnlineStatus] = useState(true)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setOnlineStatus(props.onlineStatus)
  }, [props.onlineStatus])

  const updateOnlineStatus = async () => {
    setSaving(true)
    try {
      await dispatch.dashboard.updateOrderOpen(onlineStatus === 'true')
      dispatch.notification.setMessage({
        message: 'Online Status Updated!',
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error updating online Status.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Update Online Status" {...props}>
      <CFView column p="20px" w="300px">
        <CFView fill>
          <Select
            width={260}
            label="Online Status"
            value={onlineStatus}
            onChange={(e) => setOnlineStatus(e.target.value)}
          >
            <option value={true}>Online</option>
            <option value={false}>Offline</option>
          </Select>
        </CFView>
        <PrimaryButton
          mt="20px"
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateOnlineStatus}
        />
      </CFView>
    </PrimaryModal>
  )
}
