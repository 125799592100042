import axios from 'axios'

export function registerUser(serverUrl, authToken, user) {
  const config = {
    url: `${serverUrl}/api/create_user`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: user,
  }
  return axios(config)
}

export function unregisterUser(serverUrl, authToken, userId) {
  const config = {
    url: `${serverUrl}/api/delete_user`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: { userId },
  }
  return axios(config)
}

export function addChargeToOrder(serverUrl, authToken, addedCharge) {
  const config = {
    url: `${serverUrl}/api/add_charge`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: addedCharge,
  }
  return axios(config)
}

export function addChargeToOrderV2(serverUrl, authToken, addedCharge) {
  const config = {
    url: `${serverUrl}/api/add_charge_v2`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: addedCharge,
  }
  return axios(config)
}

export function refundOrderAndCharge(serverUrl, authToken, refund) {
  const config = {
    url: `${serverUrl}/api/refund_order_v2`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: refund,
  }
  return axios(config)
}

export function createNotificationWithFCM(serverUrl, authToken, message) {
  const config = {
    url: `${serverUrl}/api/create_notification`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: message,
  }
  return axios(config)
}

export function createMessageWithTwilio(serverUrl, authToken, message) {
  const config = {
    url: `${serverUrl}/api/create_message`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: message,
  }
  return axios(config)
}

export function createEmailPromotion(serverUrl, authToken, data) {
  const config = {
    url: `${serverUrl}/api/create_promotion`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data,
  }
  return axios(config)
}

export function sendInvoiceToEmail(serverUrl, authToken, invoiceDetails) {
  const config = {
    url: `${serverUrl}/api/send_invoice`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authToken,
    },
    data: { invoiceDetails },
  }
  return axios(config)
}
