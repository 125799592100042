import React, { useState, useEffect } from 'react'
import { Card, CFSelect, CFView, DefaultScreen, MobileScreen } from 'components'
import { AddIcon, Button, EditIcon, RemoveIcon, TrashIcon } from 'evergreen-ui'
import RestaurantsModal from './RestaurantsModal'
import RemovePaymentModal from './RemovePaymentModal'
import UserModal from './UserModal'
import { dispatch } from 'store'

export default () => {
  const [user, setUser] = useState('')
  const [showModal, setShowModal] = useState('')
  const [saving, setSaving] = useState('')

  useEffect(() => {
    const unsubscribeUsers = dispatch.dashboard.subscribeUsers()
    const unsubscribeRestaurants = dispatch.dashboard.subscribeRestaurants()
    return () => {
      unsubscribeUsers()
      unsubscribeRestaurants()
    }
  }, [])

  const showUserModal = (user) => {
    setShowModal('user')
    setUser(user)
  }

  const showRestaurantsModal = (user) => {
    setShowModal('restaurants')
    setUser(user)
  }

  const closeModal = () => {
    setShowModal('')
    setUser('')
  }

  const deleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setSaving(userId)
      try {
        await dispatch.dashboard.unregisterUser(userId)
        dispatch.notification.setMessage({
          message: `User deleted!`,
          level: 'success',
        })
      } catch (e) {
        alert('Error deleting user.')
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <CFView h="100%" center>
      <DefaultScreen>
        <CFView column p="20px">
          <Card h="calc(100vh - 110px)" w="1080px" column overflowY="auto">
            <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
              <CFView h1 bold>
                Users
              </CFView>
              <CFView row>
                <Button
                  intent="danger"
                  iconBefore={RemoveIcon}
                  height={40}
                  onClick={() => setShowModal('payment')}
                >
                  Remove Payment
                </Button>
                <CFView w="10px" />
                <Button
                  intent="danger"
                  iconBefore={AddIcon}
                  height={40}
                  onClick={() => setShowModal('user')}
                >
                  Create User
                </Button>
              </CFView>
            </CFView>
            <CFSelect selector={dispatch.dashboard.getUsers}>
              {(users) =>
                Object.values(users).length > 0 ? (
                  <CFView p="20px" column>
                    {Object.values(users).map((user, index) => {
                      return (
                        <Card
                          p="10px"
                          mb="10px"
                          row
                          justifyBetween
                          key={user.id}
                        >
                          <CFView column>
                            <CFView>{user.email}</CFView>
                            <CFView>{`Role: ${user.role}`}</CFView>
                          </CFView>
                          <CFView row>
                            {user.role === 'int_admin' && (
                              <Button
                                appearance="primary"
                                iconBefore={AddIcon}
                                height={40}
                                marginRight={10}
                                onClick={() => showRestaurantsModal(user)}
                              >
                                Restaurants
                              </Button>
                            )}
                            <Button
                              appearance="primary"
                              intent="warning"
                              iconBefore={EditIcon}
                              height={40}
                              marginRight={10}
                              onClick={() => showUserModal(user)}
                            >
                              Edit User
                            </Button>
                            <Button
                              appearance="primary"
                              intent="danger"
                              iconBefore={TrashIcon}
                              height={40}
                              marginRight={10}
                              disabled={saving === user.id}
                              onClick={() => deleteUser(user.id)}
                            >
                              {saving === user.id ? 'Deleting' : 'Delete User'}
                            </Button>
                          </CFView>
                        </Card>
                      )
                    })}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Users
                  </CFView>
                )
              }
            </CFSelect>
          </Card>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView h="100vh" w="100%" column overflowY="auto">
          <CFView column center mv="15px">
            <Button
              intent="danger"
              iconBefore={AddIcon}
              height={48}
              onClick={() => setShowModal('user')}
            >
              Create User
            </Button>
          </CFView>
          <CFSelect selector={dispatch.dashboard.getUsers}>
            {(users) =>
              Object.values(users).length > 0 ? (
                <CFView ph="20px" column c>
                  {Object.values(users).map((user, index) => {
                    return (
                      <Card
                        p="10px"
                        mb="10px"
                        column
                        alignCenter
                        justifyBetween
                        key={user.id}
                      >
                        <CFView column textCenter>
                          <CFView>{`Email: ${user.email}`}</CFView>
                          <CFView>{`Role: ${user.role}`}</CFView>
                        </CFView>
                        <CFView column m="5px">
                          {user.role === 'int_admin' && (
                            <Button
                              appearance="primary"
                              iconBefore={AddIcon}
                              height={48}
                              marginBottom={5}
                              onClick={() => showRestaurantsModal(user)}
                            >
                              Restaurants
                            </Button>
                          )}
                          <Button
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={48}
                            marginBottom={5}
                            onClick={() => showUserModal(user)}
                          >
                            Edit User
                          </Button>
                          <CFView w="10px" />
                          <Button
                            appearance="primary"
                            intent="danger"
                            iconBefore={TrashIcon}
                            height={48}
                            marginBottom={5}
                            disabled={saving === user.id}
                            onClick={() => deleteUser(user.id)}
                          >
                            {saving === user.id ? 'Deleting' : 'Delete User'}
                          </Button>
                        </CFView>
                      </Card>
                    )
                  })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Users
                </CFView>
              )
            }
          </CFSelect>
        </CFView>
      </MobileScreen>
      <UserModal
        isOpen={showModal === 'user'}
        user={user}
        onRequestClose={closeModal}
      />
      <RestaurantsModal
        isOpen={showModal === 'restaurants'}
        user={user}
        onRequestClose={closeModal}
      />
      <RemovePaymentModal
        isOpen={showModal === 'payment'}
        onRequestClose={closeModal}
      />
    </CFView>
  )
}
