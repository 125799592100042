import React, { useState, useEffect } from 'react'
import {
  CFView,
  PrimaryButton,
  PrimaryModal,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  IconButton,
  Button,
  Checkbox,
  TextInputField,
  TrashIcon,
} from 'evergreen-ui'
import { TimePicker } from '@material-ui/pickers'
import { dispatch } from 'store'

const initialState = {
  name: '',
  active: true,
  hours: {
    0: {
      open: 0,
      close: 0,
    },
    1: {
      open: 0,
      close: 0,
    },
    2: {
      open: 0,
      close: 0,
    },
    3: {
      open: 0,
      close: 0,
    },
    4: {
      open: 0,
      close: 0,
    },
    5: {
      open: 0,
      close: 0,
    },
    6: {
      open: 0,
      close: 0,
    },
  },
}

const DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.menu) {
      setFields({ ...initialState, ...props.menu })
    } else {
      setFields(initialState)
    }
  }, [props.menu])

  const updateMenu = async () => {
    if (fields.name === '') {
      alert('Name cannot be empty')
      return false
    }
    setSaving(true)
    try {
      const menus = dispatch.restaurant.getMenus()
      if (props.menu) {
        menus[props.menu.id] = { ...menus[props.menu.id], ...fields }
      } else {
        const menuId = 'menu' + Object.keys(menus).length
        menus[menuId] = fields
      }
      await dispatch.dashboard.updateMenus(menus)
      dispatch.notification.setMessage({
        message: `Menu saved!`,
        level: 'success',
      })
    } catch (e) {
      alert('Error saving menu.')
    } finally {
      props.onRequestClose()
      setFields(initialState)
      setSaving(false)
    }
  }

  const changeHours = async (time, day, event) => {
    fields.hours[day][event] = time.valueOf()
    setFields({ ...fields })
  }

  const clearHours = (day) => {
    fields.hours[day] = { open: null, close: null }
    setFields({ ...fields })
  }

  return (
    <PrimaryModal
      title={props.menu ? `Edit ${props.menu.name}` : 'Create Menu'}
      {...props}
    >
      <DefaultScreen>
        <CFView minWidth="600px" p="20px">
          <CFView bg="white" row center>
            <TextInputField
              marginRight={30}
              label="Menu Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <Checkbox
              label="Active"
              onChange={(e) =>
                setFields({ ...fields, active: e.target.checked })
              }
              checked={fields.active}
            />
          </CFView>
          <CFView column center>
            <CFView column>
              {[1, 2, 3, 4, 5, 6, 0].map((day) => (
                <CFView row alignCenter mb="10px">
                  <CFView w="120px">{DAYS[day]}</CFView>
                  <TimePicker
                    label="Open"
                    value={fields.hours[day].open}
                    onChange={(time) => changeHours(time, day, 'open')}
                  />
                  <CFView mh="20px">To</CFView>
                  <TimePicker
                    label="Close"
                    value={fields.hours[day].close}
                    onChange={(time) => changeHours(time, day, 'close')}
                  />

                  <Button
                    height={30}
                    marginLeft={30}
                    intent="danger"
                    onClick={() => clearHours(day)}
                  >
                    Reset Hour
                  </Button>
                </CFView>
              ))}
            </CFView>
          </CFView>
          <PrimaryButton
            mt="20px"
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateMenu}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView p="20px" column w="100%" minWidth="340px">
          <CFView bg="white" row center>
            <TextInputField
              marginRight={30}
              label="Menu Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <Checkbox
              label="Active"
              onChange={(e) =>
                setFields({ ...fields, active: e.target.checked })
              }
              checked={fields.active}
            />
          </CFView>
          <CFView column center>
            <CFView column>
              {[1, 2, 3, 4, 5, 6, 0].map((day) => (
                <CFView column justifyStart mb="10px">
                  <CFView w="120px">{DAYS[day]}</CFView>
                  <CFView row mb="20px">
                    <TimePicker
                      label="Open"
                      value={fields.hours[day].open}
                      onChange={(time) => changeHours(time, day, 'open')}
                    />
                    <CFView mh="20px">To</CFView>
                    <TimePicker
                      label="Close"
                      value={fields.hours[day].close}
                      onChange={(time) => changeHours(time, day, 'close')}
                    />
                    <IconButton
                      icon={TrashIcon}
                      marginLeft={10}
                      intent="danger"
                      height={40}
                      onClick={() => clearHours(day)}
                    />
                  </CFView>
                </CFView>
              ))}
            </CFView>
          </CFView>
          <PrimaryButton
            mt="20px"
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateMenu}
          />
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
