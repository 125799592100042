import React, { useState, useEffect } from 'react'
import {
  Card,
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import { Checkbox, SelectField, TextInputField } from 'evergreen-ui'
import * as api from 'api'
import { dispatch } from 'store'
import { DELETE_FIELD_VALUE } from 'cf-core/src/config/firebase'
import { isEmpty } from 'lodash'

const initialState = {
  address: '',
  bagFee: { amount: 0, interval: 0, taxRate: 0, posId: '', posName: '' },
  commissionFee: '0',
  deliveryEnabled: false,
  deliveryFee: '0',
  deliveryOpen: false,
  deliveryOnlinePaymentOnly: false,
  deliveryTime: '30',
  deliveryZone: [],
  dineInCommissionEnabled: false,
  dineInEnabled: false,
  dineInPaymentMethodType: 'online',
  doordashEnabled: false,
  firstOrderDiscount: '0',
  googleMapsUrl: '',
  guestOrderingEnabled: false,
  inactive: false,
  integrationId: '',
  invoiceEmail: '',
  invoiceNumber: '1',
  locationName: '',
  minimumCommission: '0',
  minOrder: '15',
  minOrderDiscount: '0',
  orderOpen: false,
  paymentMethodType: 'online',
  phoneNumber: '',
  pickupDiscount: '0',
  printingEnabled: false,
  promoCodesEnabled: false,
  rewardsEnabled: false,
  scheduleInvoice: false,
  schedulePayout: true,
  stripeAccountId: '',
  stripeCustomerId: '',
  stripeFee: '0',
  subscriptionFee: '0',
  transferSubscriptionFee: false,
  waitTime: '20',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [tipValues, setTipValues] = useState([0.1, 0.15, 0.2])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.location) {
      setFields({
        ...initialState,
        ...props.location,
      })
      if (props.location.tipValues) {
        setTipValues(props.location.tipValues)
      }
    }
  }, [props.location])

  const onTipValuesChange = (tipValue, index) => {
    tipValues[index] = tipValue
    setTipValues([...tipValues])
  }

  const updateLocation = async () => {
    if (!isFormValid()) {
      return
    }
    const {
      commissionFee,
      deliveryFee,
      deliveryTime,
      firstOrderDiscount,
      invoiceNumber,
      minimumCommission,
      minOrder,
      minOrderDiscount,
      pickupDiscount,
      stripeFee,
      subscriptionFee,
      waitTime,
      ...rest
    } = fields
    const locationDetails = {
      commissionFee: Number(commissionFee),
      deliveryFee: Number(deliveryFee),
      deliveryTime: Number(deliveryTime),
      firstOrderDiscount: Number(firstOrderDiscount),
      invoiceNumber: Number(invoiceNumber),
      minimumCommission: Number(minimumCommission),
      minOrder: Number(minOrder),
      minOrderDiscount: Number(minOrderDiscount),
      pickupDiscount: Number(pickupDiscount),
      stripeFee: Number(stripeFee),
      subscriptionFee: Number(subscriptionFee),
      waitTime: Number(waitTime),
      ...rest,
      tipValues: [
        Number(tipValues[0]),
        Number(tipValues[1]),
        Number(tipValues[2]),
      ],
    }
    if (Number(fields.bagFee.amount) > 0) {
      let bagFee = {
        amount: Number(fields.bagFee.amount),
        interval: Number(fields.bagFee.interval),
      }
      if (!isEmpty(fields.bagFee.posId)) {
        bagFee.taxRate = Number(fields.bagFee.taxRate)
        bagFee.posId = fields.bagFee.posId
        bagFee.posName = fields.bagFee.posName
      }
      locationDetails.bagFee = bagFee
    } else {
      if (props.location) {
        locationDetails.bagFee = DELETE_FIELD_VALUE
      }
    }
    setSaving(true)
    try {
      if (props.location) {
        await api.updateLocation(
          props.restaurantId,
          props.location.id,
          locationDetails
        )
      } else {
        const hours = {
          0: { open: 0, close: 0 },
          1: { open: 0, close: 0 },
          2: { open: 0, close: 0 },
          3: { open: 0, close: 0 },
          4: { open: 0, close: 0 },
          5: { open: 0, close: 0 },
          6: { open: 0, close: 0 },
        }
        const DEFAULT_MENU = {
          active: true,
          categoryOrder: [],
          hours: {
            0: { open: 0, close: 0 },
            1: { open: 0, close: 0 },
            2: { open: 0, close: 0 },
            3: { open: 0, close: 0 },
            4: { open: 0, close: 0 },
            5: { open: 0, close: 0 },
            6: { open: 0, close: 0 },
          },
        }
        const menus = {
          default: {
            ...DEFAULT_MENU,
            name: 'Dinner',
          },
          menu1: {
            ...DEFAULT_MENU,
            name: 'Lunch',
          },
          menu2: {
            ...DEFAULT_MENU,
            name: 'Happy Hours',
          },
        }
        locationDetails.hours = hours
        locationDetails.menus = menus
        await api.createLocation(props.restaurantId, locationDetails)
      }
      dispatch.notification.setMessage({
        message: `Location saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert(e)
    } finally {
      setSaving(false)
    }
  }

  const isFormValid = () => {
    const {
      bagFee,
      commissionFee,
      deliveryFee,
      deliveryTime,
      dineInPaymentMethodType,
      firstOrderDiscount,
      invoiceNumber,
      minimumCommission,
      minOrder,
      minOrderDiscount,
      name,
      paymentMethodType,
      pickupDiscount,
      stripeFee,
      subscriptionFee,
      waitTime,
      passcode,
    } = fields
    if (name === '') {
      alert('Name cannot be empty')
      return false
    }
    if (isNaN(Number(invoiceNumber))) {
      alert('Invoice Number must be a number.')
      return false
    }
    if (isNaN(Number(deliveryFee))) {
      alert('Delivery Fee must be a number.')
      return false
    }
    if (isNaN(Number(deliveryTime))) {
      alert('Delivery Time must be a number.')
      return false
    }
    if (isNaN(Number(minOrder))) {
      alert('Min Order discount must be a number.')
      return false
    }
    if (isNaN(Number(minOrderDiscount))) {
      alert('Min Order Discount must be a number.')
      return false
    }
    if (Number(minOrderDiscount) < 0) {
      alert('Min Order Discount must be greater than 0.')
      return false
    }
    if (isNaN(Number(bagFee.amount))) {
      alert('Bag Fee Amount must be a number.')
      return false
    }
    if (isNaN(Number(bagFee.interval))) {
      alert('Bag Fee Interval must be a number.')
      return false
    }
    if (Number(bagFee.amount) > 0 && Number(bagFee.interval) < 10) {
      alert('Bag Fee Interval must be greater than $10.')
      return false
    }
    if (bagFee.taxRate && isNaN(Number(bagFee.taxRate))) {
      alert('Bag Fee Tax Rate must be a number.')
      return false
    }
    if (bagFee.taxRate && Number(bagFee.taxRate) > 1) {
      alert('Bag Fee Tax Rate must be between 0 and 1.')
      return false
    }
    if (isNaN(Number(firstOrderDiscount))) {
      alert('First Order Discount must be a number.')
      return false
    }
    if (Number(firstOrderDiscount) > 1) {
      alert('First Order Discount must between 0 and 1.')
      return false
    }
    if (isNaN(Number(pickupDiscount))) {
      alert('Pickup Discount must be a number.')
      return false
    }
    if (Number(pickupDiscount) > 1) {
      alert('Pickup Discount must between 0 and 1.')
      return false
    }
    if (isNaN(Number(commissionFee))) {
      alert('Commission Fee must be a number.')
      return false
    }
    if (isNaN(Number(stripeFee))) {
      alert('Stripe Fee must be a number.')
      return false
    }
    if (isNaN(Number(subscriptionFee))) {
      alert('Subscription Fee must be a number.')
      return false
    }
    if (isNaN(Number(minimumCommission))) {
      alert('Minimum Commission must be a number.')
      return false
    }
    if (isNaN(Number(waitTime))) {
      alert('Wait Time must be a number.')
      return false
    }
    if (passcode && isNaN(passcode)) {
      alert('Passcode must be a number.')
      return
    }
    if (passcode && passcode.length !== 4) {
      alert('Passcode must be 4 digits.')
      return
    }
    if (Number(waitTime) % 5 > 0) {
      alert('Wait Time must be a multiple of 5.')
      return false
    }
    if (
      paymentMethodType !== 'both' &&
      paymentMethodType !== 'online' &&
      paymentMethodType !== 'offline'
    ) {
      alert('Payment Method Type must be either online or offline or both.')
      return false
    }
    if (
      dineInPaymentMethodType !== 'both' &&
      dineInPaymentMethodType !== 'online' &&
      dineInPaymentMethodType !== 'offline'
    ) {
      alert(
        'Dine-in Payment Method Type must be either online or offline or both.'
      )
      return false
    }
    if (isNaN(tipValues[0]) || tipValues[0] > 1 || tipValues[0] < 0) {
      alert('Tip 1 must be a number between 0 and 1.')
      return false
    }
    if (isNaN(tipValues[1]) || tipValues[1] > 1 || tipValues[1] < 0) {
      alert('Tip 2 must be a number between 0 and 1.')
      return false
    }
    if (isNaN(tipValues[2]) || tipValues[2] > 1 || tipValues[2] < 0) {
      alert('Tip 3 must be a number between 0 and 1.')
      return false
    }
    return true
  }

  return (
    <PrimaryModal
      title={
        props.location
          ? `Editing - ${props.location.locationName} Location`
          : 'Create Location'
      }
      {...props}
    >
      <DefaultScreen>
        <CFView column minWidth="650px" p="20px">
          <CFView column>
            <CFView row center>
              <Checkbox
                marginRight="20px"
                label="Store Open"
                onChange={(e) =>
                  setFields({ ...fields, orderOpen: e.target.checked })
                }
                checked={fields.orderOpen}
              />
              <Checkbox
                label="Delivery Open"
                onChange={(e) =>
                  setFields({ ...fields, deliveryOpen: e.target.checked })
                }
                checked={fields.deliveryOpen}
              />
            </CFView>
            <CFView center>
              <TextInputField
                width="100%"
                marginRight={10}
                label="Current Wait Time"
                value={fields.waitTime}
                onChange={(e) =>
                  setFields({ ...fields, waitTime: e.target.value })
                }
              />
            </CFView>
          </CFView>
          <CFView color="#00C0FF" xBold mt="20px" mb="5px">
            STORE SETTINGS
          </CFView>
          <Card p="15px" mb="15px">
            <CFView row justifyBetween w="100%">
              <TextInputField
                width="100%"
                marginRight={10}
                label="Location Name"
                value={fields.locationName}
                onChange={(e) =>
                  setFields({ ...fields, locationName: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="Address"
                value={fields.address}
                onChange={(e) =>
                  setFields({ ...fields, address: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                label="Phone Number"
                value={fields.phoneNumber}
                onChange={(e) =>
                  setFields({ ...fields, phoneNumber: e.target.value })
                }
              />
            </CFView>
            <CFView row justifyBetween mt="10px">
              <TextInputField
                width="100%"
                marginRight={10}
                label="Min Order Discount"
                value={fields.minOrderDiscount}
                onChange={(e) =>
                  setFields({ ...fields, minOrderDiscount: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="First Order Discount"
                value={fields.firstOrderDiscount}
                onChange={(e) =>
                  setFields({ ...fields, firstOrderDiscount: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                label="Pickup Discount"
                value={fields.pickupDiscount}
                onChange={(e) =>
                  setFields({ ...fields, pickupDiscount: e.target.value })
                }
              />
            </CFView>
            <CFView row justifyBetween mt="10px">
              <TextInputField
                width="100%"
                marginRight={10}
                label="Tip 1"
                value={tipValues[0]}
                onChange={(e) => onTipValuesChange(e.target.value, 0)}
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="Tip 2"
                value={tipValues[1]}
                onChange={(e) => onTipValuesChange(e.target.value, 1)}
              />
              <TextInputField
                width="100%"
                label="Tip 3"
                value={tipValues[2]}
                onChange={(e) => onTipValuesChange(e.target.value, 2)}
              />
            </CFView>
            <CFView row justifyBetween mt="10px">
              <TextInputField
                width="100%"
                marginRight={10}
                label="Bag Fee Amount"
                value={fields.bagFee.amount}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    bagFee: { ...fields.bagFee, amount: e.target.value },
                  })
                }
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="Bag Fee Interval"
                value={fields.bagFee.interval}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    bagFee: { ...fields.bagFee, interval: e.target.value },
                  })
                }
              />
              {props.location.integrationId === 'ini' && (
                <>
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Bag Fee Tax Rate"
                    value={fields.bagFee.taxRate}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        bagFee: { ...fields.bagFee, taxRate: e.target.value },
                      })
                    }
                  />
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Bag Fee POS ID"
                    value={fields.bagFee.posId}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        bagFee: { ...fields.bagFee, posId: e.target.value },
                      })
                    }
                  />
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Bag Fee POS Name"
                    value={fields.bagFee.posName}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        bagFee: { ...fields.bagFee, posName: e.target.value },
                      })
                    }
                  />
                </>
              )}
            </CFView>
            <TextInputField
              width="100%"
              label="Passcode"
              value={fields.passcode}
              onChange={(e) =>
                setFields({ ...fields, passcode: e.target.value })
              }
            />
          </Card>
          <CFView xBold color="#00C0FF" mt="20px" mb="5px">
            PAYMENT SETTINGS
          </CFView>
          <Card p="15px" mb="15px">
            <CFView column>
              <TextInputField
                placeholder="Please Input Owner Email..."
                label="Owner Email"
                value={fields.invoiceEmail}
                onChange={(e) =>
                  setFields({ ...fields, invoiceEmail: e.target.value })
                }
              />
              <SelectField
                label="Payment Methods"
                value={fields.paymentMethodType}
                onChange={(e) =>
                  setFields({ ...fields, paymentMethodType: e.target.value })
                }
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
                <option value="both" selected>
                  Both
                </option>
              </SelectField>
              <SelectField
                mt="10px"
                label="Dine-in Payment Methods"
                value={fields.dineInPaymentMethodType}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    dineInPaymentMethodType: e.target.value,
                  })
                }
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
                <option value="both" selected>
                  Both
                </option>
              </SelectField>
              {dispatch.dashboard.getRole() === 'super_admin' && (
                <CFView mt="10px" row>
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Invoice Number"
                    value={fields.invoiceNumber}
                    onChange={(e) =>
                      setFields({ ...fields, invoiceNumber: e.target.value })
                    }
                  />
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Stripe Account ID"
                    value={fields.stripeAccountId}
                    onChange={(e) =>
                      setFields({ ...fields, stripeAccountId: e.target.value })
                    }
                  />
                  <TextInputField
                    width="100%"
                    label="Stripe Customer ID"
                    value={fields.stripeCustomerId}
                    onChange={(e) =>
                      setFields({ ...fields, stripeCustomerId: e.target.value })
                    }
                  />
                </CFView>
              )}
              <Checkbox
                label="Schedule Invoice"
                onChange={(e) =>
                  setFields({
                    ...fields,
                    scheduleInvoice: e.target.checked,
                  })
                }
                checked={fields.scheduleInvoice}
              />
            </CFView>
          </Card>
          <CFView xBold color="#00C0FF" mt="20px" mb="5px">
            DELIVERY SETTINGS
          </CFView>
          <Card p="15px" mb="15px">
            <CFView column>
              <Checkbox
                label="Delivery Enabled"
                onChange={(e) =>
                  setFields({
                    ...fields,
                    deliveryEnabled: e.target.checked,
                  })
                }
                checked={fields.deliveryEnabled}
              />
              <CFView row justifyBetween mt="10px">
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Delivery Fee"
                  value={fields.deliveryFee}
                  onChange={(e) =>
                    setFields({ ...fields, deliveryFee: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Delivery Time"
                  value={fields.deliveryTime}
                  onChange={(e) =>
                    setFields({ ...fields, deliveryTime: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Min Order Delivery"
                  value={fields.minOrder}
                  onChange={(e) =>
                    setFields({ ...fields, minOrder: e.target.value })
                  }
                />
              </CFView>
            </CFView>
          </Card>
          {dispatch.dashboard.getRole() === 'super_admin' && (
            <>
              <CFView xBold color="#00C0FF" mt="20px" mb="5px">
                OTHER SETTINGS
              </CFView>
              <Card p="15px">
                <CFView column>
                  <Checkbox
                    label="Inactive"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        inactive: e.target.checked,
                      })
                    }
                    checked={fields.inactive}
                  />
                  <Checkbox
                    mt="10px"
                    label="Dine-in Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        dineInEnabled: e.target.checked,
                      })
                    }
                    checked={fields.dineInEnabled}
                  />
                  <Checkbox
                    mt="10px"
                    label="Guest Ordering Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        guestOrderingEnabled: e.target.checked,
                      })
                    }
                    checked={fields.guestOrderingEnabled}
                  />
                  <Checkbox
                    mt="10px"
                    label="Door Dash Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        doordashEnabled: e.target.checked,
                      })
                    }
                    checked={fields.doordashEnabled}
                  />
                  <Checkbox
                    mt="10px"
                    label="Delivery Online Payment Only"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        deliveryOnlinePaymentOnly: e.target.checked,
                      })
                    }
                    checked={fields.deliveryOnlinePaymentOnly}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Dine-in Commission"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        dineInCommissionEnabled: e.target.checked,
                      })
                    }
                    checked={fields.dineInCommissionEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Printing Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        printingEnabled: e.target.checked,
                      })
                    }
                    checked={fields.printingEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Promo Codes Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        promoCodesEnabled: e.target.checked,
                      })
                    }
                    checked={fields.promoCodesEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Rewards Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        rewardsEnabled: e.target.checked,
                      })
                    }
                    checked={fields.rewardsEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Schedule Payout"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        schedulePayout: e.target.checked,
                      })
                    }
                    checked={fields.schedulePayout}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Transfer Fee"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        transferSubscriptionFee: e.target.checked,
                      })
                    }
                    checked={fields.transferSubscriptionFee}
                  />
                  <CFView row justifyBetween mt="10px">
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Commission Fee"
                      value={fields.commissionFee}
                      onChange={(e) =>
                        setFields({ ...fields, commissionFee: e.target.value })
                      }
                    />
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Stripe Fee"
                      value={fields.stripeFee}
                      onChange={(e) =>
                        setFields({ ...fields, stripeFee: e.target.value })
                      }
                    />
                    <TextInputField
                      width="100%"
                      label="Subscription Fee"
                      value={fields.subscriptionFee}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          subscriptionFee: e.target.value,
                        })
                      }
                    />
                  </CFView>
                  <CFView row justifyBetween mt="10px">
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Minimum Commission"
                      value={fields.minimumCommission}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          minimumCommission: e.target.value,
                        })
                      }
                    />
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Google Maps Url"
                      value={fields.googleMapsUrl}
                      onChange={(e) =>
                        setFields({ ...fields, googleMapsUrl: e.target.value })
                      }
                    />
                    <TextInputField
                      width="100%"
                      label="Integration ID"
                      value={fields.integrationId}
                      onChange={(e) =>
                        setFields({ ...fields, integrationId: e.target.value })
                      }
                    />
                  </CFView>
                </CFView>
              </Card>
            </>
          )}
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={updateLocation}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView column w="100%" p="20px" minWidth="340px">
          <CFView column>
            <CFView row center>
              <Checkbox
                marginRight="20px"
                label="Store Open"
                onChange={(e) =>
                  setFields({ ...fields, orderOpen: e.target.checked })
                }
                checked={fields.orderOpen}
              />
              <Checkbox
                label="Delivery Open"
                onChange={(e) =>
                  setFields({ ...fields, deliveryOpen: e.target.checked })
                }
                checked={fields.deliveryOpen}
              />
            </CFView>
            <CFView center>
              <TextInputField
                width="100%"
                marginRight={10}
                label="Current Wait Time"
                value={fields.waitTime}
                onChange={(e) =>
                  setFields({ ...fields, waitTime: e.target.value })
                }
              />
            </CFView>
          </CFView>
          <CFView color="#00C0FF" xBold center mt="20px" mb="5px">
            STORE SETTINGS
          </CFView>
          <Card p="15px" mb="15px">
            <CFView column justifyBetween w="100%">
              <TextInputField
                width="100%"
                marginRight={10}
                label="Location Name"
                value={fields.locationName}
                onChange={(e) =>
                  setFields({ ...fields, locationName: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="Address"
                value={fields.address}
                onChange={(e) =>
                  setFields({ ...fields, address: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                label="Phone Number"
                value={fields.phoneNumber}
                onChange={(e) =>
                  setFields({ ...fields, phoneNumber: e.target.value })
                }
              />
            </CFView>
            <CFView column justifyBetween>
              <TextInputField
                width="100%"
                marginRight={10}
                label="Min Order Discount"
                value={fields.minOrderDiscount}
                onChange={(e) =>
                  setFields({ ...fields, minOrderDiscount: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="First Order Discount"
                value={fields.firstOrderDiscount}
                onChange={(e) =>
                  setFields({ ...fields, firstOrderDiscount: e.target.value })
                }
              />
              <TextInputField
                width="100%"
                label="Pickup Discount"
                value={fields.pickupDiscount}
                onChange={(e) =>
                  setFields({ ...fields, pickupDiscount: e.target.value })
                }
              />
            </CFView>
            <CFView column justifyBetween>
              <TextInputField
                width="100%"
                marginRight={10}
                label="Tip 1"
                value={tipValues[0]}
                onChange={(e) => onTipValuesChange(e.target.value, 0)}
              />
              <TextInputField
                width="100%"
                marginRight={10}
                label="Tip 2"
                value={tipValues[1]}
                onChange={(e) => onTipValuesChange(e.target.value, 1)}
              />
              <TextInputField
                width="100%"
                label="Tip 3"
                value={tipValues[2]}
                onChange={(e) => onTipValuesChange(e.target.value, 2)}
              />
            </CFView>
            <TextInputField
              width="100%"
              label="Passcode"
              value={fields.passcode}
              onChange={(e) =>
                setFields({ ...fields, passcode: e.target.value })
              }
            />
          </Card>
          <CFView xBold center color="#00C0FF" mt="20px" mb="5px">
            PAYMENT SETTINGS
          </CFView>
          <Card p="15px" mb="15px">
            <CFView column>
              <TextInputField
                placeholder="Please Input Owner Email..."
                label="Owner Email"
                value={fields.invoiceEmail}
                onChange={(e) =>
                  setFields({ ...fields, invoiceEmail: e.target.value })
                }
              />
              <SelectField
                label="Payment Methods"
                value={fields.paymentMethodType}
                onChange={(e) =>
                  setFields({ ...fields, paymentMethodType: e.target.value })
                }
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
                <option value="both" selected>
                  Both
                </option>
              </SelectField>
              <SelectField
                mt="10px"
                label="Dine-in Payment Methods"
                value={fields.dineInPaymentMethodType}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    dineInPaymentMethodType: e.target.value,
                  })
                }
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
                <option value="both" selected>
                  Both
                </option>
              </SelectField>
              {dispatch.dashboard.getRole() === 'super_admin' && (
                <CFView column>
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Invoice Number"
                    value={fields.invoiceNumber}
                    onChange={(e) =>
                      setFields({ ...fields, invoiceNumber: e.target.value })
                    }
                  />
                  <TextInputField
                    width="100%"
                    marginRight={10}
                    label="Stripe Account ID"
                    value={fields.stripeAccountId}
                    onChange={(e) =>
                      setFields({ ...fields, stripeAccountId: e.target.value })
                    }
                  />
                  <TextInputField
                    width="100%"
                    label="Stripe Customer ID"
                    value={fields.stripeCustomerId}
                    onChange={(e) =>
                      setFields({ ...fields, stripeCustomerId: e.target.value })
                    }
                  />
                </CFView>
              )}
              <Checkbox
                label="Schedule Invoice"
                onChange={(e) =>
                  setFields({
                    ...fields,
                    scheduleInvoice: e.target.checked,
                  })
                }
                checked={fields.scheduleInvoice}
              />
            </CFView>
          </Card>
          <CFView xBold center color="#00C0FF" mt="20px" mb="5px">
            DELIVERY SETTINGS
          </CFView>
          <Card p="15px" mb="15px">
            <CFView column>
              <Checkbox
                label="Delivery Enabled"
                onChange={(e) =>
                  setFields({
                    ...fields,
                    deliveryEnabled: e.target.checked,
                  })
                }
                checked={fields.deliveryEnabled}
              />
              <CFView column justifyBetween mt="10px">
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Delivery Fee"
                  value={fields.deliveryFee}
                  onChange={(e) =>
                    setFields({ ...fields, deliveryFee: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Delivery Time"
                  value={fields.deliveryTime}
                  onChange={(e) =>
                    setFields({ ...fields, deliveryTime: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Min Order Delivery"
                  value={fields.minOrder}
                  onChange={(e) =>
                    setFields({ ...fields, minOrder: e.target.value })
                  }
                />
              </CFView>
              {/* <LabelInput
            mt="10px"
            label="Delivery Zone"
            value={fields.minOrder}
            onChange={(e) => setFields({ ...fields, minOrder: e.target.value })}
          /> */}
            </CFView>
          </Card>
          {dispatch.dashboard.getRole() === 'super_admin' && (
            <>
              <CFView xBold center color="#00C0FF" mt="20px" mb="5px">
                OTHER SETTINGS
              </CFView>
              <Card p="15px">
                <CFView column>
                  <Checkbox
                    label="Inactive"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        inactive: e.target.checked,
                      })
                    }
                    checked={fields.inactive}
                  />
                  <Checkbox
                    mt="10px"
                    label="Dine-in Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        dineInEnabled: e.target.checked,
                      })
                    }
                    checked={fields.dineInEnabled}
                  />
                  <Checkbox
                    mt="10px"
                    label="Guest Ordering Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        guestOrderingEnabled: e.target.checked,
                      })
                    }
                    checked={fields.guestOrderingEnabled}
                  />
                  <Checkbox
                    mt="10px"
                    label="Door Dash Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        doordashEnabled: e.target.checked,
                      })
                    }
                    checked={fields.doordashEnabled}
                  />
                  <Checkbox
                    mt="10px"
                    label="Delivery Online Payment Only"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        deliveryOnlinePaymentOnly: e.target.checked,
                      })
                    }
                    checked={fields.deliveryOnlinePaymentOnly}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Dine-in Commission"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        dineInCommissionEnabled: e.target.checked,
                      })
                    }
                    checked={fields.dineInCommissionEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Printing Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        printingEnabled: e.target.checked,
                      })
                    }
                    checked={fields.printingEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Rewards Enabled"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        rewardsEnabled: e.target.checked,
                      })
                    }
                    checked={fields.rewardsEnabled}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Schedule Payout"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        schedulePayout: e.target.checked,
                      })
                    }
                    checked={fields.schedulePayout}
                  />
                  <Checkbox
                    marginTop={10}
                    label="Transfer Fee"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        transferSubscriptionFee: e.target.checked,
                      })
                    }
                    checked={fields.transferSubscriptionFee}
                  />
                  <CFView column justifyBetween mt="10px">
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Commission Fee"
                      value={fields.commissionFee}
                      onChange={(e) =>
                        setFields({ ...fields, commissionFee: e.target.value })
                      }
                    />
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Stripe Fee"
                      value={fields.stripeFee}
                      onChange={(e) =>
                        setFields({ ...fields, stripeFee: e.target.value })
                      }
                    />
                    <TextInputField
                      width="100%"
                      label="Subscription Fee"
                      value={fields.subscriptionFee}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          subscriptionFee: e.target.value,
                        })
                      }
                    />
                  </CFView>
                  <CFView column justifyBetween mt="10px">
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Minimum Commission"
                      value={fields.minimumCommission}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          minimumCommission: e.target.value,
                        })
                      }
                    />
                    <TextInputField
                      width="100%"
                      marginRight={10}
                      label="Google Maps Url"
                      value={fields.googleMapsUrl}
                      onChange={(e) =>
                        setFields({ ...fields, googleMapsUrl: e.target.value })
                      }
                    />
                    <TextInputField
                      width="100%"
                      label="Integration ID"
                      value={fields.integrationId}
                      onChange={(e) =>
                        setFields({ ...fields, integrationId: e.target.value })
                      }
                    />
                  </CFView>
                </CFView>
              </Card>
            </>
          )}
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={updateLocation}
            />
          </CFView>
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
