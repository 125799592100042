import React, { useState } from 'react'
import { Card, LabelInput, CFView, PrimaryButton } from 'components'
import * as api from 'api'

export default (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [authenticating, setAuthenticating] = useState(false)

  const handleLoginPress = async () => {
    setAuthenticating(true)
    try {
      await api.auth.signInWithEmailAndPassword(email, password)
    } catch (e) {
      alert('Sorry, wrong credentials.')
    } finally {
      setAuthenticating(false)
    }
  }

  return (
    <CFView w="100%" h="calc(100vh - 80px)" row justifyCenter alignCenter>
      <Card h="30rem" w="48.5rem" p="4rem" column justifyAround>
        <CFView h2 bold>
          Authenticate
        </CFView>
        <LabelInput
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CFView h="1rem" />
        <LabelInput
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CFView h="2rem" />
        <PrimaryButton
          disabled={authenticating}
          onClick={handleLoginPress}
          label={authenticating ? 'Authenticating...' : 'Login'}
        />
      </Card>
    </CFView>
  )
}
