import React from 'react'
import { CFIcon, CFView, DefaultScreen, MobileScreen } from 'components'

export default (props) => {
  return (
    <CFView w="80px" row alignCenter hover {...props}>
      <DefaultScreen>
        <CFIcon icon="arrow-left" fontSize={28} />
        <CFView bold black ml="5px">
          Back
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFIcon icon="arrow-left" fontSize={28} />
      </MobileScreen>
    </CFView>
  )
}
