import React from 'react'
import { NavLink } from 'react-router-dom'
import { CFView, IconLink, DefaultScreen, MobileScreen } from 'components'
import { cflogo } from 'images'
import { COLOR } from 'styles'
import { dispatch } from 'store'

export default (props) => (
  <CFView
    w="100%"
    h="70px"
    center
    bg={COLOR.lightOpacity}
    boxShadow={'0 1px 3px rgba(0,0,0,.8)'}
  >
    <DefaultScreen>
      <CFView w="100%" maxWidth="1200px" row justifyBetween alignCenter>
        <NavLink to="/">
          <img src={cflogo} alt="cflogo" />
        </NavLink>
        {props.authed ? (
          <>
            {dispatch.dashboard.getRole() === 'super_admin' && (
              <CFView w="720px" row alignCenter>
                <CFView mr="20px">
                  <IconLink label="RESTAURANTS" to="/restaurants" icon="home" />
                </CFView>
                <IconLink label="USERS" to="/users" icon="user" />
                <IconLink
                  label="STATISTICS"
                  to="/statistics"
                  icon="chart-pie"
                />
                <IconLink
                  label="SIGN OUT"
                  to="/auth"
                  icon="sign-out-alt"
                  onClick={() => dispatch.dashboard.signOut()}
                />
              </CFView>
            )}
            {dispatch.dashboard.getRole() === 'admin' && (
              <CFView w="800px" row alignCenter>
                <IconLink label="ORDERS" to="/orders" icon="home" />
                <IconLink label="MENUS" to="/menus" icon="utensils" />
                <IconLink label="ANALYTICS" to="/analytics" icon="chart-bar" />
                <IconLink label="SETTINGS" to="/settings" icon="cog" />
                <IconLink
                  label="SIGN OUT"
                  to="/auth"
                  icon="sign-out-alt"
                  onClick={() => dispatch.dashboard.signOut()}
                />
              </CFView>
            )}
            {(dispatch.dashboard.getRole() === 'int_admin' ||
              dispatch.dashboard.getRole() === 'menu_admin') && (
              <CFView>
                <IconLink
                  label="SIGN OUT"
                  to="/auth"
                  icon="sign-out-alt"
                  onClick={() => dispatch.dashboard.signOut()}
                />
              </CFView>
            )}
          </>
        ) : (
          <CFView>
            <IconLink label="AUTHENTICATE" to="/auth" icon="sign-in-alt" />
          </CFView>
        )}
      </CFView>
    </DefaultScreen>
    <MobileScreen>
      <CFView w="100%" h="60px" center boxShadow={'0 -1px 3px rgba(0,0,0,.8)'}>
        {props.authed ? (
          <CFView w="100%">
            {dispatch.dashboard.getRole() === 'super_admin' && (
              <CFView row center>
                <CFView w="30%">
                  <IconLink label="RESTAURANTS" to="/restaurants" icon="home" />
                </CFView>
                <CFView w="30%">
                  <IconLink label="USERS" to="/users" icon="user" />
                </CFView>
                <CFView w="30%">
                  <IconLink
                    label="SIGN OUT"
                    to="/auth"
                    icon="sign-out-alt"
                    onClick={() => dispatch.dashboard.signOut()}
                  />
                </CFView>
              </CFView>
            )}
            {dispatch.dashboard.getRole() === 'admin' && (
              <CFView row center>
                <IconLink label="ORDERS" to="/orders" icon="home" />
                <IconLink label="MENUS" to="/menus" icon="utensils" />
                <IconLink label="ANALYTICS" to="/analytics" icon="chart-bar" />
                <IconLink label="SETTINGS" to="/settings" icon="cog" />
                <IconLink
                  label="SIGN OUT"
                  to="/auth"
                  icon="sign-out-alt"
                  onClick={() => dispatch.dashboard.signOut()}
                />
              </CFView>
            )}
            {(dispatch.dashboard.getRole() === 'int_admin' ||
              dispatch.dashboard.getRole() === 'menu_admin') && (
              <CFView>
                <IconLink
                  label="SIGN OUT"
                  to="/auth"
                  icon="sign-out-alt"
                  onClick={() => dispatch.dashboard.signOut()}
                />
              </CFView>
            )}
          </CFView>
        ) : (
          <CFView>
            <IconLink label="AUTHENTICATE" to="/auth" icon="sign-in-alt" />
          </CFView>
        )}
      </CFView>
    </MobileScreen>
  </CFView>
)
