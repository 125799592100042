import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import { Button, EditIcon, UserIcon, CodeIcon } from 'evergreen-ui'
import TipValuesModal from './TipValuesModal'
import HoursModal from './HoursModal'
import MessageModal from './MessageModal'
import PasscodeModal from './PasscodeModal'
import { isEmpty } from 'lodash'
import { moment } from 'cf-utils'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const [showModal, setShowModal] = useState('')

  useEffect(() => {
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [])

  const closeModal = () => {
    setShowModal('')
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            <Card h="calc(100vh - 110px)" w="1080px" column overflowY="auto">
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Settings
                </CFView>
              </CFView>
              <CFView p="20px" column>
                <CFSelect selector={dispatch.restaurant.getDineInEnabled}>
                  <Card p="10px" mb="10px" row justifyBetween>
                    <CFView>Manage Staff</CFView>
                    <CFView>
                      <Button
                        width={170}
                        appearance="primary"
                        iconBefore={UserIcon}
                        height={40}
                        onClick={() => history.push('/staff')}
                      >
                        View Staff
                      </Button>
                    </CFView>
                  </Card>
                </CFSelect>
                <CFSelect selector={dispatch.restaurant.getPromoCodesEnabled}>
                  <Card p="10px" mb="10px" row justifyBetween>
                    <CFView>Promo Codes</CFView>
                    <CFView>
                      <Button
                        width={170}
                        appearance="primary"
                        iconBefore={CodeIcon}
                        height={40}
                        onClick={() => history.push('/promoCodes')}
                      >
                        View Promos
                      </Button>
                    </CFView>
                  </Card>
                </CFSelect>
                <Card p="10px" mb="10px" row justifyBetween>
                  <CFView column>
                    <CFView mb="10px">Tip Values</CFView>
                    <CFView>
                      <CFSelect selector={dispatch.restaurant.getTipValues}>
                        {(tipValues) =>
                          tipValues.map((tipValue, index) => (
                            <CFView h5 bold black mb="5px">
                              {`${index + 1}: ${tipValue * 100}%`}
                            </CFView>
                          ))
                        }
                      </CFSelect>
                    </CFView>
                  </CFView>
                  <Button
                    width={170}
                    appearance="primary"
                    iconBefore={EditIcon}
                    height={40}
                    onClick={() => setShowModal('tipValues')}
                  >
                    Edit Tips
                  </Button>
                </Card>
                <Card p="10px" mb="10px" row justifyBetween>
                  <CFView column>
                    <CFView mb="20px">Hours</CFView>
                    <CFSelect selector={dispatch.restaurant.getHours}>
                      {(hours) =>
                        [1, 2, 3, 4, 5, 6, 0].map((day) => (
                          <CFView key={day} column>
                            <CFView row w="100%" mb="10px">
                              <CFView w="120px" h5 black bold>{`${moment()
                                .weekday(day)
                                .format('dddd')}`}</CFView>
                              <CFView
                                h5
                                black
                                w="100%"
                                row
                                justifyEnd
                                textRight
                              >
                                {isEmpty(hours)
                                  ? ''
                                  : hours[day].open && hours[day].close
                                  ? `${moment(hours[day].open).format('LT')} ${
                                      hours[day].breakStart &&
                                      hours[day].breakEnd
                                        ? ` - ${moment(
                                            hours[day].breakStart
                                          ).format('LT')} ${moment(
                                            hours[day].breakEnd
                                          ).format('LT')} -`
                                        : '-'
                                    } ${moment(hours[day].close).format('LT')}`
                                  : 'Closed'}
                              </CFView>
                            </CFView>
                          </CFView>
                        ))
                      }
                    </CFSelect>
                  </CFView>
                  <CFView row>
                    <Button
                      width={170}
                      appearance="primary"
                      iconBefore={EditIcon}
                      height={40}
                      onClick={() => setShowModal('hours')}
                    >
                      Edit Hours
                    </Button>
                  </CFView>
                </Card>
                <Card p="10px" mb="10px" row justifyBetween>
                  <CFView column>
                    <CFView mb="20px">Welcome Message</CFView>
                    <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
                      {(welcomeMessage) =>
                        welcomeMessage.title ? (
                          <CFView column>
                            <CFView
                              h4
                            >{`Title: ${welcomeMessage.title}`}</CFView>
                            <CFView h4>{`Body: ${welcomeMessage.body}`}</CFView>
                          </CFView>
                        ) : (
                          <CFView>No Welcome Message</CFView>
                        )
                      }
                    </CFSelect>
                  </CFView>
                  <CFView row>
                    <Button
                      width={170}
                      appearance="primary"
                      iconBefore={EditIcon}
                      height={40}
                      onClick={() => setShowModal('message')}
                    >
                      Edit Message
                    </Button>
                  </CFView>
                </Card>
                <Card p="10px" mb="10px" row justifyBetween>
                  <CFView column>
                    <CFView mb="20px">Passcode</CFView>
                    <CFSelect selector={dispatch.restaurant.getPasscode}>
                      {(passcode) =>
                        passcode ? (
                          <CFView column>
                            <CFView h4>{passcode}</CFView>
                          </CFView>
                        ) : (
                          <CFView>0808</CFView>
                        )
                      }
                    </CFSelect>
                  </CFView>
                  <CFView row>
                    <Button
                      width={170}
                      appearance="primary"
                      iconBefore={EditIcon}
                      height={40}
                      onClick={() => setShowModal('passcode')}
                    >
                      Edit Passcode
                    </Button>
                  </CFView>
                </Card>
              </CFView>
            </Card>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader title="Settings" />
        <CFView
          h="calc(100vh - 120px)"
          column
          overflowY="auto"
          minWidth="320px"
        >
          <CFView p="20px" column>
            <CFSelect selector={dispatch.restaurant.getDineInEnabled}>
              <Card mb="15px" column center>
                <CFView w="100%" bold center bg="rgba(0,0,0,.3)" pv="10px">
                  Manage Staff
                </CFView>
                <CFView mv="10px">
                  <Button
                    width={170}
                    appearance="primary"
                    iconBefore={UserIcon}
                    height={40}
                    onClick={() => history.push('/staff')}
                  >
                    View Staff
                  </Button>
                </CFView>
              </Card>
            </CFSelect>
            <Card mb="15px" column center>
              <CFView w="100%" bold center bg="rgba(0,0,0,.3)" pv="10px">
                Tip Values
              </CFView>
              <CFView mv="10px">
                <Button
                  width={170}
                  appearance="primary"
                  iconBefore={EditIcon}
                  height={40}
                  onClick={() => setShowModal('tipValues')}
                >
                  Edit Tips
                </Button>
              </CFView>
            </Card>
            <Card mb="15px" column center>
              <CFView w="100%" bold center bg="rgba(0,0,0,.3)" pv="10px">
                Hours
              </CFView>
              <CFView mv="10px">
                <Button
                  width={170}
                  appearance="primary"
                  iconBefore={EditIcon}
                  height={40}
                  onClick={() => setShowModal('hours')}
                >
                  Edit Hours
                </Button>
              </CFView>
            </Card>
            <Card mb="15px" column center>
              <CFView
                w="100%"
                bold
                columb
                center
                bg="rgba(0,0,0,.3)"
                pv="10px"
                mb="10px"
              >
                Welcome Message
              </CFView>
              <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
                {(welcomeMessage) =>
                  welcomeMessage.title ? (
                    <CFView column>
                      <CFView>{`Title: ${welcomeMessage.title}`}</CFView>
                      <CFView>{`Body: ${welcomeMessage.body}`}</CFView>
                    </CFView>
                  ) : (
                    <CFView>No Welcome Message</CFView>
                  )
                }
              </CFSelect>
              <CFView mv="10px">
                <Button
                  width={170}
                  appearance="primary"
                  iconBefore={EditIcon}
                  height={40}
                  onClick={() => setShowModal('message')}
                >
                  Edit Message
                </Button>
              </CFView>
            </Card>
          </CFView>
        </CFView>
      </MobileScreen>
      <CFSelect selector={dispatch.restaurant.getTipValues}>
        {(tipValues) => (
          <TipValuesModal
            tipValues={tipValues}
            isOpen={showModal === 'tipValues'}
            onRequestClose={closeModal}
          />
        )}
      </CFSelect>
      <HoursModal isOpen={showModal === 'hours'} onRequestClose={closeModal} />
      <CFSelect selector={dispatch.restaurant.getPasscode}>
        {(passcode) => (
          <PasscodeModal
            isOpen={showModal === 'passcode'}
            onRequestClose={closeModal}
            passcode={passcode}
          />
        )}
      </CFSelect>
      <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
        {(welcomeMessage) => (
          <MessageModal
            welcomeMessage={welcomeMessage}
            isOpen={showModal === 'message'}
            onRequestClose={closeModal}
          />
        )}
      </CFSelect>
    </>
  )
}
