import React, { useState, useEffect } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

export default (props) => {
  const [fields, setFields] = useState({})
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setFields({ index: props.index })
  }, [props.index])

  const updateRanking = async () => {
    const numberStr = fields.index.replace(/\D/g, '')
    const newIndex = parseInt(numberStr, 10)
    if (isNaN(newIndex)) {
      alert('Rank must be a number.')
      return
    }
    if (props.index !== newIndex) {
      setSaving(true)
      try {
        await dispatch.dashboard.changeCategoryOrder({
          menuId: props.menuId,
          fromIndex: props.index,
          toIndex: newIndex,
        })
        dispatch.notification.setMessage({
          message: `Rank updated!`,
          level: 'success',
        })
        props.onRequestClose()
      } catch (e) {
        alert(e)
      } finally {
        setSaving(false)
      }
    }
  }

  return (
    <PrimaryModal title={`Update ${props.category.name} Rank`} {...props}>
      <CFView column justifyCenter w="400px" p="20px">
        <LabelInput
          label="Rank"
          value={fields.index}
          onChange={(e) => setFields({ ...fields, index: e.target.value })}
        />
        <CFView h="20px" />
        <PrimaryButton
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateRanking}
        />
      </CFView>
    </PrimaryModal>
  )
}
