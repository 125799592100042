import React, { useState } from 'react'
import { CSVReader } from 'react-papaparse'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import * as api from 'api'

export default (props) => {
  const [status, setStatus] = useState('')
  const [saving, setSaving] = useState(false)
  const [CSVData, setCSVData] = useState([])

  const handleOnDrop = (data) => {
    setCSVData(data)
  }

  const handleOnError = (err, file, inputElem, reason) => {
    alert('Error with upload.')
  }

  const handleOnRemoveFile = () => {
    setCSVData([])
  }

  const updateProducts = async () => {
    setSaving(true)
    try {
      await Promise.all(
        CSVData.map(({ data }, index) => {
          if (index === 0) {
            return Promise.resolve()
          }
          if (!data[0]) {
            return Promise.resolve()
          }
          const productId = data[0]
          const name = data[1]
          const description = data[2]
          const price = Number(data[3])
          const taxRate = Number(data[4])
          const imageUrl = data[5]
          const orderType = data[6]
          const posId = data[7]
          const posName = data[8]
          const productDetails = {
            name,
            description,
            price,
            taxRate,
            imageUrl,
            orderType,
            posId,
            posName,
          }
          const restaurantId = dispatch.restaurant.getRestaurantId()
          const locationId = dispatch.restaurant.getSelectedLocationId()
          setStatus(`Updating ${name}...`)
          return api.updateProduct(
            restaurantId,
            locationId,
            productId,
            productDetails
          )
        })
      )
      dispatch.notification.setMessage({
        message: `Products updated!`,
        level: 'success',
      })
      props.onRequestClose()
    } catch {
      alert('Error saving data.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Import CSV" {...props}>
      <CFView column w="500px" h="400px">
        {saving ? (
          <CFView fill center>
            <CFView>{status}</CFView>
          </CFView>
        ) : (
          <CFView p="20px" fill>
            <CSVReader
              noClick
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here.</span>
            </CSVReader>
          </CFView>
        )}
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={CSVData.length === 0 || saving}
            onClick={updateProducts}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
