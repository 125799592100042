import React, { useState } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

const initialState = {
  userId: '',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  const removePayment = async () => {
    let { userId } = fields
    if (!userId) {
      alert('User Id cannot be empty.')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.updateUser({ userId, user: { payment: null } })
      dispatch.notification.setMessage({
        message: `User payment removed!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error saving user.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Cancel Order" {...props}>
      <CFView column>
        <CFView p="20px" bg="white" row>
          <CFView column>
            <LabelInput
              label="User Id"
              value={fields.userId}
              onChange={(e) => setFields({ ...fields, userId: e.target.value })}
            />
          </CFView>
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={removePayment}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
