import React from 'react'
import {
  PrimaryModal,
  DefaultScreen,
  MobileScreen,
  CFSelect,
  CFView,
  PrimaryButton,
  CheckSelectButton,
  CircleSelectButton,
} from 'components'
import { dispatch } from 'store'
import { Pane } from 'evergreen-ui'
import { CircleArrowDownIcon } from 'evergreen-ui'
import { useState } from 'react'

export default (props) => {
  const [saving, setSaving] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState({})
  const [selectedProducts, setSelectedProducts] = useState([])

  const addProductToCopy = (id) => {
    if (selectedProducts.includes(id)) {
      const newProducts = selectedProducts.filter((product) => product !== id)
      setSelectedProducts(newProducts)
    } else {
      setSelectedProducts([...selectedProducts, id])
    }
  }

  const toggleAccordion = (category) => {
    if (selectedCategory.id === category.id) {
      setSelectedCategory({})
    } else {
      setSelectedCategory(category)
    }
  }

  const moveProducts = () => {
    setSaving(true)
    const selectedCategoriesProduct = props.category.productOrder.filter(
      (el) => !selectedProducts.includes(el)
    )

    const newProducts = [...selectedCategory.productOrder, ...selectedProducts]

    dispatch.dashboard.updateProductOrder({
      categoryId: selectedCategory.id,
      products: newProducts,
    })

    dispatch.dashboard.updateProductOrder({
      categoryId: props.category.id,
      products: selectedCategoriesProduct,
    })
    dispatch.notification.setMessage({
      message: `Products copied!`,
      level: 'success',
    })

    setSaving(false)
    props.onRequestClose()
  }
  return (
    <>
      <DefaultScreen>
        <PrimaryModal w="700px" title={'Move Products'} {...props}>
          <CFSelect
            selector={[
              () => dispatch.restaurant.getCategoryOrder(props.menuId),
              dispatch.restaurant.getCategories,
            ]}
          >
            {([categoryOrder, categories]) =>
              Object.values(categories).length > 0 ? (
                <CFView p="20px" column>
                  {categoryOrder
                    .filter((item) => item !== props.category.id)
                    .map((categoryId, index) => {
                      return (
                        <CircleSelectButton
                          mb="10px"
                          label={categories[categoryId]?.name}
                          selected={selectedCategory === categories[categoryId]}
                          onClick={() => {
                            toggleAccordion(categories[categoryId])
                          }}
                        />
                      )
                    })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Categories
                </CFView>
              )
            }
          </CFSelect>
          <CFSelect
            selector={[
              () =>
                dispatch.restaurant.getProductsInOrder(props.category.id, true),
            ]}
          >
            {(productList) =>
              Object.values(productList).length > 0 ? (
                <CFView pl="20px" pr="20px" pb="20px" column>
                  {Object.values(productList).map((product) => {
                    return product.map((prod) => (
                      <CheckSelectButton
                        mb="10px"
                        label={prod.name}
                        selected={selectedProducts.includes(prod.id)}
                        onClick={() => addProductToCopy(prod.id)}
                      />
                    ))
                  })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Products
                </CFView>
              )
            }
          </CFSelect>
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={
                saving || !selectedCategory.id || selectedProducts.length === 0
              }
              onClick={moveProducts}
            />
          </CFView>
        </PrimaryModal>
      </DefaultScreen>
      <MobileScreen>
        <PrimaryModal
          w="300px"
          title={
            props.category ? `Edit ${props.category.name}` : 'Create Category'
          }
          {...props}
        >
          <CFSelect
            selector={[
              () => dispatch.restaurant.getCategoryOrder(props.menuId),
              dispatch.restaurant.getCategories,
            ]}
          >
            {([categoryOrder, categories]) =>
              Object.values(categories).length > 0 ? (
                <Pane
                  paddingX="15px"
                  paddingY="10px"
                  display="flex"
                  flexDirection="column"
                >
                  {categoryOrder
                    .filter((item) => item !== props.category.id)
                    .map((categoryId, index) => {
                      return (
                        <Pane
                          onClick={() => {
                            toggleAccordion(categories[categoryId])
                          }}
                          width="100%"
                          bg="#ccc"
                          mb="10px"
                          padding="10px"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          backgroundColor={
                            selectedCategory.id === categoryId
                              ? 'lightgray'
                              : 'white'
                          }
                        >
                          {categories[categoryId]?.name}
                          <CircleArrowDownIcon size={30} />
                        </Pane>
                      )
                    })}
                </Pane>
              ) : (
                <CFView h2 bold fill center>
                  No Categories
                </CFView>
              )
            }
          </CFSelect>
          {selectedCategory ? (
            <CFSelect
              selector={[
                () =>
                  dispatch.restaurant.getProductsInOrder(
                    selectedCategory.id,
                    true
                  ),
              ]}
            >
              {(productList) =>
                Object.values(productList).length > 0 ? (
                  <CFView pl="20px" pr="20px" pb="20px" column>
                    {Object.values(productList).map((product) => {
                      return product.map((prod) => (
                        <CheckSelectButton
                          label={prod.name}
                          ml="20px"
                          selected={selectedProducts.includes(prod.id)}
                          onClick={() => addProductToCopy(prod.id)}
                        />
                      ))
                    })}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Products
                  </CFView>
                )
              }
            </CFSelect>
          ) : null}
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={
                saving || !selectedCategory.id || selectedProducts.length === 0
              }
              onClick={moveProducts}
            />
          </CFView>
        </PrimaryModal>
      </MobileScreen>
    </>
  )
}
