import React, { useState, useEffect } from 'react'
import { CFSelect, CFView, PrimaryButton, PrimaryModal } from 'components'
import { Checkbox, Select, TextInputField } from 'evergreen-ui'
import { dispatch } from 'store'
import { sortBy, omit } from 'lodash'

const initialState = {
  productId: '$5 OFF',
  points: '',
  imageUrl: '',
  isFirstReward: false,
}

const DISCOUNTS = {
  '$5 OFF': 5,
  '$10 OFF': 10,
  '$15 OFF': 15,
  '$20 OFF': 20,
  '$25 OFF': 25,
  '$50 OFF': 50,
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.reward) {
      const isFirstReward =
        props.reward.type === 'FreeFirstOrderOrPoints/Product' ||
        props.reward.type === 'FreeFirstOrderOrPoints/Discount'
      setFields({
        ...initialState,
        ...props.reward,
        isFirstReward,
        productId: props.reward.productId,
      })
    }
  }, [props.reward])

  const updateReward = async () => {
    if (!isFormValid()) {
      return
    }
    setSaving(true)
    try {
      const { productId } = fields
      let rewards = dispatch.restaurant.getRewards()
      if (props.reward) {
        if (props.reward.productId !== productId) {
          rewards = omit(rewards, props.reward.productId)
        }
        rewards[productId] = generateRewardObject()
      } else {
        rewards = {
          ...dispatch.restaurant.getRewards(),
          [productId]: generateRewardObject(),
        }
      }
      await dispatch.dashboard.updateRewards({ rewards })
      dispatch.notification.setMessage({
        message: `Reward saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error saving reward.')
    } finally {
      setSaving(false)
    }
  }

  const generateRewardObject = () => {
    const { productId, points, isFirstReward, imageUrl } = fields
    const reward = { points: Number(points), imageUrl }
    if (DISCOUNTS[productId] !== undefined) {
      if (isFirstReward) {
        reward.type = 'FreeFirstOrderOrPoints/Discount'
      } else {
        reward.type = 'Points/Discount'
      }
      reward.value = DISCOUNTS[productId]
    } else {
      if (isFirstReward) {
        reward.type = 'FreeFirstOrderOrPoints/Product'
      } else {
        reward.type = 'Points/Product'
      }
      reward.value = productId
    }
    return reward
  }

  const isFormValid = () => {
    const { points } = fields
    if (isNaN(Number(points))) {
      alert('Points must be a number.')
      return false
    }
    if (points === '' || points <= 0) {
      alert('Please enter valid points.')
      return false
    }
    if (points > 10000) {
      alert('Reward cannot cost more than 10000.')
      return false
    }
    return true
  }

  return (
    <PrimaryModal
      title={props.reward ? `Edit ${props.reward.name}` : 'Create Reward'}
      {...props}
    >
      <CFView column p="20px">
        <CFSelect selector={dispatch.restaurant.getProducts}>
          {(products) => (
            <CFView>
              <Select
                label="Rewards List"
                marginBottom={15}
                value={fields.productId}
                onChange={(e) =>
                  setFields({ ...fields, productId: e.target.value })
                }
              >
                {Object.keys(DISCOUNTS).map((discount) => (
                  <option value={discount}>{discount}</option>
                ))}
                {sortBy(Object.values(products), (product) =>
                  product.name.toLowerCase()
                ).map((product) => (
                  <option value={product.id}>{product.name}</option>
                ))}
              </Select>
            </CFView>
          )}
        </CFSelect>
        <TextInputField
          placeholder="Please Enter Points..."
          label="Points"
          width="100%"
          marginBottom={15}
          value={fields.points}
          onChange={(e) => setFields({ ...fields, points: e.target.value })}
        />
        <TextInputField
          placeholder="Please Enter Image Url..."
          label="Image Url"
          width="100%"
          marginBottom={15}
          value={fields.imageUrl}
          onChange={(e) => setFields({ ...fields, imageUrl: e.target.value })}
        />
        <CFView h="10px" />
        <Checkbox
          label="Make This A First Order Reward?"
          marginTop={10}
          onChange={(e) =>
            setFields({
              ...fields,
              isFirstReward: e.target.checked,
            })
          }
          checked={fields.isFirstReward}
        />
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateReward}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
