import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BackButton, Card, CFImage, CFSelect, CFView } from 'components'
import { AddIcon, Button, DeleteIcon, EditIcon } from 'evergreen-ui'
import RewardModal from './RewardModal'
import { dispatch } from 'store'
import { omit, sortBy } from 'lodash'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [showModal, setShowModal] = useState('')
  const [reward, setReward] = useState('')
  const [saving, setSaving] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations = dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showRewardModal = (reward) => {
    setShowModal('reward')
    setReward(reward)
  }

  const closeModal = () => {
    setShowModal('')
    setReward('')
  }

  const deleteReward = async (productId) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setSaving(productId)
      try {
        const rewards = omit(dispatch.restaurant.getRewards(), productId)
        await dispatch.dashboard.updateRewards({ rewards })
        dispatch.notification.setMessage({
          message: `Reward deleted!`,
          level: 'success',
        })
      } catch (e) {
        alert('Error deleting reward.')
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <CFView h="100%" p="20px" center>
      <CFView column>
        <BackButton onClick={history.goBack} />
        <Card
          h="calc(100vh - 148px)"
          w="1080px"
          mt="10px"
          column
          overflowY="auto"
        >
          <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
            <CFView h1 bold>
              Rewards
            </CFView>
            <CFView row>
              <Button
                intent="danger"
                iconBefore={AddIcon}
                height={40}
                onClick={() => setShowModal('reward')}
              >
                Create Reward
              </Button>
            </CFView>
          </CFView>
          <CFSelect
            selector={[
              dispatch.restaurant.getRewards,
              dispatch.restaurant.getProducts,
            ]}
          >
            {([rewards, products]) =>
              Object.keys(rewards).length > 0 ? (
                <CFView p="20px" column>
                  {sortBy(
                    Object.keys(rewards).map((productId) => ({
                      productId,
                      ...rewards[productId],
                    })),
                    ['points']
                  ).map((reward) => {
                    const { type, points, imageUrl, productId } = reward
                    return (
                      <Card
                        p="10px"
                        mb="10px"
                        row
                        justifyBetween
                        key={productId}
                      >
                        <CFView row>
                          {imageUrl && (
                            <CFImage
                              src={imageUrl}
                              cover
                              w="150px"
                              h="150px"
                              mr="20px"
                              alt="Product"
                              br="5px"
                              boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                            />
                          )}
                          <CFView column>
                            <CFView fontSize={14}>{`Name: ${
                              products[productId]
                                ? products[productId].name
                                : productId
                            }`}</CFView>
                            <CFView fontSize={14}>{`Points: ${points}`}</CFView>
                            {(type === 'FreeFirstOrderOrPoints/Product' ||
                              type === 'FreeFirstOrderOrPoints/Discount') && (
                              <CFView>
                                <CFView
                                  fontSize={14}
                                >{`First Order: True`}</CFView>
                              </CFView>
                            )}
                          </CFView>
                        </CFView>
                        <CFView column>
                          <Button
                            width={170}
                            marginBottom={10}
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={40}
                            onClick={() =>
                              showRewardModal({
                                productId,
                                name: products[productId]
                                  ? products[productId].name
                                  : productId,
                                ...reward,
                              })
                            }
                          >
                            Edit Reward
                          </Button>
                          <Button
                            width={170}
                            marginBottom={10}
                            appearance="primary"
                            intent="danger"
                            disabled={saving === productId}
                            iconBefore={DeleteIcon}
                            height={40}
                            onClick={() => deleteReward(productId)}
                          >
                            {saving === productId ? 'Deleting' : 'Delete'}
                          </Button>
                        </CFView>
                      </Card>
                    )
                  })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Rewards
                </CFView>
              )
            }
          </CFSelect>
        </Card>
      </CFView>
      <RewardModal
        isOpen={showModal === 'reward'}
        reward={reward}
        onRequestClose={closeModal}
      />
    </CFView>
  )
}
