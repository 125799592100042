import React from 'react'
import {
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import { TextInputField } from 'evergreen-ui'
import { dispatch } from 'store'
import { useState } from 'react'

export default (props) => {
  const [adjustPriceValue, setAdjustPriceValue] = useState(0)
  const [saving, setSaving] = useState(false)

  const adjustPrice = async () => {
    setSaving(true)
    try {
      const categoriesIds = dispatch.restaurant.getCategoryOrder(props.menuId)
      const newCategoryList = await Promise.all(
        categoriesIds.map(async (id) => {
          const productList = await dispatch.restaurant.getProductsInOrder(
            id,
            true
          )
          return productList.map((item) => ({
            ...item,
            price: item.price * adjustPriceValue,
          }))
        })
      )

      for (const productList of newCategoryList) {
        for (const productDetails of productList) {
          await dispatch.dashboard.updateProduct({
            productId: productDetails.id,
            productDetails,
          })
        }
      }
      props.onRequestClose()
    } catch (error) {
      console.error('Error during adjust price for products:', error)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <DefaultScreen>
        <PrimaryModal w="400px" title="Adjust Price" {...props}>
          <CFView p="10px">
            <TextInputField
              type="number"
              width="100%"
              label="Name"
              value={adjustPriceValue}
              onChange={(e) => setAdjustPriceValue(e.target.value)}
            />
            <CFView p="20px">
              <PrimaryButton
                label={saving ? 'SAVING...' : 'SAVE'}
                disabled={saving}
                onClick={adjustPrice}
              />
            </CFView>
          </CFView>
        </PrimaryModal>
      </DefaultScreen>
      <MobileScreen>
        <PrimaryModal w="300px" title="Adjust Price" {...props}>
          <CFView p="10px">
            <TextInputField
              type="number"
              width="100%"
              label="Name"
              value={adjustPriceValue}
              onChange={(e) => setAdjustPriceValue(e.target.value)}
            />
            <CFView p="20px">
              <PrimaryButton
                label={saving ? 'SAVING...' : 'SAVE'}
                disabled={saving}
                onClick={adjustPrice}
              />
            </CFView>
          </CFView>
        </PrimaryModal>
      </MobileScreen>
    </>
  )
}
