import React, { useState, useEffect } from 'react'
import {
  CFView,
  DefaultScreen,
  MobileScreen,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import { TimePicker } from '@material-ui/pickers'
import { IconButton, SegmentedControl, TrashIcon } from 'evergreen-ui'
import { dispatch } from 'store'

const initialState = {
  0: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
  1: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
  2: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
  3: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
  4: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
  5: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
  6: {
    open: 0,
    close: 0,
    breakStart: 0,
    breakEnd: 0,
  },
}

const DAYS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export default (props) => {
  const [type, setType] = useState('open')
  const [hours, setHours] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.location) {
      setHours(props.location.hours)
    }
  }, [props.location])

  const changeHours = async (time, day, event) => {
    hours[day][event] = time.valueOf()
    setHours({ ...hours })
  }

  const changeType = (type) => {
    if (type === 'delivery') {
      if (props.location.deliveryHours) {
        setHours(props.location.deliveryHours)
      } else {
        setHours({
          0: {
            open: 0,
            close: 0,
          },
          1: {
            open: 0,
            close: 0,
          },
          2: {
            open: 0,
            close: 0,
          },
          3: {
            open: 0,
            close: 0,
          },
          4: {
            open: 0,
            close: 0,
          },
          5: {
            open: 0,
            close: 0,
          },
          6: {
            open: 0,
            close: 0,
          },
        })
      }
    } else {
      setHours(props.location.hours)
    }
    setType(type)
  }

  const clearHours = (day, type) => {
    if (type === 'break') {
      hours[day] = { ...hours[day], breakStart: null, breakEnd: null }
    } else {
      hours[day] = { ...hours[day], open: null, close: null }
    }
    setHours({ ...hours })
  }

  const updateHours = async () => {
    setSaving(true)
    try {
      dispatch.restaurant.setLocationId(props.location.id)
      if (type === 'delivery') {
        await dispatch.dashboard.updateDeliveryHours(hours)
      } else {
        await dispatch.dashboard.updateHours(hours)
      }
      dispatch.notification.setMessage({
        message: `Hours saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setHours(initialState)
      setType('open')
    } catch (e) {
      alert('Error saving hours.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal
      title={`Edit ${props.location.locationName} Hours`}
      {...props}
    >
      <DefaultScreen>
        <CFView p="20px" column minWidth="650px">
          {props.location.deliveryEnabled ? (
            <SegmentedControl
              width={240}
              options={[
                { label: 'Open', value: 'open' },
                { label: 'Break', value: 'break' },
                { label: 'Delivery', value: 'delivery' },
              ]}
              value={type}
              onChange={changeType}
            />
          ) : (
            <SegmentedControl
              width={240}
              options={[
                { label: 'Open', value: 'open' },
                { label: 'Break', value: 'break' },
              ]}
              value={type}
              onChange={changeType}
            />
          )}
          <CFView h="10px" />
          {[1, 2, 3, 4, 5, 6, 0].map((day) =>
            type === 'break' ? (
              <CFView row alignCenter mb="10px">
                <CFView w="120px">{DAYS[day]}</CFView>
                <TimePicker
                  label="Start"
                  value={hours[day].breakStart}
                  onChange={(time) => changeHours(time, day, 'breakStart')}
                />
                <CFView mh="20px">To</CFView>
                <TimePicker
                  label="End"
                  value={hours[day].breakEnd}
                  onChange={(time) => changeHours(time, day, 'breakEnd')}
                />
                <PrimaryButton
                  label="Clear"
                  ml="10px"
                  onClick={() => clearHours(day, type)}
                />
              </CFView>
            ) : (
              <CFView row alignCenter mb="10px">
                <CFView w="120px">{DAYS[day]}</CFView>
                <TimePicker
                  label="Open"
                  value={hours[day].open}
                  onChange={(time) => changeHours(time, day, 'open')}
                />
                <CFView mh="20px">To</CFView>
                <TimePicker
                  label="Close"
                  value={hours[day].close}
                  onChange={(time) => changeHours(time, day, 'close')}
                />
                <PrimaryButton
                  label="Clear"
                  ml="10px"
                  onClick={() => clearHours(day, type)}
                />
              </CFView>
            )
          )}
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateHours}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView p="20px" column center minWidth="320px">
          {props.location.deliveryEnabled ? (
            <SegmentedControl
              width="100%"
              options={[
                { label: 'Open', value: 'open' },
                { label: 'Break', value: 'break' },
                { label: 'Delivery', value: 'delivery' },
              ]}
              value={type}
              onChange={changeType}
            />
          ) : (
            <SegmentedControl
              width="100%"
              options={[
                { label: 'Open', value: 'open' },
                { label: 'Break', value: 'break' },
              ]}
              value={type}
              onChange={changeType}
            />
          )}
          <CFView h="10px" />
          {[1, 2, 3, 4, 5, 6, 0].map((day) =>
            type === 'break' ? (
              <CFView column justifyStart mb="10px">
                <CFView w="120px">{DAYS[day]}</CFView>
                <CFView row mb="20px">
                  <TimePicker
                    label="Start"
                    value={hours[day].breakStart}
                    onChange={(time) => changeHours(time, day, 'breakStart')}
                  />
                  <CFView mh="10px" mt="10px">
                    To
                  </CFView>
                  <TimePicker
                    label="End"
                    value={hours[day].breakEnd}
                    onChange={(time) => changeHours(time, day, 'breakEnd')}
                  />
                  <IconButton
                    icon={TrashIcon}
                    marginLeft={10}
                    intent="danger"
                    height={40}
                    onClick={() => clearHours(day, type)}
                  />
                </CFView>
              </CFView>
            ) : (
              <CFView column justifyStart mb="10px">
                <CFView w="120px">{DAYS[day]}</CFView>
                <CFView row mb="20px">
                  <TimePicker
                    label="Open"
                    value={hours[day].open}
                    onChange={(time) => changeHours(time, day, 'open')}
                  />
                  <CFView mh="10px" mt="10px">
                    To
                  </CFView>
                  <TimePicker
                    label="Close"
                    value={hours[day].close}
                    onChange={(time) => changeHours(time, day, 'close')}
                  />
                  <IconButton
                    icon={TrashIcon}
                    marginLeft={10}
                    intent="danger"
                    height={40}
                    onClick={() => clearHours(day, type)}
                  />
                </CFView>
              </CFView>
            )
          )}
        </CFView>
        <CFView pb="20px" w="80%" m="0 auto">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateHours}
          />
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
