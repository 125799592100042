import React from 'react'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

export default (props) => {
  const copyMenu = (menuId) => {
    dispatch.dashboard.copyMenu({
      restaurantId: props.restaurantId,
      locationId: props.locationId,
      menuId1: menuId,
      menuId2: props.menuId,
    })
    dispatch.notification.setMessage({
      message: `Menu copied!`,
      level: 'success',
    })
    props.onRequestClose()
  }

  return (
    <PrimaryModal title="Copy Menu" {...props}>
      <CFView column justifyCenter w="400px" p="20px">
        {props.menuId !== 'default' && (
          <CFView row justifyBetween alignCenter mb="10px">
            <CFView>Dinner</CFView>
            <PrimaryButton label="Copy" onClick={() => copyMenu('default')} />
          </CFView>
        )}
        {props.menuId !== 'menu1' && (
          <CFView row justifyBetween alignCenter mb="10px">
            <CFView>Lunch</CFView>
            <PrimaryButton label="Copy" onClick={() => copyMenu('menu1')} />
          </CFView>
        )}
        {props.menuId !== 'menu2' && (
          <CFView row justifyBetween alignCenter mb="10px">
            <CFView>Happy Hours</CFView>
            <PrimaryButton label="Copy" onClick={() => copyMenu('menu2')} />
          </CFView>
        )}
      </CFView>
    </PrimaryModal>
  )
}
