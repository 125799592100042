import React from 'react'
import { CFView } from 'components'

export default ({ title, LeftButton, RightButton, ...props }) => {
  return (
    <CFView
      h="50px"
      w="100%"
      bg="white"
      boxShadow="0 1px 3px rgba(0,0,0,.8)"
      row
      relative
      alignCenter
      {...props}
    >
      <CFView absolute left={0} ml="5px">
        {LeftButton}
      </CFView>
      <CFView center fill>
        <CFView h3 bold truncate maxWidth="280px" color="black">
          {title}
        </CFView>
      </CFView>
      <CFView absolute right={0} mr="5px">
        {RightButton}
      </CFView>
    </CFView>
  )
}
