import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  AddButton,
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import HoursModal from './HoursModal'
import MessageModal from './MessageModal'
import LocationModal from './LocationModal'
import {
  AddIcon,
  Button,
  ChatIcon,
  ClipboardIcon,
  EditIcon,
  TagIcon,
  TimeIcon,
  TimelineLineChartIcon,
  StarIcon,
  UserIcon,
  SquareIcon,
  CodeIcon,
} from 'evergreen-ui'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const { restaurantId } = useParams()
  const [showModal, setShowModal] = useState('')
  const [location, setLocation] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
    }
    const unsubscribe = dispatch.restaurant.subscribeRestaurant()
    return () => unsubscribe()
  }, [restaurantId])

  const showModalType = (type, location) => {
    setShowModal(type)
    setLocation(location)
  }

  const closeModal = () => {
    setShowModal('')
    setLocation('')
  }

  const navigateOrders = (locationId) => {
    history.push(`/restaurants/${restaurantId}/locations/${locationId}/orders`)
  }

  const navigateMenus = (locationId) => {
    history.push(`/restaurants/${restaurantId}/locations/${locationId}/menus`)
  }

  const navigateRewards = (locationId) => {
    history.push(`/restaurants/${restaurantId}/locations/${locationId}/rewards`)
  }

  const navigatePromoCodes = (locationId) => {
    history.push(
      `/restaurants/${restaurantId}/locations/${locationId}/promoCodes`
    )
  }

  const navigateTables = (locationId) => {
    history.push(`/restaurants/${restaurantId}/locations/${locationId}/tables`)
  }

  const navigateStaff = (locationId) => {
    history.push(`/restaurants/${restaurantId}/locations/${locationId}/staff`)
  }

  const navigateAnalytics = (locationId) => {
    history.push(
      `/restaurants/${restaurantId}/locations/${locationId}/analytics`
    )
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" column center>
          <CFView w="1080px" row justifyStart>
            <BackButton onClick={history.goBack} />
          </CFView>
          <Card
            h="calc(100vh - 148px)"
            w="100vw"
            maxWidth="1080px"
            mt="10px"
            column
            overflowY="auto"
          >
            <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
              <CFView h1 bold>
                Locations
              </CFView>
              {dispatch.dashboard.getRole() === 'super_admin' && (
                <Button
                  intent="danger"
                  iconBefore={AddIcon}
                  height={40}
                  onClick={() => setShowModal('location')}
                >
                  Create Location
                </Button>
              )}
            </CFView>
            <CFSelect selector={dispatch.restaurant.getLocations}>
              {(locations) =>
                Object.values(locations).length > 0 ? (
                  <CFView p="20px" column>
                    {Object.values(locations)
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1
                        }
                        if (a.name > b.name) {
                          return 1
                        }
                        return 0
                      })
                      .map((location) => (
                        <Card mb="10px" column justifyBetween key={location.id}>
                          <CFView
                            bg="#43464B"
                            color="white"
                            bold
                            row
                            p="10px"
                            mb="5px"
                          >
                            {location.locationName}
                          </CFView>
                          <CFView row justifyBetween p="10px">
                            <CFView column>
                              <CFView
                                fontSize={11}
                              >{`Address: ${location.address}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Phone Number: ${location.phoneNumber}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Email: ${location.invoiceEmail}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Store Open: ${location.orderOpen}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Open: ${location.deliveryOpen}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Enabled: ${location.deliveryEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Payment Types: ${location.paymentMethodType}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Dine-in Payment Types: ${location.dineInPaymentMethodType}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Wait Time: ${location.waitTime}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Fee: ${location.deliveryFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Time: ${location.deliveryTime}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Min Order Delivery: ${location.minOrder}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`First Order Discount: ${location.firstOrderDiscount}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Pickup Discount: ${location.pickupDiscount}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Stripe Account Id: ${location.stripeAccountId}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Stripe Customer Id: ${location.stripeCustomerId}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Google Maps Url: ${location.googleMapsUrl}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Invoice Number: ${location.invoiceNumber}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Dine-in Enabled: ${location.dineInEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Rewards Enabled: ${location.rewardsEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Printing Enabled: ${location.printingEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Dine-in Commission: ${location.dineInCommissionEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Guest Ordering Enabled: ${location.guestOrderingEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Door Dash Enabled: ${location.doordashEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Online Payment Only: ${location.deliveryOnlinePaymentOnly}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Schedule Payout: ${location.schedulePayout}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Transfer Fee: ${location.transferSubscriptionFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Commission Fee: ${location.commissionFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Stripe Fee: ${location.stripeFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Subscription Fee: ${location.subscriptionFee}`}</CFView>
                            </CFView>
                            <CFView column>
                              {dispatch.dashboard.getRole() !==
                                'menu_admin' && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={ClipboardIcon}
                                    height={40}
                                    onClick={() => navigateOrders(location.id)}
                                  >
                                    View Orders
                                  </Button>
                                </CFView>
                              )}
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={TagIcon}
                                  height={40}
                                  onClick={() => navigateMenus(location.id)}
                                >
                                  View Menus
                                </Button>
                              </CFView>
                              {location.rewardsEnabled && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={StarIcon}
                                    height={40}
                                    onClick={() => navigateRewards(location.id)}
                                  >
                                    View Rewards
                                  </Button>
                                </CFView>
                              )}
                              {location.promoCodesEnabled && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={CodeIcon}
                                    height={40}
                                    onClick={() =>
                                      navigatePromoCodes(location.id)
                                    }
                                  >
                                    View Promos
                                  </Button>
                                </CFView>
                              )}
                              {location.dineInEnabled && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={SquareIcon}
                                    height={40}
                                    onClick={() => navigateTables(location.id)}
                                  >
                                    View Tables
                                  </Button>
                                </CFView>
                              )}
                              {location.dineInEnabled && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={UserIcon}
                                    height={40}
                                    onClick={() => navigateStaff(location.id)}
                                  >
                                    View Staff
                                  </Button>
                                </CFView>
                              )}
                              {dispatch.dashboard.getRole() !==
                                'menu_admin' && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={TimelineLineChartIcon}
                                    height={40}
                                    onClick={() =>
                                      navigateAnalytics(location.id)
                                    }
                                  >
                                    View Analytics
                                  </Button>
                                </CFView>
                              )}
                              {dispatch.dashboard.getRole() !==
                                'menu_admin' && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={ChatIcon}
                                    height={40}
                                    onClick={() =>
                                      showModalType('message', location)
                                    }
                                  >
                                    Edit Message
                                  </Button>
                                </CFView>
                              )}
                              {dispatch.dashboard.getRole() !==
                                'menu_admin' && (
                                <CFView mb="12px">
                                  <Button
                                    width={170}
                                    appearance="primary"
                                    iconBefore={TimeIcon}
                                    height={40}
                                    onClick={() =>
                                      showModalType('hours', location)
                                    }
                                  >
                                    Business Hours
                                  </Button>
                                </CFView>
                              )}
                              {dispatch.dashboard.getRole() !==
                                'menu_admin' && (
                                <Button
                                  width={170}
                                  appearance="primary"
                                  intent="warning"
                                  iconBefore={EditIcon}
                                  height={40}
                                  onClick={() =>
                                    showModalType('location', location)
                                  }
                                >
                                  Edit Location
                                </Button>
                              )}
                            </CFView>
                          </CFView>
                        </Card>
                      ))}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Locations
                  </CFView>
                )
              }
            </CFSelect>
          </Card>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="Locations"
          LeftButton={<BackButton onClick={history.goBack} />}
          RightButton={
            dispatch.dashboard.getRole() === 'super_admin' ? (
              <AddButton onClick={() => setShowModal('location')} />
            ) : null
          }
        />
        <CFView
          h="calc(100vh - 110px)"
          column
          overflowY="auto"
          minWidth="320px"
        >
          <CFSelect selector={dispatch.restaurant.getLocations}>
            {(locations) =>
              Object.values(locations).length > 0 ? (
                <CFView p="10px" column>
                  {Object.values(locations)
                    .sort((a, b) => {
                      if (a.locationName < b.locationName) {
                        return -1
                      }
                      if (a.locationName > b.locationName) {
                        return 1
                      }
                      return 0
                    })
                    .map((location) => (
                      <Card
                        mb="10px"
                        column
                        justifyBetween
                        key={location.id}
                        w="100%"
                      >
                        <CFView
                          bg="#43464B"
                          color="white"
                          bold
                          row
                          center
                          p="10px"
                          mb="5px"
                        >
                          {location.locationName}
                        </CFView>
                        <CFView column center p="10px">
                          <CFView column textCenter>
                            <CFView
                              fontSize={16}
                              mb="8px"
                            >{`Address: ${location.address}`}</CFView>
                            <CFView
                              fontSize={16}
                              mb="8px"
                            >{`Phone Number: ${location.phoneNumber}`}</CFView>{' '}
                            <CFView
                              fontSize={16}
                              mb="8px"
                            >{`Store Open: ${location.orderOpen}`}</CFView>
                            {/* <CFView
                                fontSize={11}
                              >{`Email: ${location.invoiceEmail}`}</CFView>

                              <CFView
                                fontSize={11}
                              >{`Delivery Open: ${location.deliveryOpen}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Enabled: ${location.deliveryEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Payment Types: ${location.paymentMethodType}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Dine-in Payment Types: ${location.dineInPaymentMethodType}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Wait Time: ${location.waitTime}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Fee: ${location.deliveryFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Delivery Time: ${location.deliveryTime}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Min Order Delivery: ${location.minOrder}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`First Order Discount: ${location.firstOrderDiscount}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Pickup Discount: ${location.pickupDiscount}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Stripe Account Id: ${location.stripeAccountId}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Stripe Customer Id: ${location.stripeCustomerId}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Google Maps Url: ${location.googleMapsUrl}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Invoice Number: ${location.invoiceNumber}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Dine-in Enabled: ${location.dineInEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Rewards Enabled: ${location.rewardsEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Printing Enabled: ${location.printingEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Dine-in Commission: ${location.dineInCommissionEnabled}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Schedule Payout: ${location.schedulePayout}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Transfer Fee: ${location.transferSubscriptionFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Commission Fee: ${location.commissionFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Stripe Fee: ${location.stripeFee}`}</CFView>
                              <CFView
                                fontSize={11}
                              >{`Subscription Fee: ${location.subscriptionFee}`}</CFView> */}
                          </CFView>
                          <CFView column>
                            {dispatch.dashboard.getRole() !== 'menu_admin' && (
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={ClipboardIcon}
                                  height={40}
                                  onClick={() => navigateOrders(location.id)}
                                >
                                  View Orders
                                </Button>
                              </CFView>
                            )}
                            <CFView mb="12px">
                              <Button
                                width={170}
                                appearance="primary"
                                iconBefore={TagIcon}
                                height={40}
                                onClick={() => navigateMenus(location.id)}
                              >
                                View Menus
                              </Button>
                            </CFView>
                            {location.rewardsEnabled && (
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={StarIcon}
                                  height={40}
                                  onClick={() => navigateRewards(location.id)}
                                >
                                  View Rewards
                                </Button>
                              </CFView>
                            )}
                            {location.dineInEnabled && (
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={UserIcon}
                                  height={40}
                                  onClick={() => navigateStaff(location.id)}
                                >
                                  View Staff
                                </Button>
                              </CFView>
                            )}
                            {dispatch.dashboard.getRole() !== 'menu_admin' && (
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={TimelineLineChartIcon}
                                  height={40}
                                  onClick={() => navigateAnalytics(location.id)}
                                >
                                  View Analytics
                                </Button>
                              </CFView>
                            )}
                            {dispatch.dashboard.getRole() !== 'menu_admin' && (
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={ChatIcon}
                                  height={40}
                                  onClick={() =>
                                    showModalType('message', location)
                                  }
                                >
                                  Edit Message
                                </Button>
                              </CFView>
                            )}
                            {dispatch.dashboard.getRole() !== 'menu_admin' && (
                              <CFView mb="12px">
                                <Button
                                  width={170}
                                  appearance="primary"
                                  iconBefore={TimeIcon}
                                  height={40}
                                  onClick={() =>
                                    showModalType('hours', location)
                                  }
                                >
                                  Business Hours
                                </Button>
                              </CFView>
                            )}
                            {dispatch.dashboard.getRole() !== 'menu_admin' && (
                              <Button
                                width={170}
                                appearance="primary"
                                intent="warning"
                                iconBefore={EditIcon}
                                height={40}
                                onClick={() =>
                                  showModalType('location', location)
                                }
                              >
                                Edit Location
                              </Button>
                            )}
                          </CFView>
                        </CFView>
                      </Card>
                    ))}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Locations
                </CFView>
              )
            }
          </CFSelect>
        </CFView>
      </MobileScreen>
      <HoursModal
        isOpen={showModal === 'hours'}
        restaurantId={restaurantId}
        location={location}
        onRequestClose={closeModal}
      />
      <MessageModal
        isOpen={showModal === 'message'}
        restaurantId={restaurantId}
        location={location}
        onRequestClose={closeModal}
      />
      <LocationModal
        isOpen={showModal === 'location'}
        restaurantId={restaurantId}
        location={location}
        onRequestClose={closeModal}
      />
    </>
  )
}
