import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ExportToCsv } from 'export-to-csv'
import {
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import GenerateModal from './GenerateModal'
import CopyMenuModal from './CopyMenuModal'
import CopyLocationMenusModal from './CopyLocationMenusModal'
import ImportModal from './ImportModal'
import MenuModal from './MenuModal'
import PdfMenuModal from './PdfMenuModal'
import MoveCategoriesModal from './MoveCategoriesModal'
import AdjustPriceModal from './AdjustPriceModal'
import {
  AddIcon,
  DuplicateIcon,
  Button,
  ExportIcon,
  EditIcon,
  ThIcon,
  ImportIcon,
  TagIcon,
  CircleArrowRightIcon,
  DocumentIcon,
} from 'evergreen-ui'
import { dispatch } from 'store'
import { compact } from 'lodash'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [showModal, setShowModal] = useState('')
  const [menu, setMenu] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showGenerateModal = (menu) => {
    setShowModal('generate')
    setMenu(menu)
  }

  const showMenuModal = (menu) => {
    setShowModal('menu')
    setMenu(menu)
  }

  const showCopyModal = (menu) => {
    setShowModal('copy')
    setMenu(menu)
  }

  const showCopyCategoriesModal = (menu) => {
    setShowModal('copyCategories')
    setMenu(menu)
  }

  const showAdjustPriceModal = (menu) => {
    setShowModal('adjustPrice')
    setMenu(menu)
  }

  const showPdfMenuModal = (menu) => {
    setShowModal('pdfMenu')
    setMenu(menu)
  }

  const closeModal = () => {
    setShowModal('')
    setMenu('')
  }

  const navigateCategories = (menuId) => {
    dispatch.dashboard.getRole() === 'admin'
      ? history.push(`/menus/${menuId}/categories`)
      : history.push(
          `/restaurants/${restaurantId}/locations/${locationId}/menus/${menuId}/categories`
        )
  }

  const exportProductsCSV = () => {
    const productsData = Object.values(
      dispatch.restaurant.getProducts()
    ).reduce((prev, product) => {
      const {
        id = '',
        name = '',
        description = '',
        price = 0,
        taxRate = 0.05,
        imageUrl = '',
        orderType = 'all',
        posId = '',
        posName = '',
      } = product
      prev.push({
        id,
        name,
        description,
        price,
        taxRate,
        imageUrl,
        orderType,
        posId,
        posName,
      })
      return prev
    }, [])
    const csvExporter = new ExportToCsv({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    })
    csvExporter.generateCsv(productsData)
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            {dispatch.dashboard.getRole() !== 'admin' && (
              <BackButton mb="10px" onClick={history.goBack} />
            )}
            <Card
              h={
                dispatch.dashboard.getRole() !== 'admin'
                  ? 'calc(100vh - 148px)'
                  : 'calc(100vh - 110px)'
              }
              w="100vw"
              maxWidth="1080px"
              minWidth="700px"
              column
              overflowY="auto"
            >
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Menus
                </CFView>
                <CFView row>
                  {dispatch.dashboard.getRole() === 'admin' && (
                    <CFSelect selector={dispatch.restaurant.getRewardsEnabled}>
                      <CFView ml="10px">
                        <Button
                          intent="danger"
                          iconBefore={ThIcon}
                          height={40}
                          onClick={() => history.push('/menus/rewards')}
                        >
                          View Rewards
                        </Button>
                      </CFView>
                    </CFSelect>
                  )}
                  {dispatch.dashboard.getRole() === 'super_admin' && (
                    <CFSelect selectorNot={dispatch.restaurant.getCategories}>
                      <CFView ml="10px">
                        <Button
                          intent="danger"
                          iconBefore={DuplicateIcon}
                          height={40}
                          onClick={() => setShowModal('copyLocation')}
                        >
                          Copy Menu
                        </Button>
                      </CFView>
                    </CFSelect>
                  )}
                  <CFView ml="10px">
                    <Button
                      intent="danger"
                      iconBefore={DocumentIcon}
                      height={40}
                      onClick={showPdfMenuModal}
                    >
                      Upload PDF Menu
                    </Button>
                  </CFView>
                  {dispatch.dashboard.getRole() === 'super_admin' && (
                    <CFView ml="10px">
                      <Button
                        ml="10px"
                        intent="danger"
                        iconBefore={ImportIcon}
                        height={40}
                        onClick={() => setShowModal('import')}
                      >
                        Import Menus
                      </Button>
                    </CFView>
                  )}
                  <CFView w="10px" />
                  <Button
                    intent="danger"
                    iconBefore={ExportIcon}
                    height={40}
                    onClick={exportProductsCSV}
                  >
                    Export Menus
                  </Button>
                  <CFSelect selector={dispatch.restaurant.getMenus}>
                    {(menus) =>
                      Object.keys(menus).length < 10 ? (
                        <CFView ml="10px">
                          <Button
                            intent="danger"
                            iconBefore={AddIcon}
                            height={40}
                            onClick={() => setShowModal('menu')}
                          >
                            Create Menu
                          </Button>
                        </CFView>
                      ) : null
                    }
                  </CFSelect>
                </CFView>
              </CFView>
              <CFSelect selector={dispatch.restaurant.getMenus}>
                {(menus) =>
                  Object.keys(menus).length > 0 ? (
                    <CFView p="20px" column>
                      {compact([
                        menus.default
                          ? { ...menus.default, id: 'default' }
                          : '',
                        menus.menu1 ? { ...menus.menu1, id: 'menu1' } : '',
                        menus.menu2 ? { ...menus.menu2, id: 'menu2' } : '',
                        menus.menu3 ? { ...menus.menu3, id: 'menu3' } : '',
                        menus.menu4 ? { ...menus.menu4, id: 'menu4' } : '',
                        menus.menu5 ? { ...menus.menu5, id: 'menu5' } : '',
                        menus.menu6 ? { ...menus.menu6, id: 'menu6' } : '',
                        menus.menu7 ? { ...menus.menu7, id: 'menu7' } : '',
                        menus.menu8 ? { ...menus.menu8, id: 'menu8' } : '',
                        menus.menu9 ? { ...menus.menu9, id: 'menu9' } : '',
                      ]).map((menu, index) => {
                        return (
                          <Card p="10px" mb="10px" row justifyBetween>
                            <CFView>{menu.name}</CFView>
                            <CFView column>
                              {dispatch.dashboard.getRole() !== 'admin' &&
                                (!menu.categoryOrder ||
                                  menu.categoryOrder.length === 0) && (
                                  <Button
                                    marginBottom={10}
                                    width={175}
                                    appearance="primary"
                                    iconBefore={AddIcon}
                                    height={40}
                                    onClick={() => showGenerateModal(menu)}
                                  >
                                    Generate New
                                  </Button>
                                )}
                              {dispatch.dashboard.getRole() ===
                                'super_admin' && (
                                <Button
                                  marginBottom={10}
                                  width={175}
                                  appearance="primary"
                                  iconBefore={DuplicateIcon}
                                  height={40}
                                  onClick={() => showCopyModal(menu)}
                                >
                                  Copy Menu
                                </Button>
                              )}
                              <Button
                                marginBottom={10}
                                width={175}
                                appearance="primary"
                                iconBefore={ThIcon}
                                height={40}
                                onClick={() => navigateCategories(menu.id)}
                              >
                                View Categories
                              </Button>
                              <Button
                                marginBottom={10}
                                width={175}
                                appearance="primary"
                                iconBefore={CircleArrowRightIcon}
                                height={40}
                                onClick={() => showCopyCategoriesModal(menu)}
                              >
                                Move Categories
                              </Button>
                              {dispatch.dashboard.getRole() ===
                                'super_admin' && (
                                <Button
                                  width={175}
                                  marginBottom={10}
                                  appearance="primary"
                                  iconBefore={TagIcon}
                                  height={40}
                                  onClick={() => showAdjustPriceModal(menu)}
                                >
                                  Adjust Prices
                                </Button>
                              )}
                              <Button
                                marginBottom={10}
                                width={175}
                                intent="warning"
                                appearance="primary"
                                iconBefore={EditIcon}
                                height={40}
                                onClick={() => showMenuModal(menu)}
                              >
                                Edit Settings
                              </Button>
                            </CFView>
                          </Card>
                        )
                      })}
                    </CFView>
                  ) : (
                    <CFView h2 bold fill center>
                      No Menus
                    </CFView>
                  )
                }
              </CFSelect>
            </Card>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="Menus"
          LeftButton={
            dispatch.dashboard.getRole() === 'admin' ? null : (
              <BackButton onClick={history.goBack} />
            )
          }
          zIndex={90}
        />
        <CFView h="calc(100vh - 120px)" column center overflowY="auto">
          <CFView column center w="100%">
            <CFView w="80%" pt="10px" row center>
              {dispatch.dashboard.getRole() === 'admin' && (
                <CFSelect selector={dispatch.restaurant.getRewardsEnabled}>
                  <Button
                    intent="danger"
                    marginRight={10}
                    iconBefore={ThIcon}
                    height={40}
                    onClick={() => history.push('/menus/rewards')}
                  >
                    Rewards
                  </Button>
                </CFSelect>
              )}
            </CFView>
            <CFSelect selector={dispatch.restaurant.getMenus}>
              {(menus) =>
                Object.keys(menus).length > 0 ? (
                  <CFView p="20px" column w="100%">
                    {compact([
                      menus.default ? { ...menus.default, id: 'default' } : '',
                      menus.menu1 ? { ...menus.menu1, id: 'menu1' } : '',
                      menus.menu2 ? { ...menus.menu2, id: 'menu2' } : '',
                      menus.menu3 ? { ...menus.menu3, id: 'menu3' } : '',
                      menus.menu4 ? { ...menus.menu4, id: 'menu4' } : '',
                      menus.menu5 ? { ...menus.menu5, id: 'menu5' } : '',
                      menus.menu6 ? { ...menus.menu6, id: 'menu6' } : '',
                      menus.menu7 ? { ...menus.menu7, id: 'menu7' } : '',
                      menus.menu8 ? { ...menus.menu8, id: 'menu8' } : '',
                      menus.menu9 ? { ...menus.menu9, id: 'menu9' } : '',
                    ]).map((menu, index) => {
                      return (
                        <Card mb="15px" column alignCenter justifyBetween>
                          <CFView
                            textCenter
                            bg="#43464B"
                            w="100%"
                            white
                            xBold
                            p="5px"
                          >
                            {menu.name}
                          </CFView>
                          <CFView p="10px" column>
                            {dispatch.dashboard.getRole() !== 'admin' &&
                              (!menu.categoryOrder ||
                                menu.categoryOrder.length === 0) && (
                                <Button
                                  marginBottom={10}
                                  width="{175}"
                                  appearance="primary"
                                  iconBefore={AddIcon}
                                  height={40}
                                  onClick={() => showGenerateModal(menu)}
                                >
                                  Generate New
                                </Button>
                              )}
                            <Button
                              marginBottom={10}
                              width={175}
                              appearance="primary"
                              iconBefore={ThIcon}
                              height={40}
                              onClick={() => navigateCategories(menu.id)}
                            >
                              View Categories
                            </Button>
                            <Button
                              marginBottom={10}
                              width={175}
                              appearance="primary"
                              iconBefore={CircleArrowRightIcon}
                              height={40}
                              onClick={() => showCopyCategoriesModal(menu)}
                            >
                              Move Categories
                            </Button>
                            {dispatch.dashboard.getRole() === 'super_admin' && (
                              <Button
                                width={175}
                                marginBottom={10}
                                appearance="primary"
                                iconBefore={TagIcon}
                                height={40}
                                onClick={() => showAdjustPriceModal(menu)}
                              >
                                Adjust Prices
                              </Button>
                            )}
                            <Button
                              width={175}
                              intent="warning"
                              appearance="primary"
                              iconBefore={EditIcon}
                              height={40}
                              onClick={() => showMenuModal(menu)}
                            >
                              Edit Settings
                            </Button>
                          </CFView>
                        </Card>
                      )
                    })}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Menus
                  </CFView>
                )
              }
            </CFSelect>
          </CFView>
        </CFView>
      </MobileScreen>
      <GenerateModal
        isOpen={showModal === 'generate'}
        menu={menu}
        onRequestClose={closeModal}
      />
      <CopyMenuModal
        isOpen={showModal === 'copy'}
        restaurantId={restaurantId}
        locationId={locationId}
        menuId={menu.id}
        onRequestClose={closeModal}
      />
      <AdjustPriceModal
        isOpen={showModal === 'adjustPrice'}
        restaurantId={restaurantId}
        locationId={locationId}
        menuId={menu.id}
        onRequestClose={closeModal}
      />
      <MoveCategoriesModal
        isOpen={showModal === 'copyCategories'}
        restaurantId={restaurantId}
        locationId={locationId}
        menuId={menu.id}
        onRequestClose={closeModal}
      />
      <CopyLocationMenusModal
        isOpen={showModal === 'copyLocation'}
        restaurantId={restaurantId}
        locationId={locationId}
        onRequestClose={closeModal}
      />
      <ImportModal
        isOpen={showModal === 'import'}
        onRequestClose={closeModal}
      />
      <MenuModal
        isOpen={showModal === 'menu'}
        menu={menu}
        onRequestClose={closeModal}
      />
      <CFSelect selector={dispatch.restaurant.getPdfMenuUrl}>
        {(pdfMenuUrl) => (
          <PdfMenuModal
            isOpen={showModal === 'pdfMenu'}
            pdfMenuUrl={pdfMenuUrl}
            onRequestClose={closeModal}
          />
        )}
      </CFSelect>
    </>
  )
}
