import { db } from 'cf-core/src/config/firebase'
import { moment } from 'cf-utils'
import { get, clamp, filter, omit, sortBy, isEmpty } from 'lodash'
import * as api from '../api'
import userActions from './userActions'

const DEFAULT_STATE = {
  id: '',
  email: '',
  restaurantId: '',
  locationId: '',
  loading: true,
  isLoggingIn: false,
  role: '',
  restaurantIds: [],
  Orders: {},
  Restaurants: {},
  Users: {},
  Staff: {},
}

const allPrevSeenOrderIds = {}

const orderStatusMessages = {
  Preparing: {
    title: 'Preparing Order',
    body: 'Your order is now being prepared!',
  },
  Done: {
    title: 'Order Done',
    body: 'Your order is done! Thank you!',
  },
  Cancelled: {
    title: 'Order Cancelled',
    body: 'Your order has been cancelled and refunded.',
  },
}

export default (serverUrl) => ({
  state: DEFAULT_STATE,
  reducers: {
    setUser: (state, user) => ({
      ...state,
      ...user,
    }),
    unsetUser: () => DEFAULT_STATE,
    setRestaurants: (state, Restaurants) => ({
      ...state,
      Restaurants,
    }),
    setOrders: (state, Orders) => ({
      ...state,
      Orders,
    }),
    setRequests: (state, Requests) => ({
      ...state,
      Requests,
    }),
    setUsers: (state, Users) => ({
      ...state,
      Users,
    }),
    setStaff: (state, Staff) => ({
      ...state,
      Staff,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setIsLoggingIn: (state, isLoggingIn) => ({
      ...state,
      isLoggingIn,
    }),
    setRole: (state, role) => ({
      ...state,
      role,
    }),
    setRestaurantIds: (state, restaurantIds) => ({
      ...state,
      restaurantIds,
    }),
    setStatistics: (state, statistics) => ({
      ...state,
      statistics,
    }),
  },
  actions: ({ dispatch, getState }) => ({
    ...userActions({ dispatch, getState, serverUrl }),
    getRestaurants() {
      return getState().dashboard.Restaurants
    },
    getOrders() {
      return getState().dashboard.Orders
    },
    getRole() {
      return getState().dashboard.role
    },
    getRestaurantIds() {
      return getState().dashboard.restaurantIds
    },
    getNewOrderCount() {
      const orders = filter(getState().dashboard.Orders, { status: 'New' })
      return Object.keys(orders).length
    },
    getPreparingOrderCount() {
      const orders = filter(getState().dashboard.Orders, {
        status: 'Preparing',
      })
      return Object.keys(orders).length
    },
    getRequests() {
      return getState().dashboard.Requests
    },
    getRequestCount() {
      return Object.keys(getState().dashboard.Requests).length
    },
    getRequestsInOrder() {
      return sortBy(
        Object.values(dispatch.dashboard.getRequests()),
        'createdAt'
      )
    },
    getUsers() {
      return getState().dashboard.Users
    },
    getStaff() {
      return getState().dashboard.Staff
    },
    getIsLoading() {
      return getState().dashboard.loading
    },
    getOrdersRange({ fromDate, toDate }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.orders.getOrderRange({
        restaurantId,
        locationId,
        fromDate,
        toDate,
      })
    },
    getLocations(restaurantId) {
      return api.restaurant.fetchLocations(restaurantId)
    },
    getNewUsersRange({ fromDate, toDate }) {
      return new Promise((resolve, reject) => {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        db.collection('Users')
          .where(
            `restaurants.${restaurantId}.createdAt`,
            '>',
            fromDate.valueOf()
          )
          .where(`restaurants.${restaurantId}.createdAt`, '<', toDate.valueOf())
          .get()
          .then((docs) => {
            const users = {}
            docs.forEach((doc) => {
              const user = doc.data()
              users[doc.id] = user
              users[doc.id].id = doc.id
            })
            resolve(users)
          })
      })
    },
    getActiveUsersRange({ fromDate, toDate }) {
      return new Promise((resolve, reject) => {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        db.collection('Users')
          .where(
            `restaurants.${restaurantId}.lastLogin`,
            '>',
            fromDate.valueOf()
          )
          .where(`restaurants.${restaurantId}.lastLogin`, '<', toDate.valueOf())
          .get()
          .then((docs) => {
            const users = {}
            docs.forEach((doc) => {
              const user = doc.data()
              users[doc.id] = user
              users[doc.id].id = doc.id
            })
            resolve(users)
          })
      })
    },
    getStatistics() {
      return getState().dashboard.statistics
    },
    getAddedCharges({ orderId }) {
      return get(dispatch.dashboard.getOrders()[orderId], 'addedCharges', [])
    },
    getSubTotal({ orderId }) {
      return get(dispatch.dashboard.getOrders()[orderId], 'subTotal', 0)
    },
    getIsLoggingIn() {
      return getState().dashboard.isLoggingIn
    },
    uploadImage({ fileName, imageUpload }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return new Promise((resolve, reject) => {
        const filePath = imageUpload.path
        api.restaurant
          .uploadImage(restaurantId, fileName, filePath)
          .then(({ downloadURL }) => {
            resolve(downloadURL)
          })
          .catch(reject)
      })
    },
    async addCharge({ order, name, price }) {
      if (!name || !price) {
        throw new Error('Please enter a name and price')
      }
      const addedCharge = {
        order,
        price: Number(price),
        name,
      }
      const authToken = await dispatch.dashboard.getUserToken()
      const { paymentMethod = null } = order
      if (paymentMethod) {
        return await api.server.addChargeToOrderV2(
          serverUrl,
          authToken,
          addedCharge
        )
      } else {
        return await api.server.addChargeToOrder(
          serverUrl,
          authToken,
          addedCharge
        )
      }
    },
    async refundOrder({
      orderId,
      cancelReason = '',
      refundDescription = '',
      refundAmount = 0,
    }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const authToken = await dispatch.dashboard.getUserToken()
      return await api.server.refundOrderAndCharge(serverUrl, authToken, {
        restaurantId,
        orderId,
        cancelReason,
        refundDescription,
        refundAmount,
      })
    },
    async sendInvoiceToEmail(invoiceDetails) {
      const authToken = await dispatch.dashboard.getUserToken()
      return api.server.sendInvoiceToEmail(serverUrl, authToken, invoiceDetails)
    },
    createProduct({ categoryId, productDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const productOrder = dispatch.restaurant.getProductOrder(categoryId)
      return api.restaurant
        .createProduct(restaurantId, locationId, productDetails)
        .then((docRef) => {
          return api.restaurant
            .updateCategory(restaurantId, locationId, categoryId, {
              productOrder: [...productOrder, docRef.id],
            })
            .then(() => {
              return docRef.id
            })
        })
    },
    updateProduct({ productId, productDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateProduct(
        restaurantId,
        locationId,
        productId,
        productDetails
      )
    },
    updateCategoryOrder({ menuId, categories }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateCategoryOrder(
        restaurantId,
        locationId,
        menuId,
        categories
      )
    },
    updateProductOrder({ categoryId, products }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateProductOrder(
        restaurantId,
        locationId,
        categoryId,
        products
      )
    },
    createCategory({ menuId, category }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const categoryOrder = dispatch.restaurant.getCategoryOrder(menuId) || []
      return api.restaurant
        .createCategory(restaurantId, locationId, category)
        .then((docRef) => {
          const menus = dispatch.restaurant.getMenus()
          if (menus[menuId]) {
            menus[menuId].categoryOrder = [...categoryOrder, docRef.id]
          } else {
            menus[menuId] = {
              categoryOrder: [...categoryOrder, docRef.id],
            }
          }
          dispatch.dashboard.updateMenus(menus)
          return docRef.id
        })
    },
    updateCategory({ categoryId, categoryDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateCategory(
        restaurantId,
        locationId,
        categoryId,
        categoryDetails
      )
    },
    async deleteCategory({ menuId, categoryId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const productOrder = dispatch.restaurant.getProductOrder(categoryId)
      let rewards = dispatch.restaurant.getRewards()
      productOrder &&
        productOrder.forEach((productId) => {
          if (rewards[productId]) {
            rewards = omit(rewards, productId)
          }
          dispatch.dashboard.deleteProduct({ productId })
        })
      dispatch.dashboard.updateRewards({ rewards })
      const categoryOrder = dispatch.restaurant.getCategoryOrder(menuId)
      const menus = dispatch.restaurant.getMenus()
      const newCategoryOrder = filter(categoryOrder, (id) => id !== categoryId)
      menus[menuId].categoryOrder = newCategoryOrder
      dispatch.dashboard.updateMenus(menus)
      return await api.restaurant.deleteCategory(
        restaurantId,
        locationId,
        categoryId
      )
    },
    createModifier({ modifierDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant
        .createModifier(restaurantId, locationId, modifierDetails)
        .then((docRef) => {
          return docRef.id
        })
    },
    updateModifier({ modifierId, modifierDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateModifier(
        restaurantId,
        locationId,
        modifierId,
        modifierDetails
      )
    },
    createUser({ user }) {
      return api.users.createUser({ user })
    },
    updateUser({ userId, user }) {
      return api.users.updateUser({ userId, user })
    },
    deleteUser({ userId }) {
      return api.users.deleteUser({ userId })
    },
    createTable({ tableDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.createTable(restaurantId, locationId, tableDetails)
    },
    updateTable({ tableId, tableDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateTable(
        restaurantId,
        locationId,
        tableId,
        tableDetails
      )
    },
    deleteTable({ tableId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.deleteTable(restaurantId, locationId, tableId)
    },
    updateOrderStatus({ restaurantId, orderId, userId, order }) {
      return api.orders
        .updateOrder({
          restaurantId,
          orderId,
          order,
        })
        .then(() => {
          dispatch.dashboard.getUserToken().then((authToken) => {
            if (
              order.status === 'Preparing' ||
              order.status === 'Done' ||
              order.status === 'Cancelled'
            ) {
              const message = orderStatusMessages[order.status]
              message.userIds = [userId]
              api.server.createNotificationWithFCM(
                serverUrl,
                authToken,
                message
              )
            }
          })
        })
    },
    updateWaitTime(waitTime) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        waitTime,
      })
    },
    async updateWaitTimeAndSendMessage(addedTime, order) {
      const { id: orderId, orderNumber, orderType, name, phoneNumber } = order
      const restaurantName = dispatch.restaurant.getName()
      const restaurantPhoneNumber = dispatch.restaurant.getPhoneNumber()
      const completedAt = moment(order.completedAt).add(addedTime, 'm')
      const readyTime = completedAt.format('LT')
      const message = {
        body: `Hello ${name}! ${restaurantName} has added ${addedTime} to the ${orderType} Time for Order #${orderNumber}. The order should now be ${
          orderType === 'Pickup' ? 'picked up' : 'delivered'
        } at ${readyTime}. If this is a problem, please call the ${restaurantName} directly at ${restaurantPhoneNumber}. Do NOT reply to this message.`,
      }
      const data = {
        phoneNumber,
        message,
      }
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const authToken = await dispatch.dashboard.getUserToken()
      api.server.createNotificationWithFCM(serverUrl, authToken, message)
      api.orders.updateOrder({
        restaurantId,
        orderId,
        order: {
          ...order,
          completedAt,
        },
      })
      return api.server.createMessageWithTwilio(serverUrl, authToken, data)
    },
    updateTipValues(tipValues) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        tipValues,
      })
    },
    updateHours(hours) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, { hours })
    },
    updateDeliveryHours(deliveryHours) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        deliveryHours,
      })
    },
    updateOrderOpen(orderOpen) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        orderOpen,
      })
    },
    updateDeliveryOpen(deliveryOpen) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        deliveryOpen,
      })
    },
    updateMenus(menus) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, { menus })
    },
    updateWelcomeMessage(welcomeMessage) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        welcomeMessage,
      })
    },
    deleteOrder({ orderId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      return api.orders.deleteOrder({ restaurantId, orderId })
    },
    changeCategoryOrder({ menuId, fromIndex, toIndex }) {
      const categoryOrder = dispatch.restaurant.getCategoryOrder(menuId)
      const toIndexClamped = clamp(toIndex, 0, categoryOrder.length - 1)
      if (toIndexClamped === fromIndex) {
        return
      }
      const newCategoryOrder = []
      if (fromIndex > toIndexClamped) {
        for (let i = 0; i < toIndexClamped; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
        newCategoryOrder.push(categoryOrder[fromIndex])
        for (let i = toIndexClamped; i < categoryOrder.length; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
      } else {
        for (let i = 0; i < toIndexClamped + 1; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
        newCategoryOrder.push(categoryOrder[fromIndex])
        for (let i = toIndexClamped + 1; i < categoryOrder.length; i++) {
          if (i !== fromIndex) {
            newCategoryOrder.push(categoryOrder[i])
          }
        }
      }
      const menus = dispatch.restaurant.getMenus()
      menus[menuId].categoryOrder = newCategoryOrder
      return dispatch.dashboard.updateMenus(menus)
    },
    updateRewards({ rewards }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        rewards,
      })
    },
    updatePromoCodes({ promoCodes }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        promoCodes,
      })
    },
    updatePasscode({ passcode }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateLocation(restaurantId, locationId, {
        passcode,
      })
    },
    updateRequest(reqeustId, request) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      return api.restaurant.updateRequest(
        restaurantId,
        locationId,
        reqeustId,
        request
      )
    },
    changeProductOrder({ categoryId, fromIndex, toIndex }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const productOrder = dispatch.restaurant.getProductOrder(categoryId)
      const toIndexClamped = clamp(toIndex, 0, productOrder.length - 1)
      if (toIndexClamped === fromIndex) {
        return
      }

      const newProductOrder = []
      if (fromIndex > toIndexClamped) {
        for (let i = 0; i < toIndexClamped; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
        newProductOrder.push(productOrder[fromIndex])
        for (let i = toIndexClamped; i < productOrder.length; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
      } else {
        for (let i = 0; i < toIndexClamped + 1; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
        newProductOrder.push(productOrder[fromIndex])
        for (let i = toIndexClamped + 1; i < productOrder.length; i++) {
          if (i !== fromIndex) {
            newProductOrder.push(productOrder[i])
          }
        }
      }

      return api.restaurant.updateCategory(
        restaurantId,
        locationId,
        categoryId,
        {
          productOrder: newProductOrder,
        }
      )
    },
    async addProductOption({ productId, productOptionDetails }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const docRef = await api.restaurant.addProductOption(
        restaurantId,
        locationId,
        productOptionDetails
      )
      const options = dispatch.restaurant.getProductOptions(productId)
      const newOptions = options ? [...options, docRef.id] : [docRef.id]
      await api.restaurant.updateProduct(restaurantId, locationId, productId, {
        options: newOptions,
      })
      return docRef.id
    },
    async deleteProduct({ productId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const categoryId =
        dispatch.restaurant.getProductDetails(productId).categoryId
      try {
        const options = dispatch.restaurant.getProductOptions(productId)
        const productOrder = dispatch.restaurant.getProductOrder(categoryId)
        const newProductOrder = filter(productOrder, (id) => id !== productId)
        api.restaurant.updateCategory(restaurantId, locationId, categoryId, {
          productOrder: newProductOrder,
        })
        if (options && options.length > 0) {
          for (let i = 0; i < options.length; i++) {
            api.restaurant.deleteProduct(restaurantId, locationId, options[i])
          }
        }
        await api.restaurant.deleteProduct(restaurantId, locationId, productId)
      } catch (error) {
        console.warn(error)
      }
    },
    async deleteProductOption({ productId, productOptionId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      try {
        const options = dispatch.restaurant.getProductOptions(productId)
        const removedOptions = filter(options, (id) => id !== productOptionId)
        await api.restaurant.updateProduct(
          restaurantId,
          locationId,
          productId,
          {
            options: removedOptions,
          }
        )
        await api.restaurant.deleteProduct(
          restaurantId,
          locationId,
          productOptionId
        )
      } catch (error) {
        console.warn(error)
      }
    },
    async addProductModifier({ productId, modifierId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const modifiers = dispatch.restaurant.getProductModifiers(productId)
      const newModifiers = modifiers ? [...modifiers, modifierId] : [modifierId]
      await api.restaurant.updateProduct(restaurantId, locationId, productId, {
        modifiers: newModifiers,
      })
    },
    async removeProductModifier({ productId, modifierId }) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const modifiers = dispatch.restaurant.getProductModifiers(productId)
      const removedModifiers = filter(modifiers, (id) => id !== modifierId)
      await api.restaurant.updateProduct(restaurantId, locationId, productId, {
        modifiers: removedModifiers,
      })
    },
    updateUserTables(userId, tables) {
      api.users.updateUser({ userId, user: { tables } })
    },
    async sendUserNotifications() {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const docs = await db
        .collection('Users')
        .where(`restaurants.${restaurantId}.createdAt`, '>', 0)
        .orderBy(`restaurants.${restaurantId}.createdAt`)
        .get()

      const userTokens = []
      docs.forEach((doc) => {
        const user = doc.data()
        if (user.notificationToken) {
          userTokens.push(user.notificationToken)
        }
      })
      const message = ''
      message.userIds = userTokens
      const authToken = await dispatch.dashboard.getUserToken()
      api.server.createNotificationWithFCM(serverUrl, authToken, message)
    },
    copyMenu({ restaurantId, locationId, menuId1, menuId2 }) {
      const locationRef = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)

      locationRef.get().then((locationDoc) => {
        const location = locationDoc.data()
        let categoryOrder = []
        let categoryOrderCount = 0
        location.menus[menuId1].categoryOrder.forEach((categoryId) => {
          locationRef
            .collection('Categories')
            .doc(categoryId)
            .get()
            .then((categoryDoc) => {
              const categoryDetails = categoryDoc.data()
              locationRef
                .collection('Categories')
                .add(categoryDetails)
                .then((menuCategoryDoc) => {
                  categoryOrder.push(menuCategoryDoc.id)
                  categoryOrderCount++
                  if (
                    categoryOrderCount ===
                    location.menus[menuId1].categoryOrder.length
                  ) {
                    locationRef.update({
                      [`menus.${menuId2}.categoryOrder`]: categoryOrder,
                    })
                  }
                  let productOrder = []
                  let productOrderCount = 0
                  categoryDetails.productOrder.forEach((productId) => {
                    locationRef
                      .collection('Products')
                      .doc(productId)
                      .get()
                      .then((productDoc) => {
                        const productDetails = productDoc.data()
                        locationRef
                          .collection('Products')
                          .add({
                            ...productDetails,
                            categoryId: menuCategoryDoc.id,
                          })
                          .then((menuProductDoc) => {
                            productOrder.push(menuProductDoc.id)
                            productOrderCount++
                            if (
                              productOrderCount ===
                              categoryDetails.productOrder.length
                            ) {
                              locationRef
                                .collection('Categories')
                                .doc(menuCategoryDoc.id)
                                .update({ productOrder })
                            }
                          })
                      })
                  })
                })
            })
        })
      })
    },
    copyLocationMenus({ restaurantId, locationId1, locationId2 }) {
      const location1Ref = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId1)
      const location2Ref = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId2)
      location1Ref.get().then((locationDoc) => {
        location1Ref
          .collection('Modifiers')
          .get()
          .then((modifiersRef) => {
            if (modifiersRef.size > 0) {
              modifiersRef.forEach((modifierDoc) => {
                if (!isEmpty(modifierDoc.data())) {
                  location2Ref.collection('Modifiers').add(modifierDoc.data())
                }
              })
            }
          })
        const location = locationDoc.data()
        Object.keys(location.menus).forEach((menuId) => {
          let categoryOrder = []
          if (location.menus[menuId].active) {
            let categoryOrderlength =
              location.menus[menuId].categoryOrder.length
            let categoryOrderCount = 0
            location.menus[menuId].categoryOrder.forEach((categoryId) => {
              location1Ref
                .collection('Categories')
                .doc(categoryId)
                .get()
                .then((loc1CategoryDoc) => {
                  if (!isEmpty(loc1CategoryDoc.data())) {
                    const categoryDetails = loc1CategoryDoc.data()
                    delete categoryDetails.productOrder
                    location2Ref
                      .collection('Categories')
                      .add(categoryDetails)
                      .then((loc2CategoryDoc) => {
                        categoryOrder.push(loc2CategoryDoc.id)
                        categoryOrderCount++
                        if (categoryOrderCount === categoryOrderlength) {
                          location2Ref.update({
                            [`menus.${menuId}.categoryOrder`]: categoryOrder,
                          })
                        }
                        let productOrder = []
                        let productOrderLength =
                          loc1CategoryDoc.data().productOrder.length
                        let productOrderCount = 0
                        loc1CategoryDoc
                          .data()
                          .productOrder.forEach((productId) => {
                            location1Ref
                              .collection('Products')
                              .doc(productId)
                              .get()
                              .then((productDoc) => {
                                if (!isEmpty(productDoc.data())) {
                                  const productDetails = productDoc.data()
                                  delete productDetails.modifiers
                                  location2Ref
                                    .collection('Products')
                                    .add(productDetails)
                                    .then((productDoc) => {
                                      productOrder.push(productDoc.id)
                                      productOrderCount++
                                      console.log(
                                        `${categoryDetails.name} counter: ${productOrderCount} out of ${productOrderLength}`
                                      )
                                      if (
                                        productOrderCount === productOrderLength
                                      ) {
                                        location2Ref
                                          .collection('Categories')
                                          .doc(loc2CategoryDoc.id)
                                          .update({
                                            productOrder,
                                          })
                                      }
                                    })
                                } else {
                                  console.log(
                                    `${productDoc.data()} data missing`
                                  )
                                  productOrderLength--
                                }
                              })
                          })
                      })
                  } else {
                    categoryOrderlength--
                  }
                })
            })
          }
        })
      })
    },
    async createEmailPromotion(data) {
      const authToken = await dispatch.dashboard.getUserToken()
      return api.server.createEmailPromotion(serverUrl, authToken, data)
    },
    subscribeRestaurants() {
      return db.collection('Restaurants').onSnapshot((snapshot) => {
        const restaurants = {}
        snapshot.forEach((doc) => {
          const restaurantData = doc.data()
          restaurants[doc.id] = restaurantData
          restaurants[doc.id].id = doc.id
        })
        dispatch.dashboard.setRestaurants(restaurants)
      })
    },
    subscribeOrders() {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()

      const startOfYesterday = moment()
        .subtract(2, 'days')
        .startOf('day')
        .valueOf()

      let docRef = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Orders')
        .where('createdAt', '>', startOfYesterday)
        .orderBy('createdAt', 'desc')

      if (locationId) {
        docRef = docRef.where('locationId', '==', locationId)
      }

      return docRef.onSnapshot(
        (snapshot) => {
          const orders = {}
          snapshot.forEach((doc) => {
            const orderData = doc.data()
            if (
              orderData.status === 'Done' ||
              orderData.status === 'Cancelled'
            ) {
              return
            }

            orders[doc.id] = orderData
            orders[doc.id].id = doc.id

            // Check if there is any unseen order
            if (!allPrevSeenOrderIds.hasOwnProperty(doc.id)) {
              allPrevSeenOrderIds[doc.id] = true
            }
          })
          dispatch.dashboard.setOrders(orders)
          dispatch.dashboard.setLoading(false)
        },
        (error) => {
          console.warn(error)
          dispatch.dashboard.setLoading(false)
        }
      )
    },
    subscribeRequests() {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const locationId = dispatch.restaurant.getSelectedLocationId()

      let docRef = db
        .collection('Restaurants')
        .doc(restaurantId)
        .collection('Locations')
        .doc(locationId)
        .collection('Requests')
        .where('confirmed', '==', false)

      return docRef.onSnapshot(
        (snapshot) => {
          const requests = {}
          snapshot.forEach((doc) => {
            const requestData = doc.data()
            requests[doc.id] = requestData
            requests[doc.id].id = doc.id
          })

          const orderCount = dispatch.dashboard.getNewOrderCount()
          if (Object.keys(requests).length === 0 && orderCount === 0) {
            dispatch({ type: 'adminCustom/stopAlert' })
          } else {
            dispatch({ type: 'adminCustom/newOrder' })
          }
          dispatch.dashboard.setRequests(requests)
        },
        (error) => {
          console.warn(error)
        }
      )
    },
    subscribeUsers() {
      let docRef = db
        .collection('Users')
        .where('role', 'in', [
          'admin',
          'menu_admin',
          'int_admin',
          'super_admin',
        ])

      return docRef.onSnapshot(
        (snapshot) => {
          const users = {}
          snapshot.forEach((doc) => {
            const requestData = doc.data()
            users[doc.id] = requestData
            users[doc.id].id = doc.id
          })

          dispatch.dashboard.setUsers(users)
        },
        (error) => {
          console.warn(error)
        }
      )
    },
    subscribeStaff() {
      const locationId = dispatch.restaurant.getSelectedLocationId()
      const docRef = db
        .collection('Users')
        .where('locationId', '==', locationId)
      return docRef.onSnapshot(
        (snapshot) => {
          const staff = {}
          snapshot.forEach((doc) => {
            const requestData = doc.data()
            if (requestData.role === 'server') {
              staff[doc.id] = requestData
              staff[doc.id].id = doc.id
            }
          })

          dispatch.dashboard.setStaff(staff)
        },
        (error) => {
          console.warn(error)
        }
      )
    },
  }),
})
