import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFView,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { AddIcon, Button, EditIcon, TrashIcon } from 'evergreen-ui'
import TableModal from './TableModal'
import { dispatch } from 'store'

export default () => {
  const { restaurantId, locationId } = useParams()
  const history = useHistory()

  const [table, setTable] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [saving, setSaving] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showTableModal = (table) => {
    setShowModal(true)
    setTable(table)
  }

  const closeModal = () => {
    setShowModal('')
    setTable('')
  }

  const deleteTable = async (tableId) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setSaving(tableId)
      try {
        await dispatch.dashboard.deleteTable({ tableId })
        dispatch.notification.setMessage({
          message: 'Table deleted!',
          level: 'success',
        })
      } catch (e) {
        alert(e)
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" column center>
          <CFView column>
            <BackButton onClick={history.goBack} />
            <CFView w="1080px" row justifyStart>
              <Card
                h="calc(100vh - 148px)"
                w="100vw"
                maxWidth="1080px"
                mt="10px"
                column
                overflowY="auto"
              >
                <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                  <CFView h1 bold>
                    Tables
                  </CFView>
                  <Button
                    intent="danger"
                    iconBefore={AddIcon}
                    height={40}
                    onClick={() => setShowModal(true)}
                  >
                    Create Table
                  </Button>
                </CFView>
                <CFSelect selector={dispatch.restaurant.getTables}>
                  {(tables) =>
                    Object.values(tables).length > 0 ? (
                      <CFView p="20px" column>
                        {Object.values(tables).map((table, index) => (
                          <Card
                            p="10px"
                            mb="10px"
                            row
                            justifyBetween
                            key={table.id}
                          >
                            <CFView column>
                              <CFView>{`Table Number: ${table.tableNumber}`}</CFView>
                              <CFView>{`POS Table ID: ${table.posTableId}`}</CFView>
                              <CFView>{`Active: ${table.active}`}</CFView>
                            </CFView>
                            <CFView column>
                              <Button
                                appearance="primary"
                                intent="warning"
                                iconBefore={EditIcon}
                                height={40}
                                marginBottom={10}
                                onClick={() => showTableModal(table)}
                              >
                                Edit Table
                              </Button>
                              <Button
                                appearance="primary"
                                intent="danger"
                                iconBefore={TrashIcon}
                                height={40}
                                disabled={saving === table.id}
                                onClick={() => deleteTable(table.id)}
                              >
                                {saving === table.id
                                  ? 'Deleting'
                                  : 'Delete Table'}
                              </Button>
                            </CFView>
                          </Card>
                        ))}
                      </CFView>
                    ) : (
                      <CFView h2 bold fill center>
                        No Tables
                      </CFView>
                    )
                  }
                </CFSelect>
              </Card>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen></MobileScreen>
      <TableModal
        isOpen={showModal}
        table={table}
        onRequestClose={closeModal}
      />
    </>
  )
}
