import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import { AddIcon, Button, EditIcon, TrashIcon } from 'evergreen-ui'
import StaffModal from './StaffModal'
import TablesModal from './TablesModal'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [user, setUser] = useState('')
  const [showModal, setShowModal] = useState('')
  const [saving, setSaving] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubscribeStaff = dispatch.dashboard.subscribeStaff()
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubscribeStaff()
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showCreateStaffModal = () => {
    if (Object.values(dispatch.dashboard.getStaff()).length >= 5) {
      alert('Number of staff users limited to 5.')
      return
    }
    setShowModal('staff')
    setUser('')
  }

  const showStaffModal = (user) => {
    setShowModal('staff')
    setUser(user)
  }

  const showTablesModal = (user) => {
    setShowModal('tables')
    setUser(user)
  }

  const closeModal = () => {
    setShowModal('')
    setUser('')
  }

  const deleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setSaving(userId)
      try {
        await dispatch.dashboard.unregisterUser(userId)
        dispatch.notification.setMessage({
          message: 'User deleted!',
          level: 'success',
        })
      } catch (e) {
        alert(e)
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            <BackButton mb="10px" onClick={history.goBack} />
            <Card h="calc(100vh - 148px)" w="1080px" column overflowY="auto">
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Staff
                </CFView>
                <Button
                  intent="danger"
                  iconBefore={AddIcon}
                  height={40}
                  onClick={showCreateStaffModal}
                >
                  Create User
                </Button>
              </CFView>
              <CFSelect selector={dispatch.dashboard.getStaff}>
                {(staff) =>
                  Object.values(staff).length > 0 ? (
                    <CFView p="20px" column>
                      {Object.values(staff).map((user, index) => {
                        return (
                          <Card
                            p="10px"
                            mb="10px"
                            row
                            justifyBetween
                            key={user.id}
                          >
                            <CFView column>
                              <CFView>{user.name}</CFView>
                              <CFView>{user.email}</CFView>
                              <CFView>{`Role: ${user.role}`}</CFView>
                            </CFView>
                            <CFSelect selector={dispatch.restaurant.getTables}>
                              {(tables) =>
                                Object.values(tables).length > 0 &&
                                !isEmpty(user.tables) && (
                                  <CFView column>
                                    <CFView>Tables</CFView>
                                    {user.tables.map((tableId) => (
                                      <CFView>
                                        {tables[tableId].tableNumber}
                                      </CFView>
                                    ))}
                                  </CFView>
                                )
                              }
                            </CFSelect>
                            <CFView column>
                              <Button
                                appearance="primary"
                                iconBefore={EditIcon}
                                height={40}
                                marginBottom={10}
                                onClick={() => showTablesModal(user)}
                              >
                                Edit Tables
                              </Button>
                              <Button
                                appearance="primary"
                                intent="warning"
                                iconBefore={EditIcon}
                                height={40}
                                marginBottom={10}
                                onClick={() => showStaffModal(user)}
                              >
                                Edit User
                              </Button>
                              <Button
                                appearance="primary"
                                intent="danger"
                                iconBefore={TrashIcon}
                                height={40}
                                disabled={saving === user.id}
                                onClick={() => deleteUser(user.id)}
                              >
                                {saving === user.id
                                  ? 'Deleting'
                                  : 'Delete User'}
                              </Button>
                            </CFView>
                          </Card>
                        )
                      })}
                    </CFView>
                  ) : (
                    <CFView h2 bold fill center>
                      No Staff
                    </CFView>
                  )
                }
              </CFSelect>
            </Card>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="STAFF"
          LeftButton={<BackButton onClick={history.goBack} />}
          zIndex={90}
        />
        <CFView
          h="calc(100vh - 120px)"
          column
          justifyStart
          alignCenter
          overflowY="auto"
        >
          <CFView column center mv="15px">
            <Button
              intent="danger"
              iconBefore={AddIcon}
              height={48}
              onClick={showCreateStaffModal}
            >
              Create User
            </Button>
          </CFView>
          <CFSelect selector={dispatch.dashboard.getStaff}>
            {(staff) =>
              Object.values(staff).length > 0 ? (
                <CFView ph="20px" column>
                  {Object.values(staff).map((user, index) => {
                    return (
                      <Card
                        p="10px"
                        mb="10px"
                        column
                        alignCenter
                        justifyBetween
                        key={user.id}
                      >
                        <CFView column textCenter>
                          <CFView>{`Email: ${user.email}`}</CFView>
                          <CFView>{`Role: ${user.role}`}</CFView>
                        </CFView>
                        <CFView column m="5px">
                          <Button
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={48}
                            marginBottom={5}
                            onClick={() => showTablesModal(user)}
                          >
                            Edit Tables
                          </Button>
                          <CFView h="10px" />
                          <Button
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={48}
                            marginBottom={5}
                            onClick={() => showStaffModal(user)}
                          >
                            Edit User
                          </Button>
                          <CFView h="10px" />
                          <Button
                            appearance="primary"
                            intent="danger"
                            iconBefore={TrashIcon}
                            height={48}
                            marginBm={5}
                            marginBottom={5}
                            disabled={saving === user.id}
                            onClick={() => deleteUser(user.id)}
                          >
                            {saving === user.id ? 'Deleting' : 'Delete User'}
                          </Button>
                        </CFView>
                      </Card>
                    )
                  })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Staff
                </CFView>
              )
            }
          </CFSelect>
        </CFView>
      </MobileScreen>
      <StaffModal
        isOpen={showModal === 'staff'}
        user={user}
        onRequestClose={closeModal}
      />
      <TablesModal
        isOpen={showModal === 'tables'}
        user={user}
        onRequestClose={closeModal}
      />
    </>
  )
}
