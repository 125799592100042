import React, { useState } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

export default (props) => {
  const [email, setEmail] = useState('')
  const [saving, setSaving] = useState(false)

  const sendInvoiceEmail = async () => {
    if (!email) {
      alert('Email cannot be empty!')
      setSaving(false)
      return
    }
    setSaving(true)
    try {
      const { orders, toDate, fromDate } = props
      const restaurantDoc = await dispatch.restaurant.getRestaurantDoc().get()
      const locationDoc = await dispatch.restaurant
        .getSelectedLocationDoc()
        .get()
      const restaurant = restaurantDoc.data()
      const location = locationDoc.data()
      let {
        commissionFee = 0,
        subscriptionFee = 0,
        stripeFee = 0,
        name,
      } = restaurant
      if (commissionFee === 0) {
        commissionFee = location.commissionFee || 0
      }
      if (subscriptionFee === 0) {
        subscriptionFee = location.subscriptionFee || 0
      }
      if (stripeFee === 0) {
        stripeFee = location.stripeFee || 0
      }
      const { address } = location

      const invoiceDetails = {
        restaurantId: dispatch.restaurant.getRestaurantId(),
        locationId: dispatch.restaurant.getSelectedLocationId(),
        invoiceId: 'Export',
        orders,
        fromDate,
        toDate,
        commissionFee,
        subscriptionFee,
        stripeFee,
        name,
        address,
        invoiceEmail: email,
        invoiceNumber: 0,
      }
      await dispatch.dashboard.sendInvoiceToEmail(invoiceDetails)

      props.onRequestClose()
      setEmail('')
    } catch (e) {
      alert('Error exporting email.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title={'Export Invoice'} {...props}>
      <CFView column maxWidth="400px" minWidth="340px">
        <CFView p="20px" column>
          <LabelInput
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'Exporting...' : 'EXPORT'}
            disabled={saving}
            onClick={sendInvoiceEmail}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
