import React, { useState } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

const initialState = {
  cancelReason: '',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  const cancelOrder = async () => {
    const { cancelReason } = fields
    const { id: orderId } = props.order
    if (!cancelReason) {
      alert('Please enter a cancel reason.')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.refundOrder({
        orderId,
        cancelReason,
      })
      dispatch.notification.setMessage({
        message: `Cancel order successful!`,
        level: 'success',
      })
      props.onRequestClose()
      props.refreshData()
      setFields(initialState)
    } catch (e) {
      alert('Error cancelling order.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Cancel Order" {...props}>
      <CFView column>
        <CFView p="20px" bg="white" row>
          <CFView column>
            <LabelInput
              label="Cancel Reason"
              value={fields.cancelReason}
              onChange={(e) =>
                setFields({ ...fields, cancelReason: e.target.value })
              }
            />
          </CFView>
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={cancelOrder}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
