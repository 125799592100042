import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import { AddIcon, Button, EditIcon } from 'evergreen-ui'
import ModifierModal from './ModifierModal'
import * as utils from 'cf-utils'
import { dispatch } from 'store'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [showModal, setShowModal] = useState('')
  const [modifier, setModifier] = useState('')

  const showModifierModal = (modifier) => {
    setShowModal('modifier')
    setModifier(modifier)
  }

  const closeModal = () => {
    setShowModal('')
    setModifier('')
  }

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            <BackButton onClick={history.goBack} />
            <Card
              h="calc(100vh - 148px)"
              w="100vw"
              maxWidth="1080px"
              minWidth="700px"
              mt="10px"
              column
              overflowY="auto"
            >
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Modifiers
                </CFView>
                <Button
                  intent="danger"
                  iconBefore={AddIcon}
                  height={40}
                  onClick={() => setShowModal('modifier')}
                >
                  Create Modifier
                </Button>
              </CFView>
              <CFSelect selector={dispatch.restaurant.getModifiers}>
                {(modifiers) =>
                  Object.values(modifiers).length > 0 ? (
                    <CFView p="20px" column>
                      {Object.values(modifiers).map((modifier) => (
                        <Card
                          p="10px"
                          mb="10px"
                          row
                          justifyBetween
                          key={modifier.id}
                        >
                          <CFView column>
                            <CFView mb="5px" bold>
                              {modifier.name}
                            </CFView>
                            {modifier.choices &&
                              modifier.choices.length > 0 && (
                                <CFView column>
                                  <CFView
                                    fontSize={16}
                                    mb="5px"
                                    color="rgb(105,105,105)"
                                  >
                                    Choices:
                                  </CFView>
                                  {modifier.choices.map((choice) => (
                                    <CFView
                                      w="100%"
                                      ml="20px"
                                      row
                                      justiyStart
                                      alignCenter
                                      fontSize={16}
                                      mb="5px"
                                      color="rgb(105,105,105)"
                                      key={choice}
                                    >
                                      • {choice.name}
                                      {choice.activeAfter >
                                        utils.moment().valueOf() && (
                                        <CFView color="red" bold h6 ml="5px">
                                          (SOLD OUT)
                                        </CFView>
                                      )}
                                    </CFView>
                                  ))}
                                </CFView>
                              )}
                          </CFView>
                          <Button
                            width={170}
                            marginBottom={10}
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={40}
                            onClick={() => showModifierModal(modifier)}
                          >
                            Edit Modifier
                          </Button>
                        </Card>
                      ))}
                    </CFView>
                  ) : (
                    <CFView h2 bold fill center>
                      No Modifiers
                    </CFView>
                  )
                }
              </CFSelect>
            </Card>
          </CFView>
          <ModifierModal
            isOpen={showModal === 'modifier'}
            modifier={modifier}
            onRequestClose={closeModal}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="MODIFIERS"
          LeftButton={<BackButton onClick={history.goBack} />}
        />
        <CFView
          h="calc(100vh - 120px)"
          column
          overflowY="auto"
          minWidth="320px"
          zIndex={99}
        >
          <CFView column overflowY="auto" justifyStart alignCenter>
            <CFView p="20px" w="100%" column center bg="rgba(0,0,0,.3)">
              <Button
                intent="danger"
                iconBefore={AddIcon}
                height={40}
                onClick={() => setShowModal('modifier')}
              >
                Create Modifier
              </Button>
            </CFView>
            <CFSelect selector={dispatch.restaurant.getModifiers}>
              {(modifiers) =>
                Object.values(modifiers).length > 0 ? (
                  <CFView pv="20px" column center w="100%">
                    {Object.values(modifiers).map((modifier) => (
                      <Card key={modifier.id} minWidth="320px" mb="10px">
                        <CFView column center w="100%">
                          <CFView column center w="100%">
                            <CFView
                              bg="#43464B"
                              p="10px"
                              w="100%"
                              white
                              xBold
                              column
                              center
                            >
                              {modifier.name}
                            </CFView>
                            {modifier.choices &&
                              modifier.choices.length > 0 && (
                                <CFView column mv="5px">
                                  <CFView fontSize={16}>Choices:</CFView>
                                  {modifier.choices.map((choice) => (
                                    <CFView
                                      w="100%"
                                      ml="20px"
                                      row
                                      justiyStart
                                      alignCenter
                                      fontSize={16}
                                      mb="5px"
                                      color="rgb(105,105,105)"
                                      key={choice}
                                    >
                                      • {choice.name}
                                      {choice.activeAfter >
                                        utils.moment().valueOf() && (
                                        <CFView color="red" bold h6 ml="5px">
                                          (SOLD OUT)
                                        </CFView>
                                      )}
                                    </CFView>
                                  ))}
                                </CFView>
                              )}
                          </CFView>
                          <Button
                            width={170}
                            marginBottom={10}
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={40}
                            onClick={() => showModifierModal(modifier)}
                          >
                            Edit Modifier
                          </Button>
                        </CFView>
                      </Card>
                    ))}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Modifiers
                  </CFView>
                )
              }
            </CFSelect>
          </CFView>
          <ModifierModal
            isOpen={showModal === 'modifier'}
            modifier={modifier}
            onRequestClose={closeModal}
          />
        </CFView>
      </MobileScreen>
    </>
  )
}
