import React, { useState, useEffect } from 'react'
import {
  CFView,
  CheckSelectButton,
  PrimaryButton,
  PrimaryModal,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { dispatch } from 'store'
import * as utils from 'cf-utils'
import { FilePicker, SelectField, TextInputField } from 'evergreen-ui'
import { isEmpty, trim } from 'lodash'
import Compressor from 'compressorjs'
import { storageRef } from 'cf-core/src/config/firebase'

const initialState = {
  activeAfter: utils.moment().year(2000).valueOf(),
  description: '',
  imageUrl: '',
  name: '',
  orderType: 'all',
  posId: '',
  posName: '',
  price: '0',
  taxRate: '0.05',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  useEffect(() => {
    if (props.product) {
      setFields({ ...initialState, ...props.product })
    }
  }, [props.product])

  const updateProduct = async () => {
    if (!isFormValid()) {
      return
    }
    setSaving(true)
    const {
      activeAfter = utils.moment().year(2000).valueOf(),
      description,
      imageUrl,
      name,
      orderType,
      posId,
      posName,
      price,
      taxRate = '0.05',
    } = fields
    const { categoryId } = props
    const productDetails = {
      activeAfter,
      description: trim(description),
      imageUrl,
      name: trim(name),
      orderType,
      posId,
      posName,
      price: utils.parseCurrency(price),
      taxRate: Number(taxRate),
      categoryId,
    }
    try {
      if (props.product) {
        await dispatch.dashboard.updateProduct({
          productId: props.product.id,
          productDetails,
        })
      } else {
        await dispatch.dashboard.createProduct({
          categoryId,
          productDetails,
        })
      }
      dispatch.notification.setMessage({
        message: `Product saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error saving product.')
    } finally {
      setSaving(false)
    }
  }

  const isFormValid = () => {
    const { name, price, taxRate, orderType, posId } = fields
    if (name === '') {
      alert('Please enter a valid Product Name')
      return false
    }
    if (isNaN(Number(price))) {
      alert('Price must be a number')
      return false
    }
    if (price === '' || price < 0) {
      alert('Please enter a valid price')
      return false
    }
    if (isNaN(Number(taxRate))) {
      alert('Tax Rate must be a number')
      return false
    }
    if (taxRate === '' || taxRate < 0) {
      alert('Please enter a valid price')
      return false
    }
    if (
      dispatch.restaurant.getIntegrationId() === 'ini' &&
      (isEmpty(posId) || isNaN(Number(posId)))
    ) {
      alert('Please enter a valid POS ID. Contact INI for help.')
      return false
    }
    if (
      orderType !== 'all' &&
      orderType !== 'dine-in' &&
      orderType !== 'dine-out'
    ) {
      alert('Order Type must be either dine-in or dine-out or all.')
      return false
    }
    return true
  }

  const onFileUpload = (file) => {
    const newFile = file[0]
    if (!newFile) {
      alert('Please choose a file first!')
    }
    const width = 800
    new Compressor(newFile, {
      quality: 0.2,
      width: width,
      height: width * (2 / 3),
      resize: 'cover',
      convertSize: 61440,

      success(result) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const locationId = dispatch.restaurant.getSelectedLocationId()
        const timestamp = new Date().getTime().toString()
        const { categoryId } = props
        const fileUrl = `/restaurants/${restaurantId}/locations/${locationId}/categories/${categoryId}/products/${timestamp}_${result.name}`
        const fileRef = storageRef.child(fileUrl)
        setLoadingFile(true)
        fileRef
          .put(result)
          .then(() => {
            console.log('File uploaded successfully')
            setLoadingFile(false)
            fileRef
              .getDownloadURL()
              .then((downloadURL) => {
                setFields({ ...fields, imageUrl: downloadURL })
              })
              .catch((error) => {
                console.error('Error getting download URL:', error)
              })
          })
          .catch((error) => {
            setLoadingFile(false)
            console.error('Error uploading file:', error)
          })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  return (
    <PrimaryModal
      title={props.product ? `Edit ${props.product.name}` : 'Create Product'}
      {...props}
    >
      <DefaultScreen>
        <CFView column minWidth="500px" w="100%">
          <CFView p="20px" bg="white" column w="100%">
            <TextInputField
              width="100%"
              label="Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <TextInputField
              width="100%"
              label="Image URL"
              value={fields.imageUrl}
              onChange={(e) =>
                setFields({ ...fields, imageUrl: e.target.value })
              }
            />
            <FilePicker
              multiple={false}
              width="100%"
              disabled={loadingFile}
              onChange={onFileUpload}
              placeholder="Select the file here!"
            />
            <CFView h="20px" />
            <TextInputField
              width="100%"
              label="Description"
              value={fields.description}
              onChange={(e) =>
                setFields({ ...fields, description: e.target.value })
              }
            />
            <CFView row w="100%">
              <TextInputField
                width="100%"
                label="Price"
                value={fields.price}
                onChange={(e) =>
                  setFields({ ...fields, price: e.target.value })
                }
              />
              <CFView w="20px" />
              <TextInputField
                width="100%"
                label="Tax Rate"
                value={fields.taxRate}
                onChange={(e) =>
                  setFields({ ...fields, taxRate: e.target.value })
                }
              />
            </CFView>
            {dispatch.restaurant.getIntegrationId() === 'ini' && (
              <CFView row w="100%">
                <TextInputField
                  width="100%"
                  label="POS ID / Item ID"
                  value={fields.posId}
                  onChange={(e) =>
                    setFields({ ...fields, posId: e.target.value })
                  }
                />
                <CFView w="20px" />
                <TextInputField
                  width="100%"
                  label="POS Name / Size ID"
                  value={fields.posName}
                  onChange={(e) =>
                    setFields({ ...fields, posName: e.target.value })
                  }
                />
              </CFView>
            )}
            <SelectField
              width="100%"
              label="Order Type"
              value={fields.orderType}
              onChange={(e) =>
                setFields({ ...fields, orderType: e.target.value })
              }
            >
              <option value="dine-in">Dine-in Only</option>
              <option value="dine-out">Take-Out Only</option>
              <option value="all" selected>
                All
              </option>
            </SelectField>
          </CFView>
          <CheckSelectButton
            w="225px"
            ml="20px"
            label="Sold Out"
            onClick={() =>
              setFields({
                ...fields,
                activeAfter:
                  fields.activeAfter > utils.moment().valueOf()
                    ? utils.moment().year(2000).valueOf()
                    : utils.moment().year(3000).valueOf(),
              })
            }
            selected={fields.activeAfter > utils.moment().valueOf()}
          />
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving || loadingFile}
              onClick={updateProduct}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView column w="100%" minWidth="320px" p="20px" bg="white">
          <CFView column center w="100%">
            <TextInputField
              width="100%"
              label="Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <TextInputField
              width="100%"
              mt="10px"
              label="Description"
              value={fields.description}
              onChange={(e) =>
                setFields({ ...fields, description: e.target.value })
              }
            />
            <TextInputField
              width="100%"
              label="Image URL"
              value={fields.imageUrl}
              onChange={(e) =>
                setFields({ ...fields, imageUrl: e.target.value })
              }
            />
            <CFView mb="20px">
              <FilePicker
                multiple={false}
                width="100%"
                disabled={loadingFile}
                onChange={onFileUpload}
                placeholder="Select the file here!"
              />
            </CFView>
            <TextInputField
              width="100%"
              mt="10px"
              label="Price"
              value={fields.price}
              onChange={(e) => setFields({ ...fields, price: e.target.value })}
            />
            <TextInputField
              width="100%"
              mt="10px"
              label="Tax Rate"
              value={fields.taxRate}
              onChange={(e) =>
                setFields({ ...fields, taxRate: e.target.value })
              }
            />
            <SelectField
              mt="10px"
              width="100%"
              label="Order Type"
              value={fields.orderType}
              onChange={(e) =>
                setFields({ ...fields, orderType: e.target.value })
              }
            >
              <option value="dine-in">Dine-in Only</option>
              <option value="dine-out">Take-Out Only</option>
              <option value="all" selected>
                All
              </option>
            </SelectField>
            {dispatch.restaurant.getIntegrationId() === 'ini' && (
              <TextInputField
                width="100%"
                mt="10px"
                label="POS ID / Item ID"
                value={fields.posId}
                onChange={(e) =>
                  setFields({ ...fields, posId: e.target.value })
                }
              />
            )}
            {dispatch.restaurant.getIntegrationId() === 'ini' && (
              <TextInputField
                width="100%"
                mt="10px"
                label="POS Name / Size ID"
                value={fields.posName}
                onChange={(e) =>
                  setFields({ ...fields, posName: e.target.value })
                }
              />
            )}
          </CFView>
          <CheckSelectButton
            label="Sold Out"
            onClick={() =>
              setFields({
                ...fields,
                activeAfter:
                  fields.activeAfter > utils.moment().valueOf()
                    ? utils.moment().year(2000).valueOf()
                    : utils.moment().year(3000).valueOf(),
              })
            }
            selected={fields.activeAfter > utils.moment().valueOf()}
          />
          <PrimaryButton
            mt="20px"
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateProduct}
          />
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
