import React, { useState, useEffect } from 'react'
import { CFView, PrimaryButton, PrimaryModal } from 'components'
import { SelectField, TextInputField } from 'evergreen-ui'
import { dispatch } from 'store'

const initialState = {
  tableNumber: '',
  posTableId: '',
  active: 'true',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.table) {
      const active = props.table.active ? 'true' : 'false'
      setFields({ ...initialState, ...props.table, active })
    } else {
      setFields({ ...initialState })
    }
  }, [props.table])

  const updateTable = async () => {
    const { tableNumber, posTableId } = fields
    const active = fields.active === 'true' ? true : false
    if (!tableNumber) {
      alert('Please enter a table number.')
      return
    }
    setSaving(true)
    try {
      if (props.table) {
        const tableId = props.table.id
        await dispatch.dashboard.updateTable({
          tableId,
          tableDetails: { tableNumber, posTableId, active },
        })
      } else {
        await dispatch.dashboard.createTable({
          tableDetails: {
            tableNumber,
            posTableId,
            active,
          },
        })
      }
      dispatch.notification.setMessage({
        message: `Table saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert(e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal
      title={props.table ? 'Edit Table' : 'Create Table'}
      {...props}
    >
      <CFView column maxWidth="400px" minWidth="320px" p="20px">
        <TextInputField
          label="Table Number"
          value={fields.tableNumber}
          maxLength={3}
          onChange={(e) =>
            setFields({ ...fields, tableNumber: e.target.value })
          }
        />
        {dispatch.restaurant.getIntegrationId() === 'ini' && (
          <TextInputField
            label="POS Table ID"
            value={fields.posTableId}
            onChange={(e) =>
              setFields({ ...fields, posTableId: e.target.value })
            }
          />
        )}
        <SelectField
          label="Active"
          value={fields.active}
          onChange={(e) => setFields({ ...fields, active: e.target.value })}
        >
          <option value={true}>True</option>
          <option value={false}>False</option>
        </SelectField>
        <PrimaryButton
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateTable}
        />
      </CFView>
    </PrimaryModal>
  )
}
