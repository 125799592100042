import React, { useState, useEffect } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

export default (props) => {
  const [tipValues, setTipValues] = useState([0.1, 0.15, 0.2])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (!isEmpty(props.tipValues)) {
      setTipValues(props.tipValues)
    }
  }, [props.tipValues])

  const onTipValuesChange = (tipValue, index) => {
    tipValues[index] = tipValue
    setTipValues([...tipValues])
  }

  const updateTipValues = async () => {
    if (isNaN(tipValues[0]) || tipValues[0] > 1 || tipValues[0] < 0) {
      alert('Tip 1 must be a number between 0 and 1.')
      return
    }
    if (isNaN(tipValues[1]) || tipValues[1] > 1 || tipValues[1] < 0) {
      alert('Tip 2 must be a number between 0 and 1.')
      return
    }
    if (isNaN(tipValues[2]) || tipValues[2] > 1 || tipValues[2] < 0) {
      alert('Tip 3 must be a number between 0 and 1.')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.updateTipValues([
        Number(tipValues[0]),
        Number(tipValues[1]),
        Number(tipValues[2]),
      ])
      dispatch.notification.setMessage({
        message: `Tip values updated!`,
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error updating tip values.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Edit Tip Values" {...props}>
      <CFView column w="360px" p="20px">
        <CFView column mb="20px">
          <LabelInput
            label="Tip 1"
            value={tipValues[0]}
            onChange={(e) => onTipValuesChange(e.target.value, 0)}
          />
          <LabelInput
            mt="10px"
            label="Tip 2"
            value={tipValues[1]}
            onChange={(e) => onTipValuesChange(e.target.value, 1)}
          />
          <LabelInput
            mt="10px"
            label="Tip 3"
            value={tipValues[2]}
            onChange={(e) => onTipValuesChange(e.target.value, 2)}
          />
        </CFView>
        <PrimaryButton
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateTipValues}
        />
      </CFView>
    </PrimaryModal>
  )
}
