import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BackButton, Card, CFSelect, CFView } from 'components'
import { AddIcon, Button, DeleteIcon, EditIcon } from 'evergreen-ui'
import PromoCodeModal from './PromoCodeModal'
import { dispatch } from 'store'
import { omit } from 'lodash'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [showModal, setShowModal] = useState(false)
  const [promoCode, setPromoCode] = useState({})
  const [saving, setSaving] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showPromoCodeModal = (promoCode) => {
    setShowModal(true)
    setPromoCode(promoCode)
  }

  const closeModal = () => {
    setShowModal(false)
    setPromoCode({})
  }

  const deletePromoCode = async (code) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setSaving(code)
      try {
        const promoCodes = omit(dispatch.restaurant.getPromoCodes(), code)
        await dispatch.dashboard.updatePromoCodes({ promoCodes })
        dispatch.notification.setMessage({
          message: `Promo code deleted!`,
          level: 'success',
        })
      } catch (e) {
        alert('Error deleting promo code.')
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <CFView h="100%" p="20px" center>
      <CFView column>
        <BackButton onClick={history.goBack} />
        <Card
          h="calc(100vh - 148px)"
          w="1080px"
          mt="10px"
          column
          overflowY="auto"
        >
          <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
            <CFView h1 bold>
              Promo Codes
            </CFView>
            <CFView row>
              <Button
                intent="danger"
                iconBefore={AddIcon}
                height={40}
                width={170}
                onClick={() => showPromoCodeModal({})}
              >
                Create Code
              </Button>
            </CFView>
          </CFView>
          <CFSelect selector={[dispatch.restaurant.getPromoCodes]}>
            {([promoCodes]) =>
              Object.keys(promoCodes).length > 0 ? (
                <CFView p="20px" column>
                  {Object.values(promoCodes).map((promoCode) => {
                    const { active, code, discount } = promoCode
                    return (
                      <Card p="10px" mb="10px" row justifyBetween key={code}>
                        <CFView row>
                          <CFView column>
                            <CFView fontSize={14}>{`Code: ${code}`}</CFView>
                            <CFView fontSize={14}>{`Discount: ${
                              discount * 100
                            }%`}</CFView>
                            <CFView fontSize={14}>{`Active: ${active}`}</CFView>
                          </CFView>
                        </CFView>
                        <CFView column>
                          <Button
                            width={170}
                            marginBottom={10}
                            appearance="primary"
                            intent="warning"
                            iconBefore={EditIcon}
                            height={40}
                            onClick={() => showPromoCodeModal(promoCode)}
                          >
                            Edit Code
                          </Button>
                          <Button
                            width={170}
                            marginBottom={10}
                            appearance="primary"
                            intent="danger"
                            disabled={saving === code}
                            iconBefore={DeleteIcon}
                            height={40}
                            onClick={() => deletePromoCode(code)}
                          >
                            {saving === code ? 'Deleting' : 'Delete'}
                          </Button>
                        </CFView>
                      </Card>
                    )
                  })}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Promo Codes
                </CFView>
              )
            }
          </CFSelect>
        </Card>
      </CFView>
      <PromoCodeModal
        isOpen={showModal}
        promoCode={promoCode}
        onRequestClose={closeModal}
      />
    </CFView>
  )
}
