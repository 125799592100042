import React from 'react'
import { CFInput, CFView } from 'components'

export default React.forwardRef((props, ref) => {
  const {
    label,
    color = 'black',
    type,
    disabled,
    multiline,
    onChange,
    onKeyPress,
    maxLength,
    placeholder,
    placeholderstyle,
    value,
    ...rest
  } = props
  return (
    <CFView column {...rest}>
      <CFView bold mb="8px" color={color}>
        {label}
      </CFView>
      <CFInput
        forwardedRef={ref}
        h={multiline ? '80px' : '40px'}
        w="100%"
        boxshadow="inset 1px 2px 5px rgba(0,0,0,.5)"
        bc="rgba(0,0,0,.1)"
        bw=".5px"
        bg="rgba(245,245,245,.8)"
        br="5px"
        p="10px"
        fontSize={18}
        multiline={multiline}
        onKeyPress={onKeyPress}
        onChange={onChange}
        value={value}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        placeholderstyle={placeholderstyle}
      />
    </CFView>
  )
})
