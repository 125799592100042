import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import CategoryModal from './CategoryModal'
import RankModal from './RankModal'
import {
  AddIcon,
  Button,
  DeleteIcon,
  EditIcon,
  SortIcon,
  TagIcon,
  CircleArrowRightIcon,
} from 'evergreen-ui'
import { dispatch } from 'store'
import MoveProductsModal from './MoveProductsModal'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId, menuId } = useParams()
  const [showModal, setShowModal] = useState('')
  const [category, setCategory] = useState('')
  const [index, setIndex] = useState(0)
  const [saving, setSaving] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showCategoryModal = (category) => {
    setShowModal('category')
    setCategory(category)
  }

  const showMoveProductsModal = (category) => {
    setShowModal('product')
    setCategory(category)
  }

  const showRankingModal = (category, index) => {
    setShowModal('rank')
    setCategory(category)
    setIndex(index)
  }

  const closeModal = () => {
    setShowModal('')
    setCategory('')
    setIndex(0)
  }

  const navigateProducts = (categoryId) => {
    dispatch.dashboard.getRole() === 'admin'
      ? history.push(`/menus/${menuId}/categories/${categoryId}/products`)
      : history.push(
          `/restaurants/${restaurantId}/locations/${locationId}/menus/${menuId}/categories/${categoryId}/products`
        )
  }

  const deleteCategory = async (categoryId) => {
    if (
      window.confirm(
        'Are you sure you want to delete? All products will also be deleted.'
      )
    ) {
      setSaving(categoryId)
      try {
        await dispatch.dashboard.deleteCategory({
          menuId,
          categoryId,
        })
      } catch (e) {
        alert('Error deleting category.')
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            <BackButton onClick={history.goBack} />
            <Card
              h="calc(100vh - 148px)"
              w="100vw"
              maxWidth="1080px"
              minWidth="700px"
              mt="10px"
              column
              overflowY="auto"
            >
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Categories
                </CFView>
                <Button
                  intent="danger"
                  iconBefore={AddIcon}
                  height={40}
                  onClick={() => setShowModal('category')}
                >
                  Create Category
                </Button>
              </CFView>
              <CFSelect
                selector={[
                  () => dispatch.restaurant.getCategoryOrder(menuId),
                  dispatch.restaurant.getCategories,
                ]}
              >
                {([categoryOrder, categories]) =>
                  Object.values(categories).length > 0 ? (
                    <CFView p="20px" column>
                      {categoryOrder.map((categoryId, index) => {
                        return (
                          <Card
                            mb="10px"
                            column
                            justifyBetween
                            key={categoryId}
                          >
                            <CFView bg="#43464B" row p="10px">
                              <Button
                                marginRight={10}
                                height={24}
                                iconBefore={SortIcon}
                                onClick={() =>
                                  showRankingModal(
                                    categories[categoryId],
                                    index
                                  )
                                }
                              >
                                {index}
                              </Button>
                              <CFView color="white" bold>
                                {categories[categoryId]?.name}
                              </CFView>
                            </CFView>
                            <CFView row justifyBetween p="10px">
                              <CFView row>
                                {categories[categoryId]?.imageUrl && (
                                  <CFImage
                                    src={categories[categoryId].imageUrl}
                                    cover
                                    w="150px"
                                    h="150px"
                                    mr="20px"
                                    alt="Category"
                                    br="5px"
                                    boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                                  />
                                )}
                                <CFView column>
                                  <CFView fontSize={16} mb="5px">
                                    {`Description: ${categories[categoryId]?.description}`}
                                  </CFView>
                                  <CFView fontSize={16} mb="5px">
                                    {`Order Type: ${
                                      categories[categoryId]?.orderType || 'All'
                                    }`}
                                  </CFView>
                                </CFView>
                              </CFView>
                              <CFView column>
                                <Button
                                  width={170}
                                  marginBottom={10}
                                  appearance="primary"
                                  iconBefore={TagIcon}
                                  height={40}
                                  onClick={() => navigateProducts(categoryId)}
                                >
                                  View Products
                                </Button>
                                <Button
                                  marginBottom={10}
                                  width={171}
                                  appearance="primary"
                                  iconBefore={CircleArrowRightIcon}
                                  height={40}
                                  onClick={() =>
                                    showMoveProductsModal(
                                      categories[categoryId]
                                    )
                                  }
                                >
                                  Move Products
                                </Button>
                                <Button
                                  width={170}
                                  marginBottom={10}
                                  appearance="primary"
                                  intent="warning"
                                  iconBefore={EditIcon}
                                  height={40}
                                  onClick={() =>
                                    showCategoryModal(categories[categoryId])
                                  }
                                >
                                  Edit Category
                                </Button>
                                <Button
                                  width={170}
                                  marginBottom={10}
                                  appearance="primary"
                                  intent="danger"
                                  disabled={saving === categoryId}
                                  iconBefore={DeleteIcon}
                                  height={40}
                                  onClick={() => deleteCategory(categoryId)}
                                >
                                  {saving === categoryId
                                    ? 'Deleting'
                                    : 'Delete Category'}
                                </Button>
                              </CFView>
                            </CFView>
                          </Card>
                        )
                      })}
                    </CFView>
                  ) : (
                    <CFView h2 bold fill center>
                      No Categories
                    </CFView>
                  )
                }
              </CFSelect>
            </Card>
          </CFView>
          <CategoryModal
            isOpen={showModal === 'category'}
            restaurantId={restaurantId}
            category={category}
            menuId={menuId}
            onRequestClose={closeModal}
          />
          <MoveProductsModal
            isOpen={showModal === 'product'}
            menuId={menuId}
            category={category}
            onRequestClose={closeModal}
          />
          <RankModal
            isOpen={showModal === 'rank'}
            category={category}
            menuId={menuId}
            index={index}
            onRequestClose={closeModal}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="CATEGORIES"
          LeftButton={<BackButton onClick={history.goBack} />}
          zIndex={90}
        />
        <CFView h="calc(100vh - 120px)" column center overflowY="auto">
          <CFView
            column
            justifyStart
            alignCenter
            w="100vw"
            mt="20px"
            overflowY="auto"
          >
            <CFView row justifyBetween>
              <Button
                intent="danger"
                iconBefore={AddIcon}
                height={40}
                onClick={() => setShowModal('category')}
              >
                Create Category
              </Button>
            </CFView>
            <CFSelect
              selector={[
                () => dispatch.restaurant.getCategoryOrder(menuId),
                dispatch.restaurant.getCategories,
              ]}
            >
              {([categoryOrder, categories]) =>
                Object.values(categories).length > 0 ? (
                  <CFView p="20px" column>
                    {categoryOrder.map((categoryId, index) => {
                      return (
                        <Card
                          mb="10px"
                          w="100%"
                          column
                          justifyBetween
                          key={categoryId}
                        >
                          <CFView
                            bg="#43464B"
                            w="100%"
                            row
                            justifyStart
                            p="10px"
                          >
                            <Button
                              marginRight={10}
                              height={24}
                              iconBefore={SortIcon}
                              onClick={() =>
                                showRankingModal(categories[categoryId], index)
                              }
                            >
                              {index}
                            </Button>
                            <CFView color="white" bold>
                              {categories[categoryId].name}
                            </CFView>
                          </CFView>
                          <CFView w="100%" column center p="10px">
                            <CFView column textCenter center mv="5px">
                              {categories[categoryId].imageUrl && (
                                <CFImage
                                  src={categories[categoryId].imageUrl}
                                  cover
                                  w="150px"
                                  h="150px"
                                  mb="10px"
                                  alt="Category"
                                  br="5px"
                                  boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                                />
                              )}
                              <CFView column center>
                                <CFView fontSize={16} mb="5px">
                                  {`Description: ${categories[categoryId].description}`}
                                </CFView>
                                <CFView fontSize={16} mb="5px">
                                  {`Order Type: ${
                                    categories[categoryId].orderType || 'All'
                                  }`}
                                </CFView>
                              </CFView>
                            </CFView>
                            <CFView column center>
                              <Button
                                width={170}
                                marginBottom={10}
                                appearance="primary"
                                iconBefore={TagIcon}
                                height={40}
                                onClick={() => navigateProducts(categoryId)}
                              >
                                View Products
                              </Button>
                              <Button
                                marginBottom={10}
                                width={170}
                                appearance="primary"
                                iconBefore={CircleArrowRightIcon}
                                height={40}
                                onClick={() =>
                                  showMoveProductsModal(categories[categoryId])
                                }
                              >
                                Move Products
                              </Button>
                              <Button
                                width={170}
                                marginBottom={10}
                                appearance="primary"
                                intent="warning"
                                iconBefore={EditIcon}
                                height={40}
                                onClick={() =>
                                  showCategoryModal(categories[categoryId])
                                }
                              >
                                Edit Category
                              </Button>
                              <Button
                                width={170}
                                marginBottom={10}
                                appearance="primary"
                                intent="danger"
                                disabled={saving === categoryId}
                                iconBefore={DeleteIcon}
                                height={40}
                                onClick={() => deleteCategory(categoryId)}
                              >
                                {saving === categoryId
                                  ? 'Deleting'
                                  : 'Delete Category'}
                              </Button>
                            </CFView>
                          </CFView>
                        </Card>
                      )
                    })}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Categories
                  </CFView>
                )
              }
            </CFSelect>
          </CFView>
          <CategoryModal
            isOpen={showModal === 'category'}
            restaurantId={restaurantId}
            category={category}
            menuId={menuId}
            onRequestClose={closeModal}
          />
          <MoveProductsModal
            isOpen={showModal === 'product'}
            menuId={menuId}
            category={category}
            onRequestClose={closeModal}
          />
          <RankModal
            isOpen={showModal === 'rank'}
            category={category}
            menuId={menuId}
            index={index}
            onRequestClose={closeModal}
          />
        </CFView>
      </MobileScreen>
    </>
  )
}
