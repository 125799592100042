import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
  PrimaryButton,
} from 'components'
import { Select } from 'evergreen-ui'
import { DatePicker } from '@material-ui/pickers'
import Stats from './Stats'
import Orders from './Orders'
import Chart from './Chart'
import FrequencyChart from './FrequencyChart'
import CustomerRanking from './CustomerRanking'
import ProductRanking from './ProductRanking'
import AddChargeModal from './AddChargeModal'
import RefundModal from './RefundModal'
import CancelModal from './CancelModal'
import { dispatch } from 'store'
import moment from 'moment'

const initialStats = {
  discount: 0,
  subTotal: 0,
  deliveryFee: 0,
  doordashFee: 0,
  onlineDeliveryFee: 0,
  offlineDeliveryFee: 0,
  tax: 0,
  otherTax: 0,
  tip: 0,
  deliveryTip: 0,
  deliveryOrders: 0,
  onlineTotal: 0,
  offlineTotal: 0,
  onlineTaxAmount: 0,
  onlineOtherTaxAmount: 0,
  offlineTaxAmount: 0,
  offlineOtherTaxAmount: 0,
  bagFee: 0,
  dineInTotal: 0,
  deliveryTotal: 0,
  dineInTaxAmount: 0,
  dineInOtherTaxAmount: 0,
  commission: 0,
  cancelledCommission: 0,
  salesTax: 0,
  revenue: 0,
  onlineRefund: 0,
  offlineRefund: 0,
  onlineOrders: 0,
  offlineOrders: 0,
  dineInOrders: 0,
  rewards: 0,
  cancelled: 0,
}

const chartTypes = [
  'total',
  'orders',
  'rewards',
  'cancelled',
  'active users',
  'new users',
]

export default () => {
  const history = useHistory()
  const { restaurantId, locationId } = useParams()
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState({})
  const [exportOrders, setExportOrders] = useState([])
  const [showModal, setShowModal] = useState(true)
  const [activeUsers, setActiveUsers] = useState([])
  const [newUsers, setNewUsers] = useState([])
  const [stats, setStats] = useState(initialStats)
  const [fromDate, setFromDate] = useState(moment().startOf('day'))
  const [toDate, setToDate] = useState(moment().endOf('day'))
  const [displayType, setDisplayType] = useState('stats')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    refreshData()
  }, [locationId, restaurantId])

  const refreshData = async () => {
    if (Math.abs(fromDate.diff(toDate, 'days')) > 95) {
      alert('Date range cannot be longer than 3 months.')
      return
    }
    setSaving(true)
    try {
      const [orders, activeUsers, newUsers] = await Promise.all([
        dispatch.dashboard.getOrdersRange({
          fromDate,
          toDate,
        }),
        dispatch.dashboard.getActiveUsersRange({ fromDate, toDate }),
        dispatch.dashboard.getNewUsersRange({ fromDate, toDate }),
      ])
      const exportOrdersData = []
      const stats = orders.reduce(
        (prev, order) => {
          const {
            createdAt,
            paymentMethod = 'online',
            orderType = 'Pickup',
            subTotal = 0,
            deliveryFee = 0,
            doordashFee = 0,
            taxAmount = 0,
            bagFeeAmount = 0,
            otherTaxAmount = 0,
            discount = 0,
            tipAmount = 0,
            total = 0,
            appFeeAmount = 0,
            stripeFeeAmount = 0,
            commissionAmount = 0,
            revenue = 0,
            rewards = null,
            totalRefundAmount = 0,
            status,
            products = {},
            choicesProducts = [],
            orderNumber,
            name = '',
            phoneNumber = '',
            email = '',
            firstOrderDiscountAmount = 0,
            pickupDiscountAmount = 0,
          } = order

          const productsStringArray = []
          Object.values(products).forEach((product) => {
            productsStringArray.push(
              `${product.count}x ${product.name} (${product.price})`
            )
          })
          choicesProducts.forEach((choicesProduct) => {
            productsStringArray.push(
              `${choicesProduct.count}x ${choicesProduct.name} (${choicesProduct.totalPrice})`
            )
          })
          const rewardsStringArray = []
          Object.values(rewards).forEach((reward) => {
            rewardsStringArray.push(
              `${reward.count}x ${reward.name} (${reward.price})`
            )
          })
          const orderData = {
            orderNumber,
            orderDate: moment(createdAt).format('DD/MM/YY'),
            orderType,
            status,
            name,
            phoneNumber,
            email,
            products: productsStringArray.join(', '),
            rewards: rewardsStringArray.join(', '),
            subtotal: subTotal.toFixed(2),
            firstOrderDiscount: firstOrderDiscountAmount.toFixed(2),
            pickupDiscount: pickupDiscountAmount.toFixed(2),
            discount: discount.toFixed(2),
            deliveryFee: deliveryFee.toFixed(2),
            tax: (taxAmount - otherTaxAmount).toFixed(2),
            otherTax: otherTaxAmount.toFixed(2),
            tip: tipAmount.toFixed(2),
            total: total.toFixed(2),
            commissionFee: commissionAmount.toFixed(2),
            stripeFee: stripeFeeAmount.toFixed(2),
            revenue: revenue.toFixed(2),
          }
          exportOrdersData.push(orderData)

          if (paymentMethod === 'online') {
            prev.onlineOrders++
          } else {
            prev.offlineOrders++
          }
          prev.orders++
          if (status !== 'Cancelled') {
            prev.subTotal += subTotal
            prev.tax += taxAmount
            prev.otherTax += otherTaxAmount
            prev.bagFee += bagFeeAmount
            if (orderType === 'Delivery') {
              prev.deliveryTip += tipAmount
              prev.deliveryOrders++
              prev.deliveryTotal += total - tipAmount
            } else {
              prev.tip += tipAmount
            }
            if (doordashFee > 0) {
              prev.doordashFee += doordashFee
            }
            prev.commission +=
              appFeeAmount > 0 ? appFeeAmount : commissionAmount
            if (
              moment(createdAt).isAfter(moment('2021-06-01').startOf('day'))
            ) {
              prev.salesTax +=
                appFeeAmount > 0
                  ? (appFeeAmount + doordashFee) * 0.05
                  : commissionAmount * 0.05
            }
            if (paymentMethod === 'online') {
              prev.onlineTotal += total
              prev.onlineRefund += totalRefundAmount
              prev.onlineDeliveryFee += deliveryFee
              prev.onlineTaxAmount += taxAmount
              prev.onlineOtherTaxAmount += otherTaxAmount
            }
            if (paymentMethod === 'offline') {
              prev.offlineTotal += total
              prev.offlineRefund += totalRefundAmount
              prev.offlineDeliveryFee += deliveryFee
              prev.offlineTaxAmount += taxAmount
              prev.offlineOtherTaxAmount += otherTaxAmount
            }
            if (orderType === 'Dine-in') {
              prev.dineInOrders++
              prev.dineInTotal += total - tipAmount
              prev.dineInTaxAmount += taxAmount
              prev.dineInOtherTaxAmount += otherTaxAmount
            }
            prev.revenue += revenue
            prev.discount += discount
            if (rewards) {
              const rewards = Object.values(order.rewards)
              for (const reward of rewards) {
                prev.rewards += reward.count
              }
            }
          } else {
            prev.cancelled++
            prev.cancelledCommission += appFeeAmount
            if (
              moment(createdAt).isAfter(moment('2021-06-01').startOf('day'))
            ) {
              prev.salesTax += appFeeAmount * 0.05
            }
          }
          return prev
        },
        { ...initialStats }
      )
      stats.activeUsers = Object.values(activeUsers).length
      stats.newUsers = Object.values(newUsers).length
      setStats(stats)
      setOrders(orders)
      setExportOrders(exportOrdersData)
      setActiveUsers(activeUsers)
      setNewUsers(newUsers)
    } catch (e) {
      alert('Error fetching orders.')
    } finally {
      setSaving(false)
    }
  }

  const showModalType = (type, order) => {
    setOrder(order)
    setShowModal(type)
  }

  const closeModal = () => {
    setShowModal('')
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            {dispatch.dashboard.getRole() !== 'admin' && (
              <BackButton mb="10px" onClick={history.goBack} />
            )}
            <Card
              h={
                dispatch.dashboard.getRole() !== 'admin'
                  ? 'calc(100vh - 148px)'
                  : 'calc(100vh - 110px)'
              }
              w="1080px"
              column
              overflowY="auto"
            >
              <CFView
                p="20px"
                row
                justifyBetween
                alignCenter
                bg="rgba(0,0,0,.3)"
              >
                <CFView h1 bold>
                  Analytics
                </CFView>
                <CFView row alignCenter>
                  <CFView>
                    <Select
                      label="Display Type"
                      value={displayType}
                      onChange={(e) => setDisplayType(e.target.value)}
                    >
                      <option value={'stats'}>Statistics</option>
                      <option value={'orders list'}>Order List</option>
                      <option value={'best products'}>Best Products</option>
                      <option value={'best customers'}>Best Customers</option>
                      <option value={'total'}>Revenue Growth</option>
                      <option value={'orders'}>Orders Growth</option>
                      <option value={'rewards'}>Rewards Growth</option>
                      <option value={'cancelled'}>Cancelled Growth</option>
                      <option value={'active users'}>
                        Active Users Growth
                      </option>
                      <option value={'new users'}>New Users Growth</option>
                      <option value={'frequency'}>Order Frequency</option>
                    </Select>
                  </CFView>
                  <CFView w="20px" />
                  <CFView>
                    <CFView>From</CFView>
                    <DatePicker value={fromDate} onChange={setFromDate} />
                  </CFView>
                  <CFView w="20px" />
                  <CFView>
                    <CFView>To</CFView>
                    <DatePicker value={toDate} onChange={setToDate} />
                  </CFView>
                  <CFView w="20px" />
                  <PrimaryButton
                    w="200px"
                    disabled={saving}
                    label={saving ? 'Refreshing...' : 'Apply'}
                    onClick={refreshData}
                  />
                </CFView>
              </CFView>
              {orders.length > 0 ? (
                <CFView p="20px" column>
                  {displayType === 'stats' && (
                    <Stats
                      stats={stats}
                      exportOrders={exportOrders}
                      orders={orders}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  )}
                  {displayType === 'orders list' && (
                    <Orders
                      orders={orders}
                      showModalType={showModalType}
                      refreshData={refreshData}
                    />
                  )}
                  {chartTypes.includes(displayType) && (
                    <Chart
                      orders={orders}
                      activeUsers={activeUsers}
                      newUsers={newUsers}
                      dataType={displayType}
                    />
                  )}
                  {displayType === 'frequency' && (
                    <FrequencyChart orders={orders} users={activeUsers} />
                  )}
                  {displayType === 'best customers' && (
                    <CustomerRanking orders={orders} users={activeUsers} />
                  )}
                  {displayType === 'best products' && (
                    <ProductRanking orders={orders} />
                  )}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Data
                </CFView>
              )}
            </Card>
          </CFView>
          <AddChargeModal
            isOpen={showModal === 'add charge'}
            order={order}
            refreshData={refreshData}
            onRequestClose={closeModal}
          />
          <RefundModal
            isOpen={showModal === 'refund'}
            order={order}
            refreshData={refreshData}
            onRequestClose={closeModal}
          />
          <CancelModal
            isOpen={showModal === 'cancel'}
            order={order}
            refreshData={refreshData}
            onRequestClose={closeModal}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="Analytics"
          LeftButton={
            dispatch.dashboard.getRole() === 'admin' ? null : (
              <BackButton onClick={history.goBack} />
            )
          }
        />
        <CFView
          h="calc(100vh - 120px)"
          column
          overflowY="auto"
          minWidth="320px"
        >
          <CFView column>
            <Card
              h={
                dispatch.dashboard.getRole() !== 'admin'
                  ? 'calc(100vh - 148px)'
                  : 'calc(100vh - 110px)'
              }
              column
              overflowY="auto"
            >
              <CFView
                p="20px"
                column
                justifyBetween
                alignCenter
                bg="rgba(0,0,0,.3)"
              >
                <CFView>
                  <Select
                    height={40}
                    label="Display Type"
                    value={displayType}
                    onChange={(e) => setDisplayType(e.target.value)}
                  >
                    <option value={'stats'}>Statistics</option>
                    <option value={'orders list'}>Order List</option>
                    <option value={'best products'}>Best Products</option>
                    <option value={'best customers'}>Best Customers</option>
                    <option value={'total'}>Revenue Growth</option>
                    <option value={'orders'}>Orders Growth</option>
                    <option value={'rewards'}>Rewards Growth</option>
                    <option value={'cancelled'}>Cancelled Growth</option>
                    <option value={'active users'}>Active Users Growth</option>
                    <option value={'new users'}>New Users Growth</option>
                    <option value={'frequency'}>Order Frequency</option>
                  </Select>
                </CFView>
                <CFView row center mv="15px">
                  <CFView>
                    <CFView>From</CFView>
                    <DatePicker value={fromDate} onChange={setFromDate} />
                  </CFView>
                  <CFView w="20px" />
                  <CFView>
                    <CFView>To</CFView>
                    <DatePicker value={toDate} onChange={setToDate} />
                  </CFView>
                </CFView>
                <PrimaryButton
                  w="200px"
                  disabled={saving}
                  label={saving ? 'Refreshing...' : 'Apply'}
                  onClick={refreshData}
                />
              </CFView>
              {orders.length > 0 ? (
                <CFView p="20px" column>
                  {displayType === 'stats' && <Stats stats={stats} />}
                  {displayType === 'orders list' && (
                    <Orders
                      orders={orders}
                      showModalType={showModalType}
                      refreshData={refreshData}
                    />
                  )}
                  {chartTypes.includes(displayType) && (
                    <Chart
                      orders={orders}
                      activeUsers={activeUsers}
                      newUsers={newUsers}
                      dataType={displayType}
                    />
                  )}
                  {displayType === 'frequency' && (
                    <FrequencyChart orders={orders} users={activeUsers} />
                  )}
                  {displayType === 'best customers' && (
                    <CustomerRanking orders={orders} users={activeUsers} />
                  )}
                  {displayType === 'best products' && (
                    <ProductRanking orders={orders} />
                  )}
                </CFView>
              ) : (
                <CFView h2 bold fill center>
                  No Data
                </CFView>
              )}
            </Card>
          </CFView>
          <AddChargeModal
            isOpen={showModal === 'add charge'}
            order={order}
            refreshData={refreshData}
            onRequestClose={closeModal}
          />
          <RefundModal
            isOpen={showModal === 'refund'}
            order={order}
            refreshData={refreshData}
            onRequestClose={closeModal}
          />
          <CancelModal
            isOpen={showModal === 'cancel'}
            order={order}
            refreshData={refreshData}
            onRequestClose={closeModal}
          />
        </CFView>
      </MobileScreen>
    </>
  )
}
