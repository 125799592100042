import React, { useState } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import * as utils from 'cf-utils'

const initialState = {
  name: '',
  price: '',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  const addCharge = async () => {
    const { name, price } = fields
    if (!name) {
      alert('Name cannot be empty or just spaces')
      return
    }
    if (utils.parseCurrency(price) < 0.5) {
      alert('Price must be equal or greater than $0.50')
      return
    }
    if (utils.parseCurrency(price) > 99) {
      alert('Price must be less than $99.00')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.addCharge({
        order: props.order,
        name,
        price,
      })
      dispatch.notification.setMessage({
        message: `Add charge successful!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error adding charge.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Add Charge" {...props}>
      <CFView column>
        <CFView p="20px" bg="white" row>
          <CFView column>
            <LabelInput
              label="Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <LabelInput
              mt="10px"
              label="Price"
              value={fields.price}
              onChange={(e) => setFields({ ...fields, price: e.target.value })}
            />
          </CFView>
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={addCharge}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
