import React, { useState } from 'react'
import {
  Card,
  CFIcon,
  CFLink,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
  HorizontalLine,
} from 'components'
import { AddIcon, Button, DollarIcon, DisableIcon } from 'evergreen-ui'
import { dispatch } from 'store'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { COLOR } from 'styles'

export default ({ orders, showModalType, refreshData }) => {
  const [saving, setSaving] = useState(false)

  const undoDone = async ({
    orderNumber,
    restaurantId,
    id: orderId,
    userId,
  }) => {
    setSaving(true)
    try {
      await dispatch.dashboard.updateOrderStatus({
        restaurantId,
        orderId,
        userId,
        order: { status: 'Preparing' },
      })
      dispatch.notification.setMessage({
        message: `Order status updated!`,
        level: 'success',
      })
      refreshData()
    } catch (e) {
      alert('Error saving status.')
    } finally {
      setSaving(false)
    }
  }

  const startOfYesterday = moment().subtract(2, 'days').startOf('day').valueOf()

  return orders.map((order) => (
    <>
      <DefaultScreen>
        <Card mb="10px" column justifyBetween key={order.id}>
          <CFView row p="10px" mb="5px" bg="black" justifyBetween alignCenter>
            <CFView row justifyStart alignCenter>
              <CFView xBold white fontSize={19}>{` ${order.status}`}</CFView>
              <CFView
                row
                center
                xBold
                fontSize={19}
                color={COLOR.brand}
                mh="4px"
              >
                {`${order.orderType ? order.orderType : 'Pickup'}`}
              </CFView>
              <CFView
                bold
                white
                fontSize={19}
                xBold
                mr="5px"
              >{`Order #${order.orderNumber} -`}</CFView>
              <CFView white xBold fontSize={19}>{` ${
                order.paymentMethod
                  ? order.paymentMethod === 'online'
                    ? 'Paid Online'
                    : 'Paid Offline'
                  : 'Paid Online'
              }`}</CFView>
            </CFView>
            <CFView white bold fontSize={16}>{`Received: ${moment(
              order.createdAt
            ).format('LT, DD/MM/YY')}`}</CFView>
          </CFView>
          <CFView row justifyBetween alignStart p="15px">
            <CFView column bc="black" bw="1.5px" br="2px" p="15px" w="400px">
              <CFView
                bg="lightgrey"
                p="10px"
                fontSize={16}
                mb="15px"
              >{`Special Requests: ${
                order.notes ? order.notes : 'None'
              }`}</CFView>
              {Object.values(order.products)
                .concat(order.choicesProducts || [])
                .map((product, index) => {
                  return (
                    <CFView key={index} column justifyStart alignStart>
                      <CFView row justifyBetween alignStart w="100%">
                        <CFView row justifyStart alignStart maxWidth="250px">
                          <CFView
                            column
                            center
                            p="5px"
                            w="25px"
                            h="25px"
                            bg="darkgrey"
                            mr="7px"
                            br="3px"
                            bold
                            fontSize={11}
                          >{`${product.count}X `}</CFView>
                          <CFView
                            column
                            center
                            bold
                            mt="3px"
                            fontSize={16}
                          >{`${product.name}`}</CFView>
                        </CFView>
                        <CFView column center mr="15px" fontSize={16}>{`$${(
                          product.price * product.count
                        ).toFixed(2)}`}</CFView>
                      </CFView>
                      <CFView column mv="8px">
                        {product.choices &&
                          product.choices.map((choice, index) => {
                            return (
                              <CFView
                                row
                                justifyBetween
                                ml="40px"
                                minWidth="150px"
                                maxWidth="350px"
                                key={index}
                              >
                                <CFText h6>{`• ${choice.name}`}</CFText>
                                {choice.price > 0 && (
                                  <CFView h6>{`$${choice.price.toFixed(
                                    2
                                  )}`}</CFView>
                                )}
                              </CFView>
                            )
                          })}
                      </CFView>
                    </CFView>
                  )
                })}
              {Object.keys(order.rewards).length > 0 && (
                <CFView column>
                  {Object.values(order.rewards).map((reward, index) => {
                    return (
                      <CFView
                        row
                        justifyStart
                        alignStart
                        mb="8px"
                        maxWidth="240px"
                        key={index}
                      >
                        <CFView
                          column
                          center
                          p="5px"
                          w="25px"
                          h="25px"
                          bg="darkgrey"
                          mr="7px"
                          br="3px"
                          bold
                          fontSize={11}
                        >{`${reward.count}X `}</CFView>
                        <CFIcon
                          icon="gift"
                          color="red"
                          mr="5px"
                          fontSize={20}
                          mt="3px"
                        />
                        <CFView
                          column
                          center
                          bold
                          fontSize={16}
                          mt="3px"
                        >{`${reward.name}`}</CFView>
                      </CFView>
                    )
                  })}
                </CFView>
              )}
              {!!order.addedCharges &&
                order.addedCharges.map((charge, index) => {
                  return (
                    <CFView row justifyBetween alignStart key={index}>
                      <CFView
                        row
                        justifyStart
                        alignStart
                        mb="8px"
                        maxWidth="240px"
                      >
                        <CFView
                          column
                          center
                          p="5px"
                          w="25px"
                          h="25px"
                          bg="darkgrey"
                          mr="7px"
                          br="3px"
                          bold
                          fontSize={11}
                        >
                          1X
                        </CFView>
                        <CFView
                          column
                          center
                          bold
                          fontSize={16}
                        >{`Added Charge: ${charge.name}`}</CFView>
                      </CFView>
                      <CFView
                        column
                        center
                        fontSize={16}
                        mb="8px"
                        mr="15px"
                        mt="3px"
                      >{`$${charge.price.toFixed(2)}`}</CFView>
                    </CFView>
                  )
                })}
              <HorizontalLine mv="15px" color="black" />
              <CFView column justifyCenter alignEnd mr="15px">
                <CFView
                  fontSize={16}
                  mt="8px"
                  mb="4px"
                >{`Subtotal: $${order.subTotal.toFixed(2)}`}</CFView>
                {order.discount > 0 && (
                  <CFView
                    fontSize={16}
                    mb="4px"
                  >{`Discount: -$${order.discount.toFixed(2)}`}</CFView>
                )}
                {order.orderType === 'Delivery' && (
                  <CFView fontSize={16} mb="4px">
                    {`Delivery Fee: $${order.deliveryFee.toFixed(2)}`}
                  </CFView>
                )}
                <CFView
                  fontSize={16}
                  mb="4px"
                >{`Tax: $${order.taxAmount.toFixed(2)}`}</CFView>
                {order.bagFeeAmount > 0 && (
                  <CFView fontSize={16} mb="4px">
                    {`Bag Fee: $${order.bagFeeAmount.toFixed(2)}`}
                  </CFView>
                )}
              </CFView>
              <CFView mv="8px">
                <HorizontalLine color="black" />
              </CFView>
              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mb="4px"
                mr="15px"
              >{`Total: $${(order.total - order.tipAmount).toFixed(
                2
              )}`}</CFView>
              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mb="4px"
                mr="15px"
              >
                {`Fee: -$${(
                  order.commissionAmount + (order.stripeFeeAmount || 0)
                ).toFixed(2)}
                `}
              </CFView>
              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mb="4px"
                mr="15px"
              >
                {`GST on Fees: -$${(
                  (order.commissionAmount + (order.stripeFeeAmount || 0)) *
                  0.05
                ).toFixed(2)}
                `}
              </CFView>
              {order.tipAmount > 0 && (
                <CFView
                  column
                  alignEnd
                  justifyCenter
                  fontSize={16}
                  mb="4px"
                  mr="15px"
                >{`Tip (${(order.tip * 100).toFixed(
                  0
                )}%): $${order.tipAmount.toFixed(2)}`}</CFView>
              )}
              {!!order.refunds &&
                order.refunds.map((refund, index) => {
                  return (
                    <CFView row justifyEnd alignCenter key={index}>
                      <CFView
                        column
                        alignEnd
                        justifyCenter
                        fontSize={16}
                        mb="4px"
                        mr="15px"
                      >{`Refund (${
                        refund.description
                      }): -$${refund.amount.toFixed(2)}`}</CFView>
                    </CFView>
                  )
                })}
              <CFView mv="4px">
                <HorizontalLine color="black" />
              </CFView>

              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mv="4px"
                mr="15px"
              >
                {`Revenue Payable: $${(
                  order.revenue -
                  (order.totalRefundAmount || 0) -
                  (order.commissionAmount + (order.stripeFeeAmount || 0)) * 0.05
                ).toFixed(2)}`}
              </CFView>
            </CFView>
            <CFView column justifyStart alignStart w="350px">
              {!isEmpty(order.doordashDetails) && (
                <CFView column fontSize={16} mb="8px">
                  <CFView xBold fontSize={15} mr="10px">
                    DoorDash Id:
                  </CFView>
                  {`#${order.doordashDetails.id}`}
                  <CFView xBold fontSize={15} mr="10px" mt="8px">
                    DoorDash Tracking Url:
                  </CFView>
                  {`${order.doordashDetails.delivery_tracking_url}`}
                </CFView>
              )}
              {order.orderType === 'Dine-in' && (
                <CFView row fontSize={16} mb="8px">
                  <CFView xBold fontSize={15} mr="10px">
                    Table #:
                  </CFView>
                  {order.tableNumber}
                </CFView>
              )}
              <CFView fontSize={16} mb="8px" row>
                <CFView xBold fontSize={15} mr="10px">
                  Name:
                </CFView>
                {order.name}
              </CFView>
              <CFView fontSize={16} mb="8px" row>
                <CFView xBold fontSize={15} mr="10px">
                  Phone:
                </CFView>
                {order.phoneNumber}
              </CFView>
              <CFView fontSize={16} mb="8px" row>
                <CFView xBold fontSize={15} mr="10px">
                  Email:
                </CFView>
                {order.email}
              </CFView>
              {order.orderType === 'Delivery' && (
                <CFView row fontSize={16} mb="8px">
                  <CFView xBold fontSize={15} mr="10px">
                    Address:
                  </CFView>
                  {order.aptNumber.length > 0 && `#${order.aptNumber} - `}
                  {order.address}
                </CFView>
              )}
              {order.orderType === 'Delivery' && (
                <CFView fontSize={16} mb="8px" row>
                  <CFView xBold fontSize={15} mr="10px">
                    Delivery Note:
                  </CFView>
                  {order.deliveryInstructions}
                </CFView>
              )}
              {order.cancelReason && (
                <CFView mb="8px" fontSize={16} row>
                  <CFView xBold fontSize={15} mr="10px">
                    Cancel Reason:
                  </CFView>
                  {order.cancelReason}
                </CFView>
              )}
              <CFView mb="8px" fontSize={16} row>
                <CFView xBold fontSize={15} mr="10px">
                  Complete By:
                </CFView>
                {`${moment(order.completedAt).format('LT, DD/MM/YY')}`}
              </CFView>
              {order.pointsUsed > 0 && (
                <CFView mb="8px" fontSize={16} row>
                  <CFView xBold fontSize={15} mr="10px">
                    Points Used:
                  </CFView>
                  {order.pointsUsed}
                </CFView>
              )}
              <CFView fontSize={16} row>
                <CFView xBold fontSize={15} mr="10px">
                  Device:
                </CFView>
                {order.sourceClient}
              </CFView>
            </CFView>
            {order.status !== 'Cancelled' ? (
              <CFView column justifyBetween>
                {order.status === 'Done' &&
                  order.createdAt > startOfYesterday && (
                    <Button
                      iconBefore={AddIcon}
                      marginBottom={10}
                      height={40}
                      disabled={saving}
                      onClick={() => undoDone(order)}
                    >
                      {saving ? 'Undoing Done' : 'Undo Done'}
                    </Button>
                  )}
                {dispatch.dashboard.getRole() === 'super_admin' && (
                  <>
                    <Button
                      iconBefore={AddIcon}
                      marginBottom={10}
                      height={40}
                      onClick={() => showModalType('add charge', order)}
                    >
                      Add Charge
                    </Button>
                    <Button
                      iconBefore={DollarIcon}
                      marginBottom={10}
                      height={40}
                      onClick={() => showModalType('refund', order)}
                    >
                      Partial Refund
                    </Button>
                    <Button
                      iconBefore={DisableIcon}
                      height={40}
                      onClick={() => showModalType('cancel', order)}
                    >
                      Cancel Order
                    </Button>
                  </>
                )}
              </CFView>
            ) : (
              <CFView w="200px" />
            )}
          </CFView>
        </Card>
      </DefaultScreen>
      <MobileScreen>
        <Card mb="10px" column justifyBetween key={order.id}>
          <CFView
            column
            p="10px"
            mb="5px"
            bg="black"
            justifyBetween
            alignCenter
          >
            <CFView row justifyStart alignCenter>
              <CFView
                xBold
                white
                mr="5px"
              >{`ORDER #${order.orderNumber} -`}</CFView>
              <CFView white xBold fontSize={19}>{` ${order.status}`}</CFView>
            </CFView>
            <CFView row center mv="4px">
              <CFView
                row
                center
                xBold
                fontSize={19}
                color={COLOR.brand}
                mh="4px"
              >
                {`${order.orderType ? order.orderType : 'Pickup'}`} -
              </CFView>

              <CFView white xBold fontSize={19}>{` ${
                order.paymentMethod
                  ? order.paymentMethod === 'online'
                    ? 'Paid Online'
                    : 'Paid Offline'
                  : 'Paid Online'
              }`}</CFView>
            </CFView>
            <CFView white bold fontSize={16} mv="5px">{`Received: ${moment(
              order.createdAt
            ).format('LT, DD/MM/YY')}`}</CFView>
          </CFView>
          <CFView column center p="15px" w="100%">
            <CFView column bc="black" bw="1.5px" br="2px" p="15px" w="100%">
              <CFView
                bg="lightgrey"
                p="10px"
                fontSize={16}
                mb="15px"
              >{`Special Requests: ${
                order.notes ? order.notes : 'None'
              }`}</CFView>
              {Object.values(order.products)
                .concat(order.choicesProducts || [])
                .map((product, index) => {
                  return (
                    <CFView key={index} column justifyStart alignStart>
                      <CFView row justifyBetween alignStart w="100%">
                        <CFView row justifyStart alignStart maxWidth="250px">
                          <CFView
                            column
                            center
                            p="5px"
                            w="25px"
                            h="25px"
                            bg="darkgrey"
                            mr="7px"
                            br="3px"
                            bold
                            fontSize={11}
                          >{`${product.count}X `}</CFView>
                          <CFView
                            column
                            center
                            bold
                            mt="3px"
                            fontSize={16}
                          >{`${product.name}`}</CFView>
                        </CFView>
                        <CFView column center mr="15px" fontSize={16}>{`$${(
                          product.price * product.count
                        ).toFixed(2)}`}</CFView>
                      </CFView>
                      <CFView column mv="8px">
                        {product.choices &&
                          product.choices.map((choice, index) => {
                            return (
                              <CFView
                                row
                                justifyBetween
                                ml="40px"
                                minWidth="150px"
                                maxWidth="350px"
                                key={index}
                              >
                                <CFText h6>{`• ${choice.name}`}</CFText>
                                {choice.price > 0 && (
                                  <CFView h6>{`$${choice.price.toFixed(
                                    2
                                  )}`}</CFView>
                                )}
                              </CFView>
                            )
                          })}
                      </CFView>
                    </CFView>
                  )
                })}
              {Object.keys(order.rewards).length > 0 && (
                <CFView column>
                  {Object.values(order.rewards).map((reward, index) => {
                    return (
                      <CFView row justifyStart alignStart mb="8px" key={index}>
                        <CFView
                          column
                          center
                          p="5px"
                          w="25px"
                          h="25px"
                          bg="darkgrey"
                          mr="7px"
                          br="3px"
                          bold
                          fontSize={11}
                        >{`${reward.count}X `}</CFView>
                        <CFIcon
                          icon="gift"
                          color="red"
                          mr="5px"
                          fontSize={20}
                          mt="3px"
                        />
                        <CFView
                          column
                          center
                          bold
                          fontSize={16}
                          mt="3px"
                        >{`${reward.name}`}</CFView>
                      </CFView>
                    )
                  })}
                </CFView>
              )}
              {!!order.addedCharges &&
                order.addedCharges.map((charge, index) => {
                  return (
                    <CFView row justifyBetween alignStart key={index}>
                      <CFView
                        row
                        justifyStart
                        alignCStart
                        mb="8px"
                        maxWidth="230px"
                      >
                        <CFView
                          column
                          center
                          p="5px"
                          w="25px"
                          h="25px"
                          bg="darkgrey"
                          mr="7px"
                          br="3px"
                          bold
                          fontSize={11}
                        >
                          1X
                        </CFView>
                        <CFView
                          column
                          center
                          bold
                          fontSize={16}
                          mt="3px"
                        >{`Added Charge: ${charge.name}`}</CFView>
                      </CFView>
                      <CFView
                        column
                        center
                        fontSize={16}
                        mb="8px"
                        mr="15px"
                      >{`$${charge.price.toFixed(2)}`}</CFView>
                    </CFView>
                  )
                })}
              <HorizontalLine mv="15px" color="black" />
              <CFView column justifyCenter alignEnd mr="15px">
                <CFView
                  fontSize={16}
                  mt="8px"
                  mb="4px"
                >{`Subtotal: $${order.subTotal.toFixed(2)}`}</CFView>
                {order.discount > 0 && (
                  <CFView
                    fontSize={16}
                    mb="4px"
                  >{`Discount: -$${order.discount.toFixed(2)}`}</CFView>
                )}
                {order.orderType === 'Delivery' && (
                  <CFView fontSize={16} mb="4px">
                    {`Delivery Fee: $${order.deliveryFee.toFixed(2)}`}
                  </CFView>
                )}
                <CFView
                  fontSize={16}
                  mb="4px"
                >{`Tax: $${order.taxAmount.toFixed(2)}`}</CFView>
                {order.bagFeeAmount > 0 && (
                  <CFView fontSize={16} mb="4px">
                    {`Bag Fee: $${order.bagFeeAmount.toFixed(2)}`}
                  </CFView>
                )}
              </CFView>
              <CFView mv="8px">
                <HorizontalLine color="black" />
              </CFView>
              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mb="4px"
                mr="15px"
              >{`Total: $${(order.total - order.tipAmount).toFixed(
                2
              )}`}</CFView>
              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mb="4px"
                mr="15px"
              >
                {`Fee: -$${(
                  order.commissionAmount + (order.stripeFeeAmount || 0)
                ).toFixed(2)}
                `}
              </CFView>
              {order.tipAmount > 0 && (
                <CFView
                  column
                  alignEnd
                  justifyCenter
                  fontSize={16}
                  mb="4px"
                  mr="15px"
                >{`Tip (${(order.tip * 100).toFixed(
                  0
                )}%): $${order.tipAmount.toFixed(2)}`}</CFView>
              )}
              {!!order.refunds &&
                order.refunds.map((refund, index) => {
                  return (
                    <CFView row justifyEnd alignCenter key={index}>
                      <CFView
                        column
                        alignEnd
                        justifyCenter
                        fontSize={16}
                        mb="4px"
                        mr="15px"
                      >{`Refund (${
                        refund.description
                      }): -$${refund.amount.toFixed(2)}`}</CFView>
                    </CFView>
                  )
                })}
              <CFView mv="4px">
                <HorizontalLine color="black" />
              </CFView>
              <CFView
                column
                alignEnd
                justifyCenter
                fontSize={16}
                mv="4px"
                mr="15px"
              >
                {`Revenue Payable: $${(
                  order.revenue -
                  (order.totalRefundAmount || 0) -
                  (order.commissionAmount + (order.stripeFeeAmount || 0)) * 0.05
                ).toFixed(2)}`}
              </CFView>
            </CFView>
            <CFView column justifyStart alignStart w="100%" mv="15px">
              {!isEmpty(order.doordashDetails) && (
                <CFView column fontSize={16} mb="8px">
                  <CFView xBold fontSize={15} mr="10px">
                    DoorDash Id:
                  </CFView>
                  {`#${order.doordashDetails.id}`}
                  <CFView xBold fontSize={15} mr="10px" mt="8px">
                    DoorDash Tracking Url:
                  </CFView>
                  <CFLink
                    maxWidth="300px"
                    href={`${order.doordashDetails.delivery_tracking_url}`}
                  >
                    Link
                  </CFLink>
                </CFView>
              )}
              {order.orderType === 'Dine-in' && (
                <CFView row fontSize={16} mb="8px">
                  <CFView xBold fontSize={15} mr="10px">
                    Table #:
                  </CFView>
                  {order.tableNumber}
                </CFView>
              )}
              <CFView fontSize={16} mb="8px" row>
                <CFView xBold fontSize={15} mr="10px">
                  Name:
                </CFView>
                {order.name}
              </CFView>
              <CFView fontSize={16} mb="8px" row>
                <CFView xBold fontSize={15} mr="10px">
                  Phone:
                </CFView>
                {order.phoneNumber}
              </CFView>
              <CFView fontSize={16} mb="8px" row>
                <CFView xBold fontSize={15} mr="10px">
                  Email:
                </CFView>
                {order.email}
              </CFView>
              {order.orderType === 'Delivery' && (
                <CFView row fontSize={16} mb="8px">
                  <CFView xBold fontSize={15} mr="10px">
                    Address:
                  </CFView>
                  {order.aptNumber.length > 0 && `#${order.aptNumber} - `}
                  {order.address}
                </CFView>
              )}
              {order.orderType === 'Delivery' && (
                <CFView fontSize={16} mb="8px" row>
                  <CFView xBold fontSize={15} mr="10px">
                    Delivery Note:
                  </CFView>
                  {order.deliveryInstructions}
                </CFView>
              )}
              {order.cancelReason && (
                <CFView mb="8px" fontSize={16} row>
                  <CFView xBold fontSize={15} mr="10px">
                    Cancel Reason:
                  </CFView>
                  {order.cancelReason}
                </CFView>
              )}
              <CFView mb="8px" fontSize={16} row>
                <CFView xBold fontSize={15} mr="10px">
                  Complete By:
                </CFView>
                {moment(order.completedAt).format('LT, DD/MM/YY')}
              </CFView>
              {order.pointsUsed > 0 && (
                <CFView mb="8px" fontSize={16} row>
                  <CFView xBold fontSize={15} mr="10px">
                    Points Used:
                  </CFView>
                  {order.pointsUsed}
                </CFView>
              )}
              <CFView mb="8px" fontSize={16} row>
                <CFView xBold fontSize={15} mr="10px">
                  Device:
                </CFView>
                {order.sourceClient}
              </CFView>
            </CFView>
            {order.status !== 'Cancelled' ? (
              <CFView column justifyBetween>
                {order.status === 'Done' &&
                  order.createdAt > startOfYesterday && (
                    <Button
                      iconBefore={AddIcon}
                      marginBottom={10}
                      height={40}
                      disabled={saving}
                      onClick={() => undoDone(order)}
                    >
                      {saving ? 'Undoing Done' : 'Undo Done'}
                    </Button>
                  )}
                {dispatch.dashboard.getRole() === 'super_admin' && (
                  <>
                    <Button
                      iconBefore={AddIcon}
                      marginBottom={10}
                      height={40}
                      onClick={() => showModalType('add charge', order)}
                    >
                      Add Charge
                    </Button>
                    <Button
                      iconBefore={DollarIcon}
                      marginBottom={10}
                      height={40}
                      onClick={() => showModalType('refund', order)}
                    >
                      Partial Refund
                    </Button>
                    <Button
                      iconBefore={DisableIcon}
                      height={40}
                      onClick={() => showModalType('cancel', order)}
                    >
                      Cancel Order
                    </Button>
                  </>
                )}
              </CFView>
            ) : (
              <CFView w="200px" />
            )}
          </CFView>
        </Card>
      </MobileScreen>
    </>
  ))
}
