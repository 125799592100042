import React from 'react'
import { CFButton, CFIcon, CFView } from 'components'
import { COLOR } from 'styles'

export default ({ label, selected, onClick, disabled, ...props }) => (
  <CFButton w="100%" onClick={onClick} disabled={disabled}>
    <CFView mb="3px" row justifyBetween alignCenter {...props}>
      <CFView bold mr="5px" color={selected ? COLOR.red : 'black'}>
        {label}
      </CFView>
      {selected ? (
        <CFIcon icon="check-square" color={COLOR.red} fontSize="24px" />
      ) : (
        <CFIcon icon="square" color="black" fontSize="24px" />
      )}
    </CFView>
  </CFButton>
)
