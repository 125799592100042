import React from 'react'
import { CFIcon, CFView } from 'components'
import { COLOR } from 'styles'

export default ({ tableNumber, selected, ...props }) => (
  <CFView h="40px" row justifyBetween alignCenter pointer {...props}>
    <CFView h3 color={selected ? COLOR.red : 'black'}>
      {tableNumber}
    </CFView>
    {selected ? (
      <CFIcon icon="check-square" color={COLOR.red} fontSize="24px" />
    ) : (
      <CFIcon icon="square" fontSize="24px" />
    )}
  </CFView>
)
