import React, { useState } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

const initialState = {
  emails: '',
  subject: '',
  imageUrl: '',
  ctaLabel: '',
  ctaUrl: '',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  const sendPromotion = async () => {
    const { emails, subject, imageUrl, ctaLabel, ctaUrl } = fields
    if (!props.restaurant && !emails) {
      alert('Must provide a list of valid emails')
      return
    }
    if (!subject) {
      alert('Subject cannot be empty')
      return
    }
    if (!imageUrl) {
      alert('Image Url cannot be empty')
      return
    }
    if (!ctaLabel) {
      alert('CTA Label cannot be empty')
      return
    }
    if (!ctaUrl) {
      alert('CTA Url cannot be empty')
      return
    }
    setSaving(true)
    try {
      await dispatch.dashboard.createEmailPromotion({
        restaurantId: props.restaurant.id,
        templateData: fields,
        emails,
      })
      dispatch.notification.setMessage({
        message: `Promotions successfully sent!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error sending promotion.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Email Promotion" {...props}>
      <CFView column>
        <CFView w="440px" p="20px" bg="white" row>
          <CFView column fill>
            {!props.restaurant && (
              <LabelInput
                mb="10px"
                multiline
                label="Emails"
                value={fields.emails}
                onChange={(e) =>
                  setFields({ ...fields, emails: e.target.value })
                }
              />
            )}
            <LabelInput
              label="Subject"
              value={fields.subject}
              onChange={(e) =>
                setFields({ ...fields, subject: e.target.value })
              }
            />
            <LabelInput
              mt="10px"
              label="Image Url"
              value={fields.imageUrl}
              onChange={(e) =>
                setFields({ ...fields, imageUrl: e.target.value })
              }
            />
            <LabelInput
              mt="10px"
              label="CTA Label"
              value={fields.ctaLabel}
              onChange={(e) =>
                setFields({ ...fields, ctaLabel: e.target.value })
              }
            />
            <LabelInput
              mt="10px"
              label="CTA Url"
              value={fields.ctaUrl}
              onChange={(e) => setFields({ ...fields, ctaUrl: e.target.value })}
            />
          </CFView>
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SENDING...' : 'SEND'}
            disabled={saving}
            onClick={sendPromotion}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
