import React, { useState, useEffect } from 'react'
import {
  CFView,
  CFSelect,
  CFText,
  PrimaryButton,
  PrimaryModal,
} from 'components'
import { IconButton, AddIcon, RemoveIcon } from 'evergreen-ui'
import { dispatch } from 'store'
import { clamp } from 'lodash'

export default (props) => {
  const [waitTime, setWaitTime] = useState('')
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setWaitTime(props.waitTime)
  }, [props.waitTime])

  const decrementWaitTime = () => {
    const currentWaitTime = waitTime
    const newWaitTime = clamp(currentWaitTime - 10, 10, 120)
    setWaitTime(newWaitTime)
  }

  const incrementWaitTime = () => {
    const currentWaitTime = waitTime
    const newWaitTime = clamp(currentWaitTime + 10, 10, 120)
    setWaitTime(newWaitTime)
  }

  const updateWaitTime = async () => {
    setSaving(true)
    try {
      await dispatch.dashboard.updateWaitTime(waitTime)
      dispatch.notification.setMessage({
        message: 'Wait Time Updated!',
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error updating wait time.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Update Wait Time" {...props}>
      <CFView column p="20px" w="320px">
        <CFView row alignCenter justifyBetween mh="20px">
          <CFView mr={10} white bold>
            <IconButton
              height={50}
              icon={RemoveIcon}
              intent="danger"
              disabled={waitTime <= 10}
              onClick={decrementWaitTime}
            />
          </CFView>
          <CFText h3 heading dark>
            {waitTime} mins
          </CFText>
          <CFView White bold>
            <IconButton
              height={50}
              icon={AddIcon}
              intent="danger"
              disabled={waitTime >= 120}
              onClick={incrementWaitTime}
            />
          </CFView>
        </CFView>
        <PrimaryButton
          mt="20px"
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateWaitTime}
        />
      </CFView>
    </PrimaryModal>
  )
}
