import React, { useState, useEffect } from 'react'
import { CFSelect, CFView, PrimaryButton, PrimaryModal } from 'components'
import { SelectField, TextInputField } from 'evergreen-ui'
import { dispatch } from 'store'

const initialState = {
  id: '',
  email: '',
  password: '',
  restaurantId: '',
  locationId: '',
  role: 'admin',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [locations, setLocations] = useState({})
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.user) {
      setFields({ ...initialState, ...props.user })
      if (props.user.role === 'admin') {
        dispatch.dashboard
          .getLocations(props.user.restaurantId)
          .then((locations) => {
            setLocations(locations)
          })
      }
    } else {
      setFields({ ...initialState })
    }
  }, [props.user])

  const updateRestaurantId = async (restaurantId) => {
    const newLocations = await dispatch.dashboard.getLocations(restaurantId)
    setFields({ ...fields, restaurantId })
    setLocations(newLocations)
  }

  const updateUser = async () => {
    const { email, password, restaurantId, locationId, role } = fields
    if (role === 'admin') {
      if (!restaurantId) {
        alert('Please select a restaurant.')
        return
      }
      if (!locationId) {
        alert('Please select a location.')
        return
      }
    }
    if (!props.user) {
      if (!email) {
        alert('Please enter valid email.')
        return
      }
      if (!password) {
        alert('Please enter a password.')
        return
      }
    }
    setSaving(true)
    try {
      if (props.user) {
        const userId = props.user.id
        await dispatch.dashboard.updateUser({
          userId,
          user: { restaurantId, locationId, name: role, role },
        })
      } else {
        await dispatch.dashboard.registerUser({
          email,
          password,
          restaurantId,
          locationId,
          name: role,
          role,
        })
      }
      dispatch.notification.setMessage({
        message: `User saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert(e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal
      title={props.user ? `Edit ${props.user.email}` : 'Create User'}
      {...props}
    >
      <CFView column maxWidth="400px" minWidth="320px" p="20px">
        <SelectField
          label="Role"
          value={fields.role}
          onChange={(e) => setFields({ ...fields, role: e.target.value })}
        >
          <option value="admin">Admin</option>
          <option value="int_admin">Int Admin</option>
          <option value="menu_admin">Menu Admin</option>
          <option value="super_admin">Super Admin</option>
        </SelectField>
        {!props.user && (
          <TextInputField
            label="Email"
            value={fields.email}
            onChange={(e) => setFields({ ...fields, email: e.target.value })}
          />
        )}
        {!props.user && (
          <TextInputField
            label="Password"
            value={fields.password}
            onChange={(e) => setFields({ ...fields, password: e.target.value })}
          />
        )}
        {fields.role === 'admin' && (
          <CFView>
            <SelectField
              label="Restaurant Id"
              value={fields.restaurantId}
              onChange={(e) => updateRestaurantId(e.target.value)}
            >
              <option value=""></option>
              <CFSelect selector={dispatch.dashboard.getRestaurants}>
                {(restaurants) =>
                  Object.values(restaurants)
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1
                      }
                      if (a.name > b.name) {
                        return 1
                      }
                      return 0
                    })
                    .map((restaurant) => (
                      <option value={restaurant.id} selected>
                        {restaurant.name}
                      </option>
                    ))
                }
              </CFSelect>
            </SelectField>
            {fields.restaurantId && (
              <SelectField
                label="Location Id"
                value={fields.locationId}
                onChange={(e) =>
                  setFields({ ...fields, locationId: e.target.value })
                }
              >
                <option value=""></option>
                <CFSelect selector={dispatch.dashboard.getRestaurants}>
                  {(restaurants) =>
                    Object.values(locations)
                      .sort((a, b) => {
                        if (a.locationName < b.locationName) {
                          return -1
                        }
                        if (a.locationName > b.locationName) {
                          return 1
                        }
                        return 0
                      })
                      .map((location) => (
                        <option value={location.id} selected>
                          {location.locationName}
                        </option>
                      ))
                  }
                </CFSelect>
              </SelectField>
            )}
          </CFView>
        )}
        <PrimaryButton
          label={saving ? 'SAVING...' : 'SAVE'}
          disabled={saving}
          onClick={updateUser}
        />
      </CFView>
    </PrimaryModal>
  )
}
