import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { cfvideo, cftitle } from 'images'

export default () => (
  <>
    <DefaultScreen>
      <CFView h="calc(100vh - 80px)" center>
        <CFView
          ph="80px"
          pv="40px"
          center
          column
          bg="white"
          br="30px"
          boxShadow="1px 1px 3px rgba(0,0,0,.8)"
        >
          <video
            autoPlay
            muted
            loop
            style={{ height: '20rem', objectFit: 'contain' }}
          >
            <source src={cfvideo} />
          </video>
          <img style={{ width: '45rem' }} src={cftitle} alt="codefusion" />
          <p
            style={{
              color: '#263238',
              fontSize: '35px',
              textAlign: 'center',
              lineHeight: '55px',
              fontWeight: 100,
            }}
          >
            {'Proudly based in Vancouver, BC.'}
          </p>
        </CFView>
      </CFView>
    </DefaultScreen>
    <MobileScreen>
      <CFView h="calc(100vh - 70px)" column center overflowY="auto">
        <CFView
          ph="20px"
          pv="40px"
          center
          column
          bg="white"
          br="30px"
          boxShadow="1px 1px 3px rgba(0,0,0,.8)"
        >
          <video
            autoPlay
            muted
            loop
            style={{ height: '15rem', objectFit: 'contain' }}
          >
            <source src={cfvideo} />
          </video>
          <img style={{ width: '30rem' }} src={cftitle} alt="codefusion" />
          <p
            style={{
              color: '#263238',
              fontSize: '20px',
              textAlign: 'center',
              lineHeight: '35px',
              fontWeight: 100,
            }}
          >
            {'Proudly based in Vancouver, BC.'}
          </p>
        </CFView>
      </CFView>
    </MobileScreen>
  </>
)
