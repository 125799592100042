import React, { useState } from 'react'
import ReactExport from 'react-data-export'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { COLOR } from 'styles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default (props) => {
  const [locationIds, setLocationIds] = useState('')
  const [exportData, setExportData] = useState([])

  const generateExportData = () => {
    const locationIdArray = locationIds.split(',').map((id) => id.trim())
    if (locationIdArray.length === 0) {
      alert('Location Ids cannot be empty.')
      return
    }
    const locations = Object.values(props.restaurants).reduce(
      (prev, restaurant) => {
        const { locationStats } = restaurant
        Object.keys(locationStats).forEach((locationId) => {
          prev = {
            ...prev,
            [locationId]: {
              ...locationStats[locationId],
              locationId,
              restaurantName: restaurant.name,
              locationName: restaurant.Locations[locationId].locationName,
              twilioFee: locationStats[locationId].waitTimeUpdateCount * 0.0079,
              profit:
                locationStats[locationId].commission +
                locationStats[locationId].stripeFee -
                locationStats[locationId].actualStripeFee -
                locationStats[locationId].addChargeCount * 0.4 -
                locationStats[locationId].waitTimeUpdateCount * 0.0079,
            },
          }
        })
        return prev
      },
      {}
    )
    const incorrectLocationIds = []
    const exportDataArray = locationIdArray.reduce((prev, locationId) => {
      if (locations[locationId]) {
        prev.push(locations[locationId])
      } else {
        incorrectLocationIds.push(locationId)
      }
      return prev
    }, [])
    if (incorrectLocationIds.length > 0) {
      alert(
        `The following location ids are incorrect: ${incorrectLocationIds.join(
          ', '
        )}`
      )
      return
    }
    setExportData(exportDataArray)
  }

  return (
    <PrimaryModal title="Export Stats" {...props}>
      <CFView w="340px" p="20px" bg="white" column>
        <CFView fill>
          <LabelInput
            mb="40px"
            multiline
            label="Location Ids"
            value={locationIds}
            onChange={(e) => setLocationIds(e.target.value)}
          />
        </CFView>
        {exportData.length > 0 ? (
          <ExcelFile
            filename="CodeFusion Statistics"
            element={
              <CFView
                h="45px"
                bg={COLOR.brand}
                br="25px"
                ph="30px"
                boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                center
                hover
              >
                <CFView white bold>
                  Export Stats
                </CFView>
              </CFView>
            }
          >
            <ExcelSheet data={exportData} name="Orders Summary">
              <ExcelColumn label="Location Id" value="locationId" />
              <ExcelColumn label="Restaurant Name" value="restaurantName" />
              <ExcelColumn label="Location Name" value="locationName" />
              <ExcelColumn label="Online Total" value="onlineTotal" />
              <ExcelColumn label="Offline Total" value="offlineTotal" />
              <ExcelColumn label="Tip" value="tip" />
              <ExcelColumn label="Commission Fee" value="commission" />
              <ExcelColumn label="Stripe Fee" value="stripeFee" />
              <ExcelColumn label="Actual Stripe Fee" value="actualStripeFee" />
              <ExcelColumn label="Twilio Fee" value="twilioFee" />
              <ExcelColumn label="Online Orders" value="onlineCount" />
              <ExcelColumn label="Profit" value="profit" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <PrimaryButton label="Generate Data" onClick={generateExportData} />
        )}
      </CFView>
    </PrimaryModal>
  )
}
