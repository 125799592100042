import React, { useState } from 'react'
import { Line } from 'react-chartjs-2'
import { PrimaryButton, CFView } from 'components'
import { dispatch } from 'store'
import moment from 'moment'

export default (props) => {
  const [aggregateType, setAggregateType] = useState('day')

  let firstDate, lastDate
  const data = {}
  if (props.dataType === 'active users' || props.dataType === 'new users') {
    let users =
      props.dataType === 'active users' ? props.activeUsers : props.newUsers
    for (const user of Object.values(users)) {
      const restaurantId = dispatch.restaurant.getRestaurantId()
      const time =
        props.dataType === 'active users'
          ? user.restaurants[restaurantId].lastLogin
          : user.restaurants[restaurantId].createdAt
      const dateNum = moment(time).startOf(aggregateType).valueOf()
      if (firstDate == null || firstDate > dateNum) {
        firstDate = dateNum
      }
      if (lastDate == null || lastDate < dateNum) {
        lastDate = dateNum
      }
      data[dateNum] = (data[dateNum] || 0) + 1
    }
  } else {
    for (const order of props.orders) {
      const dateNum = moment(order.createdAt).startOf(aggregateType).valueOf()
      if (firstDate == null || firstDate > dateNum) {
        firstDate = dateNum
      }
      if (lastDate == null || lastDate < dateNum) {
        lastDate = dateNum
      }
      if (props.dataType === 'cancelled' && order.status === 'Cancelled') {
        data[dateNum] = (data[dateNum] || 0) + 1
      } else if (props.dataType === 'orders' && order.status !== 'Cancelled') {
        data[dateNum] = (data[dateNum] || 0) + 1
      } else if (props.dataType === 'rewards') {
        let rewardsCount = 0
        if (order.rewards) {
          Object.values(order.rewards).forEach((reward) => {
            rewardsCount += reward.count
          })
        }
        data[dateNum] = (data[dateNum] || 0) + rewardsCount
      } else {
        data[dateNum] = (data[dateNum] || 0) + order[props.dataType]
      }
    }
  }
  const labelsObj = {}
  {
    let date = firstDate
    while (date <= lastDate) {
      labelsObj[date] = moment(date).format('M/D')
      date = moment(date)
        .add(1, aggregateType + 's')
        .valueOf()
    }
  }
  const labelskeys = Object.keys(labelsObj)
  let total = 0
  const aggregateData = labelskeys.map((dateNum) => {
    total = (data[dateNum] || 0) + total
    return total.toFixed(2)
  })
  const chartData = {
    labels: Object.values(labelsObj),
    datasets: [
      {
        backgroundColor: 'rgba(44,44,192,0.4)',
        borderColor: 'rgba(44,44,192,1)',
        label: props.dataType,
        data: labelskeys.map((dateNum) => (data[dateNum] || 0).toFixed(2)),
      },
      {
        label: `${props.dataType} growth`,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        data: aggregateData,
      },
    ],
  }

  return (
    <CFView column>
      <Line data={chartData} />
      <CFView row justifyCenter>
        <PrimaryButton
          onClick={() => setAggregateType('day')}
          disabled={aggregateType === 'day'}
          label="Days"
        />
        <CFView w="20px" />
        <PrimaryButton
          onClick={() => setAggregateType('week')}
          disabled={aggregateType === 'week'}
          label="Weeks"
        />
        <CFView w="20px" />
        <PrimaryButton
          onClick={() => setAggregateType('month')}
          disabled={aggregateType === 'month'}
          label="Months"
        />
      </CFView>
    </CFView>
  )
}
