import React, { useState } from 'react'
import {
  CFSelect,
  CFView,
  DefaultScreen,
  PrimaryButton,
  PrimaryModal,
  CheckSelectButton,
} from 'components'
import { dispatch } from 'store'

export default (props) => {
  const { categoryId } = props
  const [chosenModifiers, setChosenModifiers] = useState([])
  const [saving, setSaving] = useState()

  const saveModifiers = async () => {
    setSaving(true)
    const products = dispatch.restaurant.getProductsInOrder(categoryId, true)
    try {
      for (const item of products) {
        await dispatch.dashboard.updateProduct({
          productId: item.id,
          productDetails: { modifiers: chosenModifiers },
        })
      }
      dispatch.notification.setMessage({
        message: `Modifiers saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setChosenModifiers([])
    } catch (e) {
      alert('Error saving modifiers.')
    } finally {
      setSaving(false)
    }
  }

  const handleToggleModifier = (modifierId) => {
    if (chosenModifiers.includes(modifierId)) {
      setChosenModifiers((prev) => prev.filter((item) => item !== modifierId))
    } else {
      setChosenModifiers((prev) => [...prev, modifierId])
    }
  }

  return (
    <>
      <DefaultScreen>
        <PrimaryModal title="Apply Modifiers" {...props}>
          <CFView column p="20px" w="700px">
            <CFSelect selector={dispatch.restaurant.getModifiers}>
              {(modifiers) =>
                Object.values(modifiers).length > 0 ? (
                  <CFView p="20px" column>
                    {Object.values(modifiers).map((modifier) => (
                      <>
                        <CheckSelectButton
                          label={modifier.name}
                          onClick={() => handleToggleModifier(modifier.id)}
                          selected={chosenModifiers.includes(modifier.id)}
                        />
                        <CFView h="10px" />
                      </>
                    ))}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Modifiers
                  </CFView>
                )
              }
            </CFSelect>
            <PrimaryButton
              onClick={saveModifiers}
              disabled={saving}
              label={saving ? 'SAVING...' : 'SAVE'}
            />
          </CFView>
        </PrimaryModal>
      </DefaultScreen>
    </>
  )
}
