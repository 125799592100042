import React, { useState } from 'react'
import {
  Card,
  CFText,
  CFView,
  DefaultScreen,
  HorizontalLine,
  MobileScreen,
  PrimaryButton,
} from 'components'
import ReactExport from 'react-data-export'
import ExportInvoiceModal from './ExportInvoiceModal'
import moment from 'moment'
import { COLOR } from 'styles'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export default ({ stats, orders, exportOrders, fromDate, toDate }) => {
  const [showModal, setShowModal] = useState('')

  return (
    <>
      <DefaultScreen>
        <CFView row justifyBetween alignStart w="100%">
          <CFView column justifyStart alignStart w="100%" mr="15px">
            <Card p="15px" mb="10px" column justifyStart w="100%">
              <CFView row justifyBetween mb="8px">
                <CFView bold>Subtotal:</CFView>
                <CFView>${stats.subTotal.toFixed(2)}</CFView>
              </CFView>
              {stats.discount > 0 && (
                <CFView row justifyBetween mb="8px">
                  <CFView bold>Rewards/Discounts: </CFView>
                  <CFView> -${stats.discount.toFixed(2)}</CFView>
                </CFView>
              )}
              {stats.onlineDeliveryFee + stats.offlineDeliveryFee > 0 && (
                <CFView column mb="8px">
                  <CFView row justifyBetween mb="5px">
                    <CFView bold>Delivery Fee:</CFView>
                    <CFView>
                      $
                      {(
                        stats.onlineDeliveryFee + stats.offlineDeliveryFee
                      ).toFixed(2)}
                    </CFView>
                  </CFView>
                  {stats.onlineDeliveryFee > 0 && (
                    <CFView ml="60px">
                      - Online: ${stats.onlineDeliveryFee.toFixed(2)}
                    </CFView>
                  )}
                  {stats.offlineDeliveryFee > 0 && (
                    <CFView ml="60px">
                      - Offline: ${stats.offlineDeliveryFee.toFixed(2)}
                    </CFView>
                  )}
                </CFView>
              )}
              <CFView column>
                <CFView row justifyBetween mb="5px">
                  <CFView bold>Taxes: </CFView>
                  <CFView>${stats.tax.toFixed(2)}</CFView>
                </CFView>
                <CFView ml="60px">
                  - GST: ${(stats.tax - stats.otherTax).toFixed(2)}
                </CFView>
                <CFView ml="60px">- PST: ${stats.otherTax.toFixed(2)}</CFView>
              </CFView>
              {stats.bagFee > 0 && (
                <CFView row justifyBetween mt="8px">
                  <CFView bold>Bag Fee:</CFView>
                  <CFView>${stats.bagFee.toFixed(2)}</CFView>
                </CFView>
              )}
              <CFView mv="10px">
                <HorizontalLine color="black" />
              </CFView>
              <CFView column>
                <CFView row justifyBetween>
                  <CFView bold>Total:</CFView>
                  <CFView>
                    $
                    {(
                      stats.onlineTotal +
                      stats.offlineTotal -
                      stats.tip -
                      stats.deliveryTip
                    ).toFixed(2)}
                  </CFView>
                </CFView>
              </CFView>
            </Card>
            <Card p="15px" mr="15px" mb="10px" w="100%">
              <CFView column>
                <CFView row justifyStart>
                  <CFText color="gray" bold>
                    {stats.onlineOrders + stats.offlineOrders}
                  </CFText>
                  <CFView bold mb="2px" ml="4px">
                    Orders Received:
                  </CFView>
                </CFView>
                <CFView column justifyStart alignStart w="100%" pl="60px">
                  <CFView row justifyBetween w="100%">
                    <CFView mb="2px">
                      <CFText color="gray" bold>
                        -{' '}
                        {stats.onlineOrders +
                          stats.offlineOrders -
                          stats.deliveryOrders -
                          stats.dineInOrders}
                      </CFText>{' '}
                      Pickup:
                    </CFView>
                    <CFView ml="3px">
                      $
                      {(
                        stats.onlineTotal +
                        stats.offlineTotal -
                        stats.tip -
                        stats.deliveryTip -
                        stats.deliveryTotal -
                        stats.dineInTotal
                      ).toFixed(2)}
                    </CFView>
                  </CFView>
                </CFView>
                {stats.deliveryTotal > 0 && (
                  <CFView column justifyStart alignStart w="100%" pl="60px">
                    <CFView row justifyBetween w="100%">
                      <CFView mb="2px">
                        <CFText color="gray" bold>
                          - {stats.deliveryOrders}{' '}
                        </CFText>
                        Delivery:
                      </CFView>
                      <CFView ml="3px">
                        {' '}
                        ${stats.deliveryTotal.toFixed(2)}
                      </CFView>
                    </CFView>
                  </CFView>
                )}
                {stats.dineInTotal > 0 && (
                  <CFView column justifyStart alignStart w="100%" pl="60px">
                    <CFView row justifyBetween w="100%">
                      <CFView mb="2px">
                        <CFText color="gray" bold>
                          - {stats.dineInOrders}{' '}
                        </CFText>
                        Dine-in:
                      </CFView>
                      <CFView ml="3px"> ${stats.dineInTotal.toFixed(2)}</CFView>
                    </CFView>
                  </CFView>
                )}
              </CFView>
            </Card>
            <Card p="15px" mr="15px" mb="10px" column justifyStart w="100%">
              {stats.onlineTotal > 0 && (
                <CFView column justifyStart alignStart w="100%" mb="10px">
                  <CFView row justifyBetween w="100%">
                    <CFView bold mb="2px">
                      <CFText color="gray">{stats.onlineOrders} </CFText>
                      Paid Online:
                    </CFView>
                    <CFView ml="3px">
                      $
                      {(
                        stats.onlineTotal -
                        stats.tip -
                        stats.deliveryTip
                      ).toFixed(2)}
                    </CFView>
                  </CFView>
                  <CFView ml="60px" column justifyStart>
                    <CFText>
                      - GST: $
                      {(
                        stats.onlineTaxAmount - stats.onlineOtherTaxAmount
                      ).toFixed(2)}
                    </CFText>
                    <CFText>
                      - PST: ${stats.onlineOtherTaxAmount.toFixed(2)}
                    </CFText>
                  </CFView>
                </CFView>
              )}
              {stats.offlineTotal > 0 && (
                <CFView column justifyStart alignStart w="100%">
                  <CFView row justifyBetween w="100%">
                    <CFView bold mb="2px">
                      <CFText color="gray">{stats.offlineOrders} </CFText>
                      Paid In-Store:
                    </CFView>
                    <CFView ml="3px"> ${stats.offlineTotal.toFixed(2)}</CFView>
                  </CFView>
                  <CFView ml="60px" column justifyStart>
                    <CFText>
                      - GST: $
                      {(
                        stats.offlineTaxAmount - stats.offlineOtherTaxAmount
                      ).toFixed(2)}
                    </CFText>
                    <CFText>
                      - PST: ${stats.offlineOtherTaxAmount.toFixed(2)}
                    </CFText>
                  </CFView>
                </CFView>
              )}
            </Card>
          </CFView>
          <Card p="15px" mr="15px" mb="10px" w="100%">
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Tips: </CFView>
                <CFView>${(stats.tip + stats.deliveryTip).toFixed(2)}</CFView>
              </CFView>
              {stats.deliveryTip > 0 && (
                <CFView ml="60px" mt="5px">
                  - Server: ${stats.tip.toFixed(2)}
                </CFView>
              )}
              {stats.deliveryTip > 0 && (
                <CFView ml="60px">
                  - Delivery: ${stats.deliveryTip.toFixed(2)}
                </CFView>
              )}
            </CFView>
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Refunds:</CFView>
                <CFView>
                  -$
                  {(stats.onlineRefund + stats.offlineRefund).toFixed(2)}
                </CFView>
              </CFView>
              {stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Online: ${stats.onlineRefund.toFixed(2)}
                </CFView>
              )}
              {stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Offline: ${stats.offlineRefund.toFixed(2)}
                </CFView>
              )}
            </CFView>
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Fees:</CFView>
                -${(stats.commission + stats.cancelledCommission).toFixed(2)}
              </CFView>
              {stats.cancelledCommission > 0 && (
                <CFView ml="60px">
                  - Cancelled: ${stats.cancelledCommission.toFixed(2)}
                </CFView>
              )}
            </CFView>
            {stats.doordashFee > 0 && (
              <CFView row justifyBetween mb="8px">
                <CFView bold>DoorDash Fee:</CFView>
                <CFView>-${stats.doordashFee.toFixed(2)}</CFView>
              </CFView>
            )}
            <CFView row justifyBetween mb="8px">
              <CFView bold>GST on Fees:</CFView>
              <CFView>-${stats.salesTax.toFixed(2)}</CFView>
            </CFView>
            <CFView mv="10px">
              <HorizontalLine color="black" />
            </CFView>
            <CFView column>
              <CFView row justifyBetween>
                <CFView bold>Revenue:</CFView>
                <CFView>
                  $
                  {(
                    stats.onlineTotal +
                    stats.offlineTotal -
                    stats.onlineRefund -
                    stats.offlineRefund -
                    stats.commission -
                    stats.cancelledCommission -
                    stats.salesTax
                  ).toFixed(2)}
                </CFView>
              </CFView>
              {stats.offlineTotal - stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Net Payable: $
                  {(
                    stats.onlineTotal -
                    stats.onlineRefund -
                    stats.commission -
                    stats.cancelledCommission -
                    stats.salesTax
                  ).toFixed(2)}
                </CFView>
              )}
              {stats.offlineTotal - stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Paid In-Store: $
                  {(stats.offlineTotal - stats.offlineRefund).toFixed(2)}
                </CFView>
              )}
            </CFView>
          </Card>
          <Card w="100%" p="15px">
            <CFView row justifyBetween mb="8px">
              <CFView bold>Rewards: </CFView>
              <CFView>{stats.rewards}</CFView>
            </CFView>
            <CFView row justifyBetween mb="8px">
              <CFView bold>Orders Cancelled: </CFView>
              <CFView>{stats.cancelled}</CFView>
            </CFView>
            <CFView row justifyBetween mb="8px">
              <CFView bold>Active Users: </CFView>
              <CFView>{stats.activeUsers}</CFView>
            </CFView>
            <CFView row justifyBetween>
              <CFView bold>New Users: </CFView>
              <CFView>{stats.newUsers}</CFView>
            </CFView>
            <CFView mv="10px">
              <HorizontalLine color="black" />
            </CFView>
            <ExcelFile
              filename={`${moment(fromDate).format('DD/MM/YY')} to ${moment(
                toDate
              ).format('DD/MM/YY')} - CodeFusion Order Summary`}
              element={
                <CFView
                  h="45px"
                  bg={COLOR.brand}
                  br="25px"
                  ph="30px"
                  boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                  center
                  hover
                >
                  <CFView white bold>
                    Export Excel
                  </CFView>
                </CFView>
              }
            >
              <ExcelSheet data={exportOrders} name="Orders Summary">
                <ExcelColumn label="Order #" value="orderNumber" />
                <ExcelColumn label="Order Time" value="orderDate" />
                <ExcelColumn label="Order Type" value="orderType" />
                <ExcelColumn label="Status" value="status" />
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Phone" value="phoneNumber" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Products" value="products" />
                <ExcelColumn label="Rewards" value="rewards" />
                <ExcelColumn label="Subtotal" value="subtotal" />
                <ExcelColumn
                  label="First Order Discount"
                  value="firstOrderDiscount"
                />
                <ExcelColumn label="Pickup Discount" value="pickupDiscount" />
                <ExcelColumn label="Discount" value="discount" />
                <ExcelColumn label="Delivery Fee" value="deliveryFee" />
                <ExcelColumn label="GST" value="tax" />
                <ExcelColumn label="PST" value="otherTax" />
                <ExcelColumn label="Tip" value="tip" />
                <ExcelColumn label="Total" value="total" />
                <ExcelColumn label="Commission Fee" value="commissionFee" />
                <ExcelColumn label="Stripe Fee" value="stripeFee" />
                <ExcelColumn label="Revenue" value="revenue" />
              </ExcelSheet>
            </ExcelFile>
            <PrimaryButton
              mt="10px"
              label={'Export Invoice'}
              onClick={() => setShowModal(true)}
            />
          </Card>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView column center w="100%">
          <Card p="15px" mb="10px" column justifyStart w="100%">
            <CFView row justifyBetween mb="8px">
              <CFView bold>Subtotal:</CFView>
              <CFView>${stats.subTotal.toFixed(2)}</CFView>
            </CFView>
            {stats.discount > 0 && (
              <CFView row justifyBetween mb="8px">
                <CFView bold>Rewards/Discounts: </CFView>
                <CFView> -${stats.discount.toFixed(2)}</CFView>
              </CFView>
            )}
            {stats.onlineDeliveryFee + stats.offlineDeliveryFee > 0 && (
              <CFView column mb="8px">
                <CFView row justifyBetween>
                  <CFView bold>Delivery Fee:</CFView>
                  <CFView>
                    $
                    {(
                      stats.onlineDeliveryFee + stats.offlineDeliveryFee
                    ).toFixed(2)}
                  </CFView>
                </CFView>
                {stats.onlineDeliveryFee > 0 && (
                  <CFView ml="60px">
                    - Online: ${stats.onlineDeliveryFee.toFixed(2)}
                  </CFView>
                )}
                {stats.offlineDeliveryFee > 0 && (
                  <CFView ml="60px">
                    - Offline: ${stats.offlineDeliveryFee.toFixed(2)}
                  </CFView>
                )}
              </CFView>
            )}
            <CFView column>
              <CFView row justifyBetween mb="5px">
                <CFView bold>Taxes: </CFView>
                <CFView>${stats.tax.toFixed(2)}</CFView>
              </CFView>
              <CFView ml="60px">
                - GST: ${(stats.tax - stats.otherTax).toFixed(2)}
              </CFView>
              <CFView ml="60px">- PST: ${stats.otherTax.toFixed(2)}</CFView>
            </CFView>
            {stats.bagFee > 0 && (
              <CFView row justifyBetween mt="8px">
                <CFView bold>Bag Fee:</CFView>
                <CFView>${stats.bagFee.toFixed(2)}</CFView>
              </CFView>
            )}
            <CFView mv="10px">
              <HorizontalLine color="black" />
            </CFView>
            <CFView column>
              <CFView row justifyBetween>
                <CFView bold>Total:</CFView>
                <CFView>
                  $
                  {(
                    stats.onlineTotal +
                    stats.offlineTotal -
                    stats.tip -
                    stats.deliveryTip
                  ).toFixed(2)}
                </CFView>
              </CFView>
              {stats.offlineTotal > 0 && (
                <CFView ml="60px">
                  - Online: $
                  {(stats.onlineTotal - stats.tip - stats.deliveryTip).toFixed(
                    2
                  )}
                </CFView>
              )}
              {stats.offlineTotal > 0 && (
                <CFView ml="60px">
                  - Offline: ${stats.offlineTotal.toFixed(2)}
                </CFView>
              )}
              {stats.dineInTotal > 0 && (
                <CFView ml="60px">
                  - Dine-in: ${stats.dineInTotal.toFixed(2)}
                </CFView>
              )}
            </CFView>
          </Card>
          <Card p="15px" mb="10px" w="100%">
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Tips: </CFView>
                <CFView>${(stats.tip + stats.deliveryTip).toFixed(2)}</CFView>
              </CFView>
              {stats.deliveryTip > 0 && (
                <CFView ml="60px">- Server: ${stats.tip.toFixed(2)}</CFView>
              )}
              {stats.deliveryTip > 0 && (
                <CFView ml="60px">
                  - Delivery: ${stats.deliveryTip.toFixed(2)}
                </CFView>
              )}
            </CFView>
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Refunds:</CFView>
                <CFView>
                  -$
                  {(stats.onlineRefund + stats.offlineRefund).toFixed(2)}
                </CFView>
              </CFView>
              {stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Online: ${stats.onlineRefund.toFixed(2)}
                </CFView>
              )}
              {stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Offline: ${stats.offlineRefund.toFixed(2)}
                </CFView>
              )}
            </CFView>
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Fees:</CFView>
                -${(stats.commission + stats.cancelledCommission).toFixed(2)}
              </CFView>
              {stats.cancelledCommission > 0 && (
                <CFView ml="60px">
                  - Cancelled: ${stats.cancelledCommission.toFixed(2)}
                </CFView>
              )}
            </CFView>
            {stats.doordashFee > 0 && (
              <CFView row justifyBetween mb="8px">
                <CFView bold>DoorDash Fee:</CFView>
                <CFView>-${stats.doordashFee.toFixed(2)}</CFView>
              </CFView>
            )}
            <CFView row justifyBetween mb="8px">
              <CFView bold>GST on Fees:</CFView>
              <CFView>-${stats.salesTax.toFixed(2)}</CFView>
            </CFView>
            <CFView mv="10px">
              <HorizontalLine color="black" />
            </CFView>
            <CFView column>
              <CFView row justifyBetween>
                <CFView bold>Revenue:</CFView>
                <CFView>
                  $
                  {(
                    stats.onlineTotal +
                    stats.offlineTotal -
                    stats.onlineRefund -
                    stats.offlineRefund -
                    stats.commission -
                    stats.cancelledCommission -
                    stats.salesTax
                  ).toFixed(2)}
                </CFView>
              </CFView>
              {stats.offlineTotal - stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Net Payable: $
                  {(
                    stats.onlineTotal -
                    stats.onlineRefund -
                    stats.commission -
                    stats.cancelledCommission -
                    stats.salesTax
                  ).toFixed(2)}
                </CFView>
              )}
              {stats.offlineTotal - stats.offlineRefund > 0 && (
                <CFView ml="60px">
                  - Paid In-Store: $
                  {(stats.offlineTotal - stats.offlineRefund).toFixed(2)}
                </CFView>
              )}
            </CFView>
          </Card>
          <Card w="100%" p="15px">
            <CFView column mb="8px">
              <CFView row justifyBetween>
                <CFView bold>Orders Received: </CFView>
                <CFView>{stats.onlineOrders + stats.offlineOrders}</CFView>
              </CFView>
              {stats.dineInOrders > 0 && (
                <CFView ml="60px">- Dine-in: {stats.dineInOrders}</CFView>
              )}
            </CFView>
            <CFView row justifyBetween mb="8px">
              <CFView bold>Rewards: </CFView>
              <CFView>{stats.rewards}</CFView>
            </CFView>
            <CFView row justifyBetween mb="8px">
              <CFView bold>Orders Cancelled: </CFView>
              <CFView>{stats.cancelled}</CFView>
            </CFView>
            <CFView row justifyBetween mb="8px">
              <CFView bold>Active Users: </CFView>
              <CFView>{stats.activeUsers}</CFView>
            </CFView>
            <CFView row justifyBetween>
              <CFView bold>New Users: </CFView>
              <CFView>{stats.newUsers}</CFView>
            </CFView>
          </Card>
        </CFView>
      </MobileScreen>
      <ExportInvoiceModal
        isOpen={showModal}
        toDate={toDate}
        fromDate={fromDate}
        orders={orders}
        onRequestClose={() => setShowModal(false)}
      />
    </>
  )
}
