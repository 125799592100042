import React, { useState, useEffect } from 'react'
import {
  CFView,
  PrimaryButton,
  PrimaryModal,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { dispatch } from 'store'
import { SelectField, TextInputField, FilePicker } from 'evergreen-ui'
import { trim } from 'lodash'
import { storageRef } from 'cf-core/src/config/firebase'
import Compressor from 'compressorjs'

const initialState = {
  name: '',
  description: '',
  imageUrl: '',
  orderType: 'all',
  isSaving: false,
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)

  useEffect(() => {
    if (props.category) {
      setFields({ ...initialState, ...props.category })
    }
  }, [props.category])

  const updateRestaurant = async () => {
    if (!isFormValid()) {
      return
    }
    setSaving(true)
    try {
      const { name, description, imageUrl, orderType = 'all' } = fields
      if (props.category) {
        await dispatch.dashboard.updateCategory({
          categoryId: props.category.id,
          categoryDetails: {
            name: trim(name),
            description: trim(description),
            imageUrl,
            orderType,
          },
        })
      } else {
        await dispatch.dashboard.createCategory({
          menuId: props.menuId,
          category: {
            name: trim(name),
            description: trim(description),
            imageUrl,
            productOrder: [],
            orderType,
          },
        })
      }
      dispatch.notification.setMessage({
        message: `Category saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error saving category.')
    } finally {
      setSaving(false)
    }
  }

  const isFormValid = () => {
    const { name, imageUrl, orderType } = fields
    if (name === '') {
      alert('Name cannot be empty')
      return false
    }
    if (imageUrl === '') {
      alert('Image cannot be empty')
      return false
    }
    if (
      orderType !== 'all' &&
      orderType !== 'dine-in' &&
      orderType !== 'dine-out'
    ) {
      alert('Order Type must be either dine-in or dine-out or all.')
      return false
    }
    return true
  }

  const onFileUpload = (file) => {
    const newFile = file[0]
    if (!newFile) {
      alert('Please choose a file first!')
    }
    const width = 800
    new Compressor(newFile, {
      quality: 0.2,
      width: width,
      height: width * (2 / 3),
      resize: 'cover',
      convertSize: 61440,

      success(result) {
        const restaurantId = dispatch.restaurant.getRestaurantId()
        const locationId = dispatch.restaurant.getSelectedLocationId()
        const timestamp = new Date().getTime().toString()
        const fileUrl = `/restaurants/${restaurantId}/locations/${locationId}/categories/${timestamp}_${result.name}`
        const fileRef = storageRef.child(fileUrl)
        setLoadingFile(true)
        fileRef
          .put(result)
          .then(() => {
            console.log('File uploaded successfully')
            setLoadingFile(false)
            fileRef
              .getDownloadURL()
              .then((downloadURL) => {
                setFields({ ...fields, imageUrl: downloadURL })
              })
              .catch((error) => {
                console.error('Error getting download URL:', error)
              })
          })
          .catch((error) => {
            setLoadingFile(false)
            console.error('Error uploading file:', error)
          })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }
  return (
    <>
      <DefaultScreen>
        <PrimaryModal
          title={
            props.category ? `Edit ${props.category.name}` : 'Create Category'
          }
          {...props}
        >
          <CFView w="500px" p="20px" column>
            <TextInputField
              width="100%"
              marginBottom={15}
              placeholder="Please Enter Category Name..."
              label="Category Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <TextInputField
              width="100%"
              marginBottom={15}
              label="Description"
              placeholder="Please Enter Category Description..."
              value={fields.description}
              onChange={(e) =>
                setFields({ ...fields, description: e.target.value })
              }
            />
            <TextInputField
              width="100%"
              marginBottom={15}
              label="Image Url"
              placeholder="Please Enter Category Image Url..."
              value={fields.imageUrl}
              onChange={(e) =>
                setFields({ ...fields, imageUrl: e.target.value })
              }
            />
            <FilePicker
              multiple={false}
              width={'100%'}
              marginBottom={20}
              disabled={loadingFile}
              onChange={onFileUpload}
              placeholder="Select the file here!"
            />
            <SelectField
              marginBottom={15}
              label="Order Type"
              value={fields.orderType}
              onChange={(e) =>
                setFields({ ...fields, orderType: e.target.value })
              }
            >
              <option value="dine-in">Dine-in Only</option>
              <option value="dine-out">Take-Out Only</option>
              <option value="all" selected>
                All
              </option>
            </SelectField>
            <CFView p="20px">
              <PrimaryButton
                label={saving ? 'SAVING...' : 'SAVE'}
                disabled={saving || loadingFile}
                onClick={updateRestaurant}
              />
            </CFView>
          </CFView>
        </PrimaryModal>
      </DefaultScreen>
      <MobileScreen>
        <PrimaryModal
          title={
            props.category ? `Edit ${props.category.name}` : 'Create Category'
          }
          {...props}
        >
          <CFView minWidth="340px" w="100%" p="20px" column>
            <TextInputField
              width="100%"
              marginBottom={15}
              placeholder="Please Enter Category Name..."
              label="Categlry Name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <TextInputField
              width="100%"
              marginBottom={15}
              label="Description"
              placeholder="Please Enter Category Description..."
              value={fields.description}
              onChange={(e) =>
                setFields({ ...fields, description: e.target.value })
              }
            />
            <TextInputField
              width="100%"
              marginBottom={15}
              label="Image Url"
              placeholder="Please Enter Category Image Url..."
              value={fields.imageUrl}
              onChange={(e) =>
                setFields({ ...fields, imageUrl: e.target.value })
              }
            />
            <FilePicker
              multiple={false}
              width={'100%'}
              marginBottom={20}
              disabled={loadingFile}
              onChange={onFileUpload}
              placeholder="Select the file here!"
            />
            <SelectField
              marginBottom={15}
              label="Order Type"
              value={fields.orderType}
              onChange={(e) =>
                setFields({ ...fields, orderType: e.target.value })
              }
            >
              <option value="dine-in">Dine-in Only</option>
              <option value="dine-out">Take-Out Only</option>
              <option value="all" selected>
                All
              </option>
            </SelectField>
            <CFView p="20px">
              <PrimaryButton
                label={saving ? 'SAVING...' : 'SAVE'}
                disabled={saving}
                onClick={updateRestaurant}
              />
            </CFView>
          </CFView>
        </PrimaryModal>
      </MobileScreen>
    </>
  )
}
