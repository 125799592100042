import { db } from 'cf-core/src/config/firebase'

export function createOrder({ restaurantId, order }) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')
    .add({ ...order })
}

export function updateOrder({ restaurantId, orderId, order }) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')
    .doc(orderId)
    .update(order)
}

export function deleteOrder({ restaurantId, orderId }) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Orders')
    .doc(orderId)
    .delete()
}

export function getOrderRange({ restaurantId, locationId, fromDate, toDate }) {
  return new Promise((resolve, reject) => {
    db.collection('Restaurants')
      .doc(restaurantId)
      .collection('Orders')
      .where('locationId', '==', locationId)
      .where('createdAt', '>', fromDate.valueOf())
      .where('createdAt', '<', toDate.valueOf())
      .orderBy('createdAt', 'desc')
      .get()
      .then((docs) => {
        const orders = []
        docs.forEach((doc) => {
          orders.push({ ...doc.data(), id: doc.id })
        })
        resolve(orders)
      })
      .catch(reject)
  })
}
