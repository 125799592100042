import { db, storageRef } from 'cf-core/src/config/firebase'

export function updateRestaurant(restaurantId, restaurant) {
  return db.collection('Restaurants').doc(restaurantId).update(restaurant)
}

export function fetchLocations(restaurantId) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .get()
    .then((docs) => {
      const locations = {}
      docs.forEach((doc) => {
        const location = doc.data()
        locations[doc.id] = location
        locations[doc.id].id = doc.id
      })
      return locations
    })
}

export function updateLocation(restaurantId, locationId, location) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .update(location)
}

export function uploadImage(restaurantId, fileName, filePath) {
  return storageRef
    .child(`restaurants/${restaurantId}/${fileName}.jpg`)
    .putFile(filePath)
}

export function createCategory(restaurantId, locationId, categoryDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Categories')
    .add(categoryDetails)
}

export function updateCategoryOrder(
  restaurantId,
  locationId,
  menuId,
  newCategoryOrder
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .update({ [`menus.${menuId}.categoryOrder`]: newCategoryOrder })
}

export function updateProductOrder(
  restaurantId,
  locationId,
  categoryId,
  newProductOrder
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Categories')
    .doc(categoryId)
    .update({ productOrder: newProductOrder })
}

export function updateCategory(
  restaurantId,
  locationId,
  categoryId,
  categoryDetails
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Categories')
    .doc(categoryId)
    .update(categoryDetails)
}

export function deleteCategory(restaurantId, locationId, categoryId) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Categories')
    .doc(categoryId)
    .delete()
}

export function createProduct(restaurantId, locationId, productDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Products')
    .add(productDetails)
}

export function updateProduct(
  restaurantId,
  locationId,
  productId,
  productDetails
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Products')
    .doc(productId)
    .update(productDetails)
}

export function deleteProduct(restaurantId, locationId, productId) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Products')
    .doc(productId)
    .delete()
}

export function addProductOption(
  restaurantId,
  locationId,
  productOptionDetails
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Products')
    .add(productOptionDetails)
}

export function createModifier(restaurantId, locationId, modifierDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Modifiers')
    .add(modifierDetails)
}

export function updateModifier(
  restaurantId,
  locationId,
  modifierId,
  modifierDetails
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Modifiers')
    .doc(modifierId)
    .update(modifierDetails)
}

export function updateRequest(
  restaurantId,
  locationId,
  requestId,
  requestDetails
) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Requests')
    .doc(requestId)
    .update(requestDetails)
}

export function createTable(restaurantId, locationId, tableDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Tables')
    .add(tableDetails)
}

export function updateTable(restaurantId, locationId, tableId, tableDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Tables')
    .doc(tableId)
    .update(tableDetails)
}

export function deleteTable(restaurantId, locationId, tableId) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Tables')
    .doc(tableId)
    .delete()
}
