import React, { useState, useEffect } from 'react'
import { CFView, LabelInput, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'
import { isEmpty } from 'lodash'

const initialState = {
  title: '',
  body: '',
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (!isEmpty(props.welcomeMessage)) {
      setFields(props.welcomeMessage)
    }
  }, [props.welcomeMessage])

  const updateMessage = async () => {
    const { title, body } = fields
    if (!title || !body) {
      alert('Welcome message must have both title and body.')
      setSaving(false)
      return
    }
    if (title.length > 40) {
      alert('Title must be under 40 characters.')
      return
    }
    if (body.length > 350) {
      alert('Body must be under 350 characters.')
    }
    setSaving(true)
    try {
      const welcomeMessage = {
        title,
        body,
      }
      await dispatch.dashboard.updateWelcomeMessage(welcomeMessage)
      dispatch.notification.setMessage({
        message: `Message updated!`,
        level: 'success',
      })
      props.onRequestClose()
    } catch (e) {
      alert('Error updating message.')
    } finally {
      setSaving(false)
    }
  }

  const clearMessage = async () => {
    setSaving(true)
    try {
      await dispatch.dashboard.updateWelcomeMessage({})
      dispatch.notification.setMessage({
        message: `Message cleared!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error clearing message.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <PrimaryModal title="Edit Welcome Message" {...props}>
      <CFView column w="360px">
        <CFView p="20px" column>
          <LabelInput
            label="Title"
            value={fields.title}
            onChange={(e) => setFields({ ...fields, title: e.target.value })}
          />
          <LabelInput
            mt="10px"
            label="Body"
            value={fields.body}
            onChange={(e) => setFields({ ...fields, body: e.target.value })}
          />
        </CFView>
        <CFView p="20px" row justifyBetween>
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving}
            onClick={updateMessage}
          />
          <PrimaryButton
            label={saving ? 'CLEARING...' : 'CLEAR'}
            disabled={saving}
            onClick={clearMessage}
          />
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}
