import React, { useState, useEffect } from 'react'
import {
  Card,
  CFView,
  PrimaryButton,
  PrimaryModal,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { Checkbox, TextInputField } from 'evergreen-ui'
import * as api from 'api'
import { dispatch } from 'store'

const initialState = {
  name: '',
  orderNumber: '0',
  pointsInterval: '1',
  pointsPerInterval: '10',
  commissionFee: '0.025',
  stripeFee: '0.032',
  subscriptionFee: '0',
  minimumCommission: '0',
  landingUrl: '',
  logo: '',
  logoTitle: '',
  mainBg: '',
  mainBgMobile: '',
  menuHeader: '',
  rewardBg: '',
  rewardContainer: '',
  rewardDefault: '',
  rewardRedeemButton: '',
  fontColor: '',
  rewardsFontColor: '',
  theme: 'dark',
  tabletBg: '',
  brandLogo: '',
  brandColor: '',
  confirmingRequest: '',
  orderComplete: '',
  cartEmpty: '',
  inactive: false,
}

export default (props) => {
  const [fields, setFields] = useState(initialState)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (props.restaurant) {
      const { images = {}, styles = {}, ...rest } = props.restaurant
      setFields({
        ...initialState,
        ...images,
        ...styles,
        ...rest,
      })
    }
  }, [props.restaurant])

  const updateRestaurant = async () => {
    if (!isFormValid()) {
      return
    }
    const {
      name,
      orderNumber,
      pointsInterval,
      pointsPerInterval,
      commissionFee,
      stripeFee,
      subscriptionFee,
      minimumCommission,
      logo,
      logoTitle,
      mainBg,
      mainBgMobile,
      menuHeader,
      rewardBg,
      rewardContainer,
      rewardDefault,
      rewardRedeemButton,
      fontColor,
      rewardsFontColor,
      theme,
      tabletBg,
      brandLogo,
      brandColor,
      confirmingRequest,
      orderComplete,
      cartEmpty,
      ...rest
    } = fields
    const restaurantDetails = {
      name,
      orderNumber: Number(orderNumber),
      pointsInterval: Number(pointsInterval),
      pointsPerInterval: Number(pointsPerInterval),
      commissionFee: Number(commissionFee),
      stripeFee: Number(stripeFee),
      subscriptionFee: Number(subscriptionFee),
      minimumCommission: Number(minimumCommission),
      images: {
        logo,
        logoTitle,
        mainBg,
        mainBgMobile,
        menuHeader,
        rewardBg,
        rewardContainer,
        rewardDefault,
        rewardRedeemButton,
        tabletBg,
        brandLogo,
        confirmingRequest,
        orderComplete,
        cartEmpty,
      },
      styles: {
        fontColor,
        brandColor,
        rewardsFontColor,
        theme,
      },
      ...rest,
    }
    setSaving(true)
    try {
      if (props.restaurant) {
        await api.updateRestaurant(props.restaurant.id, restaurantDetails)
      } else {
        await api.createRestaurant(restaurantDetails)
      }
      dispatch.notification.setMessage({
        message: `Restaurant saved!`,
        level: 'success',
      })
      props.onRequestClose()
      setFields(initialState)
    } catch (e) {
      alert('Error saving restaurant.')
    } finally {
      setSaving(false)
    }
  }

  const isFormValid = () => {
    const {
      name,
      orderNumber,
      pointsInterval,
      pointsPerInterval,
      commissionFee,
      stripeFee,
      subscriptionFee,
      minimumCommission,
      theme,
    } = fields
    if (name === '') {
      alert('Name cannot be empty')
      return false
    }
    if (isNaN(Number(orderNumber))) {
      alert('Order Number must be a number.')
      return false
    }
    if (isNaN(Number(pointsInterval))) {
      alert('Points Interval must be a number.')
      return false
    }
    if (isNaN(Number(pointsPerInterval))) {
      alert('Points Per Interval must be a number.')
      return false
    }
    if (isNaN(Number(commissionFee))) {
      alert('Commission Fee must be a number.')
      return false
    }
    if (isNaN(Number(stripeFee))) {
      alert('Stripe Fee must be a number.')
      return false
    }
    if (isNaN(Number(subscriptionFee))) {
      alert('Subscription Fee must be a number.')
      return false
    }
    if (isNaN(Number(minimumCommission))) {
      alert('Minimum Commission must be a number.')
      return false
    }
    if (theme !== 'dark' && theme !== 'light') {
      alert('Theme must be dark or light.')
      return false
    }
    return true
  }

  return (
    <PrimaryModal
      title={
        props.restaurant ? `Edit ${props.restaurant.name}` : 'Create Restaurant'
      }
      {...props}
    >
      <DefaultScreen>
        <CFView p="20px" column minWidth="650px">
          <CFView column justifyStart>
            <TextInputField
              width="100%"
              marginBottom={0}
              label="Restaurant name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <Checkbox
              label="Make This Restaurant Inactive"
              marginBottom={10}
              onChange={(e) =>
                setFields({
                  ...fields,
                  inactive: e.target.checked,
                })
              }
              checked={fields.inactive}
            />
          </CFView>
          <CFView bg="white" column>
            <CFView xBold color="#00C0FF" mt="20px" mb="5px">
              RESTAURANT SETTINGS
            </CFView>
            <Card p="20px" column>
              <CFView row justifyBetween w="100%">
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Landing Url"
                  value={fields.landingUrl}
                  onChange={(e) =>
                    setFields({ ...fields, landingUrl: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Order Number"
                  value={fields.orderNumber}
                  onChange={(e) =>
                    setFields({ ...fields, orderNumber: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Points Interval"
                  value={fields.pointsInterval}
                  onChange={(e) =>
                    setFields({ ...fields, pointsInterval: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Points Per Interval"
                  value={fields.pointsPerInterval}
                  onChange={(e) =>
                    setFields({ ...fields, pointsPerInterval: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Commission Fee"
                  value={fields.commissionFee}
                  onChange={(e) =>
                    setFields({ ...fields, commissionFee: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Stripe Fee"
                  value={fields.stripeFee}
                  onChange={(e) =>
                    setFields({ ...fields, stripeFee: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyStart>
                <TextInputField
                  width={180}
                  marginRight={10}
                  label="Subscription Fee"
                  value={fields.subscriptionFee}
                  onChange={(e) =>
                    setFields({ ...fields, subscriptionFee: e.target.value })
                  }
                />
                <TextInputField
                  width={180}
                  marginRight={10}
                  label="Minimum Commission"
                  value={fields.minimumCommission}
                  onChange={(e) =>
                    setFields({ ...fields, minimumCommission: e.target.value })
                  }
                />
              </CFView>
            </Card>
            <CFView xBold color="#00C0FF" mt="20px" mb="5px">
              DESIGN SETTINGS
            </CFView>
            <Card p="20px" column minWidth="200px">
              <CFView row justifyBetween w="100%">
                {' '}
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Logo"
                  value={fields.logo}
                  onChange={(e) =>
                    setFields({ ...fields, logo: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Logo Title"
                  value={fields.logoTitle}
                  onChange={(e) =>
                    setFields({ ...fields, logoTitle: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Main Background"
                  value={fields.mainBg}
                  onChange={(e) =>
                    setFields({ ...fields, mainBg: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                {' '}
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Main Background Mobile"
                  value={fields.mainBgMobile}
                  onChange={(e) =>
                    setFields({ ...fields, mainBgMobile: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Menu Header"
                  value={fields.menuHeader}
                  onChange={(e) =>
                    setFields({ ...fields, menuHeader: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Background"
                  value={fields.rewardBg}
                  onChange={(e) =>
                    setFields({ ...fields, rewardBg: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                {' '}
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Container"
                  value={fields.rewardContainer}
                  onChange={(e) =>
                    setFields({ ...fields, rewardContainer: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Default"
                  value={fields.rewardDefault}
                  onChange={(e) =>
                    setFields({ ...fields, rewardDefault: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Redeem Button"
                  value={fields.rewardRedeemButton}
                  onChange={(e) =>
                    setFields({ ...fields, rewardRedeemButton: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                {' '}
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Tablet Background"
                  value={fields.tabletBg}
                  onChange={(e) =>
                    setFields({ ...fields, tabletBg: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Brand Color"
                  value={fields.brandColor}
                  onChange={(e) =>
                    setFields({ ...fields, brandColor: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Brand Logo"
                  value={fields.brandLogo}
                  onChange={(e) =>
                    setFields({ ...fields, brandLogo: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                {' '}
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Order Complete"
                  value={fields.orderComplete}
                  onChange={(e) =>
                    setFields({ ...fields, orderComplete: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Cart Empty"
                  value={fields.cartEmpty}
                  onChange={(e) =>
                    setFields({ ...fields, cartEmpty: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Confirming Request"
                  value={fields.confirmingRequest}
                  onChange={(e) =>
                    setFields({ ...fields, confirmingRequest: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                {' '}
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Container"
                  value={fields.rewardContainer}
                  onChange={(e) =>
                    setFields({ ...fields, rewardContainer: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Default"
                  value={fields.rewardDefault}
                  onChange={(e) =>
                    setFields({ ...fields, rewardDefault: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  mt="10px"
                  label="Reward Redeem Button"
                  value={fields.rewardRedeemButton}
                  onChange={(e) =>
                    setFields({ ...fields, rewardRedeemButton: e.target.value })
                  }
                />
              </CFView>
              <CFView row justifyBetween w="100%">
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Font Color"
                  value={fields.fontColor}
                  onChange={(e) =>
                    setFields({ ...fields, fontColor: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Rewards Font Color"
                  value={fields.rewardsFontColor}
                  onChange={(e) =>
                    setFields({ ...fields, rewardsFontColor: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  marginRight={10}
                  label="Theme"
                  value={fields.theme}
                  onChange={(e) =>
                    setFields({ ...fields, theme: e.target.value })
                  }
                />
              </CFView>
            </Card>
          </CFView>
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={updateRestaurant}
            />
          </CFView>
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <CFView p="20px" column minWidth="350px">
          <CFView column justifyStart>
            <TextInputField
              width="100%"
              marginBottom={0}
              label="Restaurant name"
              value={fields.name}
              onChange={(e) => setFields({ ...fields, name: e.target.value })}
            />
            <Checkbox
              label="Make This Restaurant Inactive"
              marginBottom={10}
              onChange={(e) =>
                setFields({
                  ...fields,
                  inactive: e.target.checked,
                })
              }
              checked={fields.inactive}
            />
          </CFView>
          <CFView bg="white" column>
            <CFView column center xBold color="#00C0FF" mt="20px" mb="5px">
              RESTAURANT SETTINGS
            </CFView>
            <Card p="20px" column w="100%">
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  label="Landing Url"
                  value={fields.landingUrl}
                  onChange={(e) =>
                    setFields({ ...fields, landingUrl: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Order Number"
                  value={fields.orderNumber}
                  onChange={(e) =>
                    setFields({ ...fields, orderNumber: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Points Interval"
                  value={fields.pointsInterval}
                  onChange={(e) =>
                    setFields({ ...fields, pointsInterval: e.target.value })
                  }
                />
              </CFView>
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  label="Points Per Interval"
                  value={fields.pointsPerInterval}
                  onChange={(e) =>
                    setFields({ ...fields, pointsPerInterval: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Commission Fee"
                  value={fields.commissionFee}
                  onChange={(e) =>
                    setFields({ ...fields, commissionFee: e.target.value })
                  }
                />
                <TextInputField
                  label="Stripe Fee"
                  width="100%"
                  value={fields.stripeFee}
                  onChange={(e) =>
                    setFields({ ...fields, stripeFee: e.target.value })
                  }
                />
              </CFView>
              <CFView column center>
                <TextInputField
                  width={180}
                  label="Subscription Fee"
                  value={fields.subscriptionFee}
                  onChange={(e) =>
                    setFields({ ...fields, subscriptionFee: e.target.value })
                  }
                />
                <TextInputField
                  width={180}
                  label="Minimum Commission"
                  value={fields.minimumCommission}
                  onChange={(e) =>
                    setFields({ ...fields, minimumCommission: e.target.value })
                  }
                />
              </CFView>
            </Card>
            <CFView column center xBold color="#00C0FF" mt="20px" mb="5px">
              DESIGN SETTINGS
            </CFView>
            <Card p="20px" column>
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  label="Logo"
                  value={fields.logo}
                  onChange={(e) =>
                    setFields({ ...fields, logo: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  mt="10px"
                  label="Logo Title"
                  value={fields.logoTitle}
                  onChange={(e) =>
                    setFields({ ...fields, logoTitle: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  mt="10px"
                  label="Main Background"
                  value={fields.mainBg}
                  onChange={(e) =>
                    setFields({ ...fields, mainBg: e.target.value })
                  }
                />
              </CFView>
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  mt="10px"
                  label="Main Background Mobile"
                  value={fields.mainBgMobile}
                  onChange={(e) =>
                    setFields({ ...fields, mainBgMobile: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  mt="10px"
                  label="Menu Header"
                  value={fields.menuHeader}
                  onChange={(e) =>
                    setFields({ ...fields, menuHeader: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  mt="10px"
                  label="Reward Background"
                  value={fields.rewardBg}
                  onChange={(e) =>
                    setFields({ ...fields, rewardBg: e.target.value })
                  }
                />
              </CFView>
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  label="Brand Color"
                  value={fields.brandColor}
                  onChange={(e) =>
                    setFields({ ...fields, brandColor: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Brand Logo"
                  value={fields.brandLogo}
                  onChange={(e) =>
                    setFields({ ...fields, brandLogo: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Tablet Background"
                  value={fields.tabletBg}
                  onChange={(e) =>
                    setFields({ ...fields, tabletBg: e.target.value })
                  }
                />
              </CFView>
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  label="Order Complete"
                  value={fields.orderComplete}
                  onChange={(e) =>
                    setFields({ ...fields, orderComplete: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Cart Empty"
                  value={fields.cartEmpty}
                  onChange={(e) =>
                    setFields({ ...fields, cartEmpty: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Confirming Request"
                  value={fields.confirmingRequest}
                  onChange={(e) =>
                    setFields({ ...fields, confirmingRequest: e.target.value })
                  }
                />
              </CFView>
              <CFView column center w="100%">
                <TextInputField
                  width="100%"
                  label="Font Color"
                  value={fields.fontColor}
                  onChange={(e) =>
                    setFields({ ...fields, fontColor: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Rewards Font Color"
                  value={fields.rewardsFontColor}
                  onChange={(e) =>
                    setFields({ ...fields, rewardsFontColor: e.target.value })
                  }
                />
                <TextInputField
                  width="100%"
                  label="Theme"
                  value={fields.theme}
                  onChange={(e) =>
                    setFields({ ...fields, theme: e.target.value })
                  }
                />
              </CFView>
            </Card>
          </CFView>
          <CFView p="20px">
            <PrimaryButton
              label={saving ? 'SAVING...' : 'SAVE'}
              disabled={saving}
              onClick={updateRestaurant}
            />
          </CFView>
        </CFView>
      </MobileScreen>
    </PrimaryModal>
  )
}
