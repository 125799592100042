import React from 'react'
import { CFView, CFSelect, PrimaryButton, PrimaryModal } from 'components'
import { dispatch } from 'store'

export default (props) => {
  const copyMenus = (locationId1) => {
    dispatch.dashboard.copyLocationMenus({
      restaurantId: props.restaurantId,
      locationId1,
      locationId2: props.locationId,
    })
    dispatch.notification.setMessage({
      message: `Menu copied!`,
      level: 'success',
    })
    props.onRequestClose()
  }

  return (
    <PrimaryModal title="Copy Location Menus" {...props}>
      <CFView column justifyCenter w="400px" p="20px">
        <CFSelect selector={dispatch.restaurant.getLocations}>
          {(locations) =>
            Object.values(locations).map((location) =>
              location.id === props.locationId ? null : (
                <CFView
                  key={location.id}
                  row
                  justifyBetween
                  alignCenter
                  mb="10px"
                >
                  <CFView>{location.locationName}</CFView>
                  <PrimaryButton
                    label="Copy"
                    onClick={() => copyMenus(location.id)}
                  />
                </CFView>
              )
            )
          }
        </CFSelect>
      </CFView>
    </PrimaryModal>
  )
}
