import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  BackButton,
  Card,
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
  PageHeader,
} from 'components'
import ProductModal from './ProductModal'
import ManageModifiersModal from './ManageModifiersModal'
import RankModal from './RankModal'
import {
  AddIcon,
  Button,
  RandomIcon,
  EditIcon,
  SortIcon,
  TrashIcon,
  ManuallyEnteredDataIcon,
} from 'evergreen-ui'
import * as utils from 'cf-utils'
import { dispatch } from 'store'
import ApplyAllModifiersModal from './ApplyAllModifiersModal'

export default () => {
  const history = useHistory()
  const { restaurantId, locationId, menuId, categoryId } = useParams()
  const [showModal, setShowModal] = useState('')
  const [product, setProduct] = useState('')
  const [index, setIndex] = useState(0)
  const [saving, setSaving] = useState('')

  useEffect(() => {
    if (restaurantId) {
      dispatch.restaurant.setRestaurantId(restaurantId)
      dispatch.restaurant.setLocationId(locationId)
    }
    const unsubRestaurant = dispatch.restaurant.subscribeRestaurant()
    const unsubRestaurantLocations =
      dispatch.restaurant.subscribeRestaurantLocation()
    return () => {
      unsubRestaurant()
      unsubRestaurantLocations()
    }
  }, [restaurantId, locationId])

  const showProductModal = (product) => {
    setShowModal('product')
    setProduct(product)
  }

  const showManageModifiersModal = (product) => {
    setShowModal('manage modifiers')
    setProduct(product)
  }

  const showApplyAllModifiers = () => {
    setShowModal('apply all modifiers')
  }

  const showRankingModal = (product, index) => {
    setShowModal('rank')
    setProduct(product)
    setIndex(index)
  }

  const closeModal = () => {
    setShowModal('')
    setProduct('')
  }

  const navigateModifiers = () => {
    dispatch.dashboard.getRole() === 'admin'
      ? history.push(
          `/menus/${menuId}/categories/${categoryId}/products/modifiers`
        )
      : history.push(
          `/restaurants/${restaurantId}/locations/${locationId}/menus/${menuId}/categories/${categoryId}/products/modifiers`
        )
  }

  const deleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setSaving(productId)
      try {
        await dispatch.dashboard.deleteProduct({ productId })
      } catch (e) {
        alert('Error deleting product.')
      } finally {
        setSaving('')
      }
    }
  }

  return (
    <>
      <DefaultScreen>
        <CFView h="100%" p="20px" center>
          <CFView column>
            <BackButton onClick={history.goBack} />
            <Card
              h="calc(100vh - 148px)"
              w="100vw"
              maxWidth="1080px"
              minWidth="700px"
              mt="10px"
              column
              overflowY="auto"
            >
              <CFView p="20px" row justifyBetween bg="rgba(0,0,0,.3)">
                <CFView h1 bold>
                  Products
                </CFView>
                <CFView row>
                  <CFView mr="10px">
                    <Button
                      intent="danger"
                      iconBefore={ManuallyEnteredDataIcon}
                      height={40}
                      onClick={showApplyAllModifiers}
                    >
                      Apply Modifiers
                    </Button>
                  </CFView>
                  <CFView mr="10px">
                    <Button
                      intent="danger"
                      iconBefore={ManuallyEnteredDataIcon}
                      height={40}
                      onClick={navigateModifiers}
                    >
                      View Modifiers
                    </Button>
                  </CFView>
                  <Button
                    intent="danger"
                    iconBefore={AddIcon}
                    height={40}
                    onClick={() => setShowModal('product')}
                  >
                    Create Product
                  </Button>
                </CFView>
              </CFView>
              <CFSelect
                selector={() =>
                  dispatch.restaurant.getProductsInOrder(categoryId, true)
                }
              >
                {(products) =>
                  Object.values(products).length > 0 ? (
                    <CFView p="20px" column>
                      {Object.values(products).map((product, index) => (
                        <Card mb="10px" column justifyBetween key={product.id}>
                          <CFView
                            bg="#43464B"
                            row
                            justifyStart
                            p="10px"
                            color="white"
                            bold
                          >
                            <Button
                              marginRight={10}
                              height={24}
                              iconBefore={SortIcon}
                              onClick={() => showRankingModal(product, index)}
                            >
                              {index}
                            </Button>
                            {product.name}
                            {product.activeAfter > utils.moment().valueOf() && (
                              <CFView color="red" bold ml="10px" mt="1px">
                                SOLD OUT
                              </CFView>
                            )}
                          </CFView>
                          <CFView row justifyBetween p="10px">
                            <CFView row>
                              {product.imageUrl && (
                                <CFImage
                                  src={product.imageUrl}
                                  cover
                                  w="150px"
                                  h="150px"
                                  mr="20px"
                                  alt="Product"
                                  br="5px"
                                  boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                                />
                              )}
                              <CFView column>
                                <CFView
                                  fontSize={16}
                                  mb="5px"
                                >{`Description: ${product.description}`}</CFView>
                                <CFView
                                  fontSize={16}
                                  mb="5px"
                                >{`Price: ${product.price.toFixed(2)}`}</CFView>
                                {product.modifiers &&
                                  product.modifiers.length > 0 && (
                                    <CFView column>
                                      <CFView fontSize={16}>Modifiers:</CFView>
                                      {product.modifiers.map((modifierId) => (
                                        <CFView
                                          fontSize={16}
                                          mb="5px"
                                          key={modifierId}
                                        >
                                          •{' '}
                                          {dispatch.restaurant.getModifierDetails(
                                            modifierId
                                          )
                                            ? dispatch.restaurant.getModifierDetails(
                                                modifierId
                                              ).name
                                            : ''}
                                        </CFView>
                                      ))}
                                    </CFView>
                                  )}
                              </CFView>
                            </CFView>
                            <CFView column>
                              <CFSelect
                                selector={dispatch.restaurant.getModifiers}
                              >
                                {(modifiers) =>
                                  Object.values(modifiers).length > 0 && (
                                    <Button
                                      width={170}
                                      marginBottom={10}
                                      appearance="primary"
                                      iconBefore={RandomIcon}
                                      height={40}
                                      onClick={() =>
                                        showManageModifiersModal(product)
                                      }
                                    >
                                      Edit Modifiers
                                    </Button>
                                  )
                                }
                              </CFSelect>
                              <CFView w="10px" />
                              <Button
                                width={170}
                                marginBottom={10}
                                appearance="primary"
                                intent="warning"
                                iconBefore={EditIcon}
                                height={40}
                                onClick={() => showProductModal(product)}
                              >
                                Edit Product
                              </Button>
                              <CFView w="10px" />

                              <Button
                                width={170}
                                marginBottom={10}
                                appearance="primary"
                                intent="danger"
                                disabled={saving === product.id}
                                iconBefore={TrashIcon}
                                height={40}
                                onClick={() => deleteProduct(product.id)}
                              >
                                {saving === product.id
                                  ? 'Deleting'
                                  : 'Delete Product'}
                              </Button>
                              <CFView w="10px" />
                            </CFView>
                          </CFView>
                        </Card>
                      ))}
                    </CFView>
                  ) : (
                    <CFView h2 bold fill center>
                      No Products
                    </CFView>
                  )
                }
              </CFSelect>
            </Card>
          </CFView>
          <ProductModal
            isOpen={showModal === 'product'}
            categoryId={categoryId}
            product={product}
            onRequestClose={closeModal}
          />
          <ManageModifiersModal
            isOpen={showModal === 'manage modifiers'}
            product={product}
            onRequestClose={closeModal}
          />
          <RankModal
            isOpen={showModal === 'rank'}
            categoryId={categoryId}
            product={product}
            index={index}
            onRequestClose={closeModal}
          />
          <ApplyAllModifiersModal
            isOpen={showModal === 'apply all modifiers'}
            categoryId={categoryId}
            onRequestClose={closeModal}
          />
        </CFView>
      </DefaultScreen>
      <MobileScreen>
        <PageHeader
          title="PRODUCTS"
          LeftButton={<BackButton onClick={history.goBack} />}
          zIndex={90}
        />
        <CFView
          h="calc(100vh - 120px)"
          column
          justifyStart
          alignCenter
          overflowY="auto"
        >
          <CFView column justifyStart alignCenter>
            <CFView p="20px" column center bg="rgba(0,0,0,.3)">
              <CFView column>
                <CFView mb="10px">
                  <Button
                    width="100%"
                    intent="danger"
                    iconBefore={ManuallyEnteredDataIcon}
                    height={40}
                    onClick={navigateModifiers}
                  >
                    View Modifiers
                  </Button>
                </CFView>
                <Button
                  width="100%"
                  intent="danger"
                  iconBefore={AddIcon}
                  height={40}
                  onClick={() => setShowModal('product')}
                >
                  Create Product
                </Button>
              </CFView>
            </CFView>
            <CFSelect
              selector={() =>
                dispatch.restaurant.getProductsInOrder(categoryId, true)
              }
            >
              {(products) =>
                Object.values(products).length > 0 ? (
                  <CFView p="20px" column>
                    {Object.values(products).map((product, index) => (
                      <Card
                        w="100%"
                        minWidth="320px"
                        mb="10px"
                        column
                        justifyBetween
                        key={product.id}
                      >
                        <CFView
                          bg="#43464B"
                          row
                          justifyStart
                          p="10px"
                          color="white"
                          bold
                        >
                          <Button
                            marginRight={10}
                            height={24}
                            iconBefore={SortIcon}
                            onClick={() => showRankingModal(product, index)}
                          >
                            {index}
                          </Button>
                          {product.name}
                        </CFView>
                        <CFView column center p="10px">
                          <CFView column center>
                            {product.imageUrl && (
                              <CFImage
                                src={product.imageUrl}
                                cover
                                w="150px"
                                h="150px"
                                mr="20px"
                                alt="Product"
                                br="5px"
                                boxShadow="1px 1px 3px rgba(0,0,0,.8)"
                              />
                            )}
                            <CFView column mv="10px">
                              <CFView
                                fontSize={16}
                                mb="5px"
                              >{`Description: ${product.description}`}</CFView>
                              <CFView
                                fontSize={16}
                                mb="5px"
                              >{`Price: ${product.price.toFixed(2)}`}</CFView>
                              {product.modifiers &&
                                product.modifiers.length > 0 && (
                                  <CFView column>
                                    <CFView fontSize={16}>Modifiers:</CFView>
                                    {product.modifiers.map((modifierId) => (
                                      <CFView
                                        fontSize={16}
                                        mb="5px"
                                        key={modifierId}
                                      >
                                        •{' '}
                                        {dispatch.restaurant.getModifierDetails(
                                          modifierId
                                        )
                                          ? dispatch.restaurant.getModifierDetails(
                                              modifierId
                                            ).name
                                          : ''}
                                      </CFView>
                                    ))}
                                  </CFView>
                                )}
                            </CFView>
                          </CFView>
                          <CFView column>
                            <CFSelect
                              selector={dispatch.restaurant.getModifiers}
                            >
                              {(modifiers) =>
                                Object.values(modifiers).length > 0 && (
                                  <Button
                                    width={170}
                                    marginBottom={10}
                                    appearance="primary"
                                    iconBefore={RandomIcon}
                                    height={40}
                                    onClick={() =>
                                      showManageModifiersModal(product)
                                    }
                                  >
                                    Edit Modifiers
                                  </Button>
                                )
                              }
                            </CFSelect>
                            <CFView w="10px" />
                            <Button
                              width={170}
                              marginBottom={10}
                              appearance="primary"
                              intent="warning"
                              iconBefore={EditIcon}
                              height={40}
                              onClick={() => showProductModal(product)}
                            >
                              Edit Product
                            </Button>
                            <CFView w="10px" />

                            <Button
                              width={170}
                              marginBottom={10}
                              appearance="primary"
                              intent="danger"
                              disabled={saving === product.id}
                              iconBefore={TrashIcon}
                              height={40}
                              onClick={() => deleteProduct(product.id)}
                            >
                              {saving === product.id
                                ? 'Deleting'
                                : 'Delete Product'}
                            </Button>
                            <CFView w="10px" />
                          </CFView>
                        </CFView>
                      </Card>
                    ))}
                  </CFView>
                ) : (
                  <CFView h2 bold fill center>
                    No Products
                  </CFView>
                )
              }
            </CFSelect>
          </CFView>
          <ProductModal
            isOpen={showModal === 'product'}
            categoryId={categoryId}
            product={product}
            onRequestClose={closeModal}
          />
          <ManageModifiersModal
            isOpen={showModal === 'manage modifiers'}
            product={product}
            onRequestClose={closeModal}
          />
          <RankModal
            isOpen={showModal === 'rank'}
            categoryId={categoryId}
            product={product}
            index={index}
            onRequestClose={closeModal}
          />
          <ManageModifiersModal
            isOpen={showModal === 'manage modifiers'}
            product={product}
            onRequestClose={closeModal}
          />
        </CFView>
      </MobileScreen>
    </>
  )
}
