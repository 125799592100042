import React, { useState, useEffect } from 'react'
import { CFView, DefaultScreen, PrimaryButton, PrimaryModal } from 'components'
import { FilePicker, TextInputField } from 'evergreen-ui'
import * as api from 'api'
import { storageRef } from 'cf-core/src/config/firebase'
import { dispatch } from 'store'

export default (props) => {
  const [menuUrl, setMenuUrl] = useState()
  const [loadingFile, setLoadingFile] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setMenuUrl(props.pdfMenuUrl)
  }, [props.pdfMenuUrl])

  const onFileUpload = (file) => {
    const newFile = file[0]
    if (!newFile) {
      alert('Please choose a file first!')
    }
    const fileUrl = `/restaurants/pdfMenus/${newFile.name}`
    const fileRef = storageRef.child(fileUrl)
    setLoadingFile(true)
    fileRef
      .put(newFile)
      .then(() => {
        console.log('File uploaded successfully')
        setLoadingFile(false)
        fileRef
          .getDownloadURL()
          .then((downloadURL) => {
            setMenuUrl(downloadURL)
          })
          .catch((error) => {
            console.error('Error getting download URL:', error)
          })
      })
      .catch((error) => {
        setLoadingFile(false)
        console.error('Error uploading file:', error)
      })
  }

  const updateLocation = async () => {
    const locationDetails = {
      pdfMenuUrl: menuUrl,
    }
    setSaving(true)
    await api.updateLocation(
      dispatch.restaurant.getRestaurantId(),
      dispatch.restaurant.getSelectedLocationId(),
      locationDetails
    )
    dispatch.notification.setMessage({
      message: `Location saved!`,
      level: 'success',
    })
    props.onRequestClose()
    setSaving(false)
  }
  return (
    <PrimaryModal
      title={`Update ${dispatch.restaurant.getLocationName()}'s PDF Menu`}
      {...props}
    >
      <DefaultScreen>
        <CFView p="20px" column minWidth="650px">
          <CFView column>
            <TextInputField
              width="100%"
              marginBottom="5px"
              label="PDF Menu Url"
              value={menuUrl}
              onChange={setMenuUrl}
            />
          </CFView>
          <FilePicker
            multiple={false}
            width="100%"
            marginTop="20px"
            disabled={loadingFile}
            onChange={onFileUpload}
            placeholder="Select the file here!"
          />
        </CFView>
        <CFView p="20px">
          <PrimaryButton
            label={saving ? 'SAVING...' : 'SAVE'}
            disabled={saving || loadingFile}
            onClick={updateLocation}
          />
        </CFView>
      </DefaultScreen>
    </PrimaryModal>
  )
}
